import {
  Component,
  OnInit,
  Inject
} from '@angular/core';

import {
  ActivatedRoute,
  Router
} from '@angular/router';

import {
  first
} from 'rxjs/operators';

import {
  MsalService
} from '@azure/msal-angular';

import {
  Country,
  Currency,
  Field,
  Pricelist,
  Rate,
  RateCod,
  RatesZone,
  Ratios,
  Service,
  ServiceExtra,
  Surcharge,
  User
} from '../../models';

import {
  AuthService,
  PricelistService,
  ServiceService
} from '../../providers';

import {
  cloneDeep,
  getContextPath,
  getCountryCodesZone,
  getCountryPartnerCodeOverride,
  getCountryOptions,
  getCurrencyOptions,
  getCurrencySymbol,
  getModelOption,
  getRateReturnTypes,
  getRateUnitFrankingTypes,
  getRegionCodeOptions,
  getUtcDateTimestamp,
  getVatRates,
  hasRegionCodeOptions,
  isArrayEmpty,
  isArrayNotEmpty,
  isEU,
  isStringSafeNotEmpty,
  naturalSort,
  objectHasValue,
  setPricelistAdjustment
} from '../../app/app.utils';

import {
  DEFAULT_CURRENCY_CODE,
  DEFAULT_LANGUAGE_CODE,
  PRICELIST_OFFICIAL_DEFAULT,
  PRICELIST_SERVICE_CODES_LEGACY_DEFAULT
} from '../../app/app.const';

@Component({
  selector: 'biz-page-pricelist',
  templateUrl: './pricelist.component.html',
  styleUrls: [
    './pricelist.component.scss'
  ],
  host: {
    class: 'biz-page'
  }
})
export class PricelistComponent implements OnInit {
  public pricelist: Pricelist;
  public pricelistDomain: string = null;
  public pricelistIsClone: boolean;
  public pricelistIsEdit: boolean;
  public pricelistIsMarkup: boolean;
  public pricelistIsNew: boolean;
  public pricelistIsReadOnly: boolean;

  public currencyOptions: {
    zones: Currency[],
    preferred: Currency[],
    other: Currency[]
  };
  public currencyOptionActive: Currency;
  public currencyPriceMaxOptionActive: Currency;
  public currencyRateFieldShow: boolean = false;

  public countryOptions: {
    zones: Country[],
    preferred: Country[],
    other: Country[]
  };
  public countryOptionActive: Country;

  public countryPartnerCodeMap: any;

  public fieldsRates: Field[] = [
    {
      label: 'Weight (kg)',
      icon: 'shopping-bag-outline',
      valueKey: 'weightMax',
      valueType: 'float',
      valueStep: 0.250,
      valueMin: 0.250
    },
    {
      label: 'Fee',
      icon: 'pricetags-outline',
      valueKey: 'fee',
      valueType: 'float',
      valueStep: 0.01,
      valueMin: 0.01
    }
  ];

  public itemEmptyRates: Rate = {
    weightMax: null,
    fee: null
  };

  public fieldsRatesCod: Field[] = [
    {
      label: 'Type',
      icon: 'pricetags-outline',
      valueKey: 'feeType',
      valueType: 'string',
      values: [
        'fixed',
        'posOnly',
        'priceRatio',
        'stepped',
        'steppedPriceRatio'
      ],
      valueLabels: [
        'Fixed Service Fee',
        'POS Only',
        'COD Amount Ratio',
        'Scaled Service Fee',
        'Scaled COD Amount Ratio'
      ],
      isModifier: true
    },
    {
      label: 'Max. COD Amount Currency',
      icon: 'pricetags-outline',
      valueKey: 'priceCurrency',
      valueType: 'string',
      modifierValues: [
        'stepped',
        'steppedPriceRatio'
      ],
      isHidden: true
    },
    {
      label: 'Max. COD Amount',
      icon: 'pricetags-outline',
      valueKey: 'priceMax',
      valueType: 'float',
      valueStep: 0.01,
      valueMin: 0.01,
      modifierValues: [
        'stepped',
        'steppedPriceRatio'
      ]
    },
    {
      label: 'COD Amount Ratio',
      icon: 'pricetags-outline',
      valueKey: 'priceRatio',
      valueType: 'float',
      valueStep: 0.01,
      valueMin: 0.01,
      valueMax: 1.00,
      modifierValues: [
        'priceRatio',
        'steppedPriceRatio'
      ]
    },
    {
      label: 'Min. Service Fee',
      icon: 'pricetags-outline',
      valueKey: 'feeMin',
      valueType: 'float',
      valueStep: 0.01,
      valueMin: 0.01,
      modifierValues: [
        'priceRatio',
        'steppedPriceRatio'
      ]
    },
    {
      label: 'Service Fee',
      icon: 'pricetags-outline',
      valueKey: 'fee',
      valueType: 'float',
      valueStep: 0.01,
      valueMin: 0.01,
      modifierValues: [
        'fixed',
        'stepped'
      ]
    },
    {
      label: 'mPOS COD Amount Ratio',
      icon: 'pricetags-outline',
      valueKey: 'mPosPriceRatio',
      valueType: 'float',
      valueStep: 0.01,
      valueMin: 0.01,
      valueMax: 1.00,
      modifierValues: [
        'posOnly',
        'fixed'
      ]
    }
  ];

  public itemEmptyRatesCod: RateCod = {
    priceCurrency: DEFAULT_CURRENCY_CODE,
    priceMax: null,
    fee: null,
    feeType: null
  };

  public fieldsRatesReturn: Field[] = [
    {
      label: 'Weight (kg)',
      icon: 'shopping-bag-outline',
      valueKey: 'weightMax',
      valueType: 'float',
      valueStep: 0.250,
      valueMin: 0.250
    },
    {
      label: 'Fee',
      icon: 'pricetags-outline',
      valueKey: 'fee',
      valueType: 'float',
      valueStep: 0.01,
      valueMin: 0.01
    }
  ];

  public itemEmptyRatesReturn: Rate = {
    weightMax: null,
    fee: null
  };

  public fieldsServicesExtra: Field[] = [
    {
      label: 'Label',
      icon: 'label',
      valueKey: 'label',
      valueType: 'string'
    },
    {
      label: 'Fee',
      icon: 'pricetags-outline',
      valueKey: 'fee',
      valueType: 'float',
      valueStep: 0.01,
      valueMin: 0.01
    }
  ];

  public itemEmptyServicesExtra: ServiceExtra = {
    label: null,
    fee: null
  };

  public fieldsSurcharges: Field[] = [
    {
      label: 'Type',
      icon: 'pricetags-outline',
      valueKey: 'feeType',
      valueType: 'string',
      values: [
        'fixed',
        'deliveryFeeRatio'
      ],
      valueLabels: [
        'Fixed Service Fee',
        'Delivery Fee Ratio',
      ],
      isModifier: true
    },
    {
      label: 'Label',
      icon: 'label',
      valueKey: 'label',
      valueType: 'string'
    },
    {
      label: 'Service Fee',
      icon: 'pricetags-outline',
      valueKey: 'fee',
      valueType: 'float',
      valueStep: 0.01,
      valueMin: 0.01,
      modifierValues: [
        'fixed'
      ]
    },
    {
      label: 'Delivery Fee Ratio',
      icon: 'pricetags-outline',
      valueKey: 'feeRatio',
      valueType: 'float',
      valueStep: 0.01,
      valueMin: 0.01,
      modifierValues: [
        'deliveryFeeRatio'
      ]
    }
  ];

  public itemEmptySurcharges: Surcharge = {
    label: null,
    feeType: null,
    fee: null,
    feeRatio: null
  };

  public languageCodeActiveLabel: string = (
    DEFAULT_LANGUAGE_CODE
  );

  public partnerCodes: string[] = [];

  public rateCodPriceCurrencyCode: string;
  public rateReturnTypes: string[];
  public rateUnitFrankingTypes: string[];

  public regionCodeOptions: any[] = [];
  public regionCodeOptionActive: any;
  
  public showRefreshWarning: boolean;

  public services: Service[] = [];
  public users: User[] = [];
  public vatRates: number[] = [];

  private authEmail: string;

  public constructor(
    @Inject(
      'Window'
    ) private window: Window,
    private router: Router,
    private route: ActivatedRoute,
    private authService: AuthService,
    private msalService: MsalService,
    private pricelistService: PricelistService,
    private serviceService: ServiceService
  ) {}

  public get countryOptionLabel(): string {
    return (
      (
        (
          this.countryOptions !==
          null
        ) &&
        (
          typeof this.countryOptions ===
          'object'
        ) &&
        (
          Array
            .isArray(
              this.countryOptions
                  .zones
            )
        ) &&
        (
          this.countryOptionActive
        ) &&
        (
          this.countryOptions
              .zones
              .map(
                zone => (
                  zone
                    .code
                )
              )
              .includes(
                this.countryOptionActive
                    .code
              )
        )
      ) ?
        'Zone' :
        'Country'
    );
  }

  public get fieldsServicesExtraCurrencyActive(): Field[] {
    return (
      this.fieldsServicesExtra
          .map(
            fieldServiceExtra => ({
              ...fieldServiceExtra,
              label: (
                (
                  fieldServiceExtra
                    .label
                    .startsWith(
                      'Fee'
                    )
                ) ?
                  `Fee (${(
                    this.currencyOptionActive
                        .symbol
                  )})` :
                  (
                    fieldServiceExtra
                      .label
                  )
              )
            })
          )
    );
  }

  public get fieldsRatesWithZones(): Field[] {
    if (!this.pricelist) {
      return [];
    };

    if (!this.pricelistHasRatesZones) {
      return this.fieldsRates;
    }

    return [
      this.fieldsRates[0],
      {
        ...this.fieldsRates[1],
        label: (
          this.fieldsRates[1]
              .label
              .replace(
                'Fee ',
                (
                  (
                    (
                      this.pricelist
                          .countryCode
                    ) ===
                    'gr'
                  ) ?
                    'Within Attiki Fee ' :
                    'Mainland Fee '
                ) // TODO: Remove hard-coded mainland labels
              )
        )
      },
      ...(
        this.pricelist
            .ratesZones
            .map(
              ({
                zoneCode
              }) => (
                {
                  ...this.fieldsRates[1],
                  label: (
                    `${(
                      this.getRegionCodeLabel(
                        zoneCode
                      )
                    )} ${(
                      this.fieldsRates[1]
                          .label
                    )}`
                  ),
                  valueKey: (
                    `${(
                      this.fieldsRates[1]
                          .valueKey
                    )}-${(
                      zoneCode
                    )}`
                  )
                }
              )
            )
      )
    ];
  }

  public get fieldsRatesReturnWithZones(): Field[] {
    if (!this.pricelist) {
      return [];
    };

    if (!this.pricelistHasRatesReturnZones) {
      return this.fieldsRates;
    }

    return [
      this.fieldsRatesReturn[0],
      {
        ...this.fieldsRatesReturn[1],
        label: (
          this.fieldsRatesReturn[1]
              .label
              .replace(
                'Fee ',
                (
                  (
                    (
                      this.pricelist
                          .countryCode
                    ) ===
                    'gr'
                  ) ?
                    'Within Attiki Fee ' :
                    'Mainland Fee '
                ) // TODO: Remove hard-coded mainland labels
              )
        )
      },
      ...(
        this.pricelist
            .ratesReturnZones
            .map(
              ({
                zoneCode
              }) => (
                {
                  ...this.fieldsRatesReturn[1],
                  label: (
                    `${(
                      this.getRegionCodeLabel(
                        zoneCode
                      )
                    )} ${(
                      this.fieldsRatesReturn[1]
                          .label
                    )}`
                  ),
                  valueKey: (
                    `${(
                      this.fieldsRatesReturn[1]
                          .valueKey
                    )}-${(
                      zoneCode
                    )}`
                  )
                }
              )
            )
      )
    ];
  }

  public get hasCountryPartnerCodeMapValue(): boolean {
    return (
      objectHasValue(
        this.countryPartnerCodeMap
      )
    );
  }

  public get hasSinglePartnerCode(): boolean {
    return (
      (
        !!this.countryPartnerCodeMap[
          this.pricelist
              .countryCode
        ]
      ) &&
      (
        ![
          'de',
          'it'
        ].includes(
          this.pricelist
              .countryCode
        )
      )
    );
  }

  public get isEU(): boolean {
    return (
      this.pricelist &&
      (
        isEU(
          this.pricelist
              .countryCode
        )
      )
    );
  }

  public get hasRegionCodes(): boolean {
    return (
      this.pricelist &&
      (
        hasRegionCodeOptions(
          this.pricelist
              .countryCode
        )
      )
    );
  }

  public get hasVat(): boolean {
    return (
      this.isEU ||
      (
        this.pricelist &&
        (
          getCountryCodesZone()
            .includes(
              this.pricelist
                  .countryCode
            )
        )
      )
    );
  }

  public get itemEmptyRatesWithZones() {
    if (!this.pricelist) {
      return null;
    }

    if (!this.pricelistHasRatesZones) {
      return this.itemEmptyRates;
    }

    const {
      weightMax,
      fee
    } = this.itemEmptyRates;

    const itemEmptyRatesWithZones = {
      weightMax,
      fee
    };
    
    this.pricelistRatesZoneCodes
        .filter(
          zoneCode => (
            !!zoneCode
          )
        )
        .forEach(
          zoneCode => (
            itemEmptyRatesWithZones[
              `fee-${zoneCode}`
            ] = null
          )
        );

    return itemEmptyRatesWithZones;
  }

  public get itemEmptyRatesReturnWithZones() {
    if (!this.pricelist) {
      return null;
    }

    if (!this.pricelistHasRatesReturnZones) {
      return this.itemEmptyRatesReturn;
    }

    const {
      weightMax,
      fee
    } = this.itemEmptyRates;

    const itemEmptyRatesReturnWithZones = {
      weightMax,
      fee
    };
    
    this.pricelistRatesReturnZoneCodes
        .filter(
          zoneCode => (
            !!zoneCode
          )
        )
        .forEach(
          zoneCode => (
            itemEmptyRatesReturnWithZones[
              `fee-${zoneCode}`
            ] = null
          )
        );

    return itemEmptyRatesReturnWithZones;
  }

  public get pricelistDomainIsCosts(): boolean {
    return (
      this.pricelistDomain ===
      'costs'
    );
  }

  public get pricelistHasRatesCod(): boolean {
    return (
      (
        this.pricelist
            .isCod
      ) &&
      (
        (
          this.pricelist
              .ratesCod
              .filter(
                rateCod => (
                  (
                    rateCod
                      .feeType
                  ) !==
                  'posOnly'
                )
              )
              .length
        ) >
        0
      )
    );
  }

  public get pricelistHasRatesCodPos(): boolean {
    return (
      (
        this.pricelist
            .isPos
      ) &&
      (
        (
          this.pricelist
              .ratesCod
              .filter(
                rateCod => (
                  (
                    (
                      rateCod
                        .mPosPriceRatio
                    ) !==
                    null
                  ) &&
                  (
                    typeof (
                      rateCod
                        .mPosPriceRatio
                    ) ===
                    'number'
                  ) &&
                  (
                    (
                      rateCod
                        .mPosPriceRatio
                    ) >
                    0
                  )
                )
              )
              .length
        ) >
        0
      )
    );
  }

  public get pricelistHasRegionCodes(): boolean {
    return (
      this.pricelist &&
      (
        isArrayNotEmpty(
          this.pricelist
              .regionCodes
        )
      )
    );
  }

  public get pricelistHasRatesCodStepped(): boolean {
    return (
      (
        this.pricelist
            .isCod
      ) &&
      (
        (
          this.pricelist
              .ratesCod
              .filter(
                rateCod => (
                  (
                    rateCod
                      .feeType
                  ) &&
                  (
                    rateCod
                      .feeType
                      .startsWith(
                        'stepped'
                      )
                  )
                )
              )
              .length
        ) >
        0
      )
    );
  }

  public get pricelistHasRatesZones(): boolean {
    return (
      this.pricelist &&
      (
        isArrayNotEmpty(
          this.pricelistRatesZoneCodes
              .filter(
                zoneCode => (
                  !!zoneCode
                )
              )
        )
      )
    );
  }

  public get pricelistHasRatesReturnZones(): boolean {
    return (
      this.pricelist &&
      (
        isArrayNotEmpty(
          this.pricelistRatesReturnZoneCodes
              .filter(
                zoneCode => (
                  !!zoneCode
                )
              )
        )
      )
    );
  }

  public get pricelistLabel(): string {
    if (
      !this.languageCodeActiveLabel ||
      (
        this.languageCodeActiveLabel ===
        DEFAULT_LANGUAGE_CODE
      )
    ) {
      return (
        this.pricelist
            .label
      );
    }

    const translationActive = (
      (
        Array
          .isArray(
            this.pricelist
                .translations
          )
      ) ?
        (
          this.pricelist
              .translations
              .find(
                translation => (
                  (
                    'label' ===
                    (
                      translation
                        .key
                    )
                  ) &&
                  (
                    this.languageCodeActiveLabel ===
                    (
                      translation
                        .languageCode
                    )
                  )
                )
              )
        ) :
        undefined
    );

    return (
      translationActive ?
        (
          translationActive
            .value
        ) :
        ''
    );
  }
  
  public get pricelistRateCodStepped(): RateCod {
    if (!this.pricelistHasRatesCodStepped) {
      return null;
    }

    return (
      this.pricelist
          .ratesCod
          .find(
            rateCod => (
              (
                rateCod
                  .feeType
              ) &&
              (
                rateCod
                  .feeType
                  .startsWith(
                    'stepped'
                  )
              )
            )
          )
    );
  }

  public get pricelistRatesWithZones() {
    if (!this.pricelist) {
      return [];
    }

    if (!this.pricelistHasRatesZones) {
      return (
        this.pricelist
            .rates
      );
    }

    const ratesZoneMap = {};

    this.pricelist
        .ratesZones
        .forEach(
          ({
            zoneCode,
            rates
          }) => (
            ratesZoneMap[
              `fee-${zoneCode}`
            ] = rates
          )
        );

    const pricelistRatesZonesSorted = (
      cloneDeep(
        this.pricelist
            .ratesZones
      )
      .sort(
        (
          {
            rates: ratesA
          },
          {
            rates: ratesB
          }
        ) => (
          (
            ratesB
              .length
          ) - 
          (
            ratesA
              .length
          )
        )
      )
    );

    return (
      (
        (
          (
            this.pricelist
                .rates
                .length
          ) >=
          (
            pricelistRatesZonesSorted[0]
              .rates
              .length
          )
        ) ?
          (
            this.pricelist
                .rates
          ) :
          (
            pricelistRatesZonesSorted[0]
              .rates
          )
      )
      .map(
        (
          {
            weightMax
          },
          index
        ) => {
          const rateWithZone = {
            weightMax,
            fee: (
              (
                this.pricelist
                    .rates[
                      index
                    ]
              ) ?
                (
                  this.pricelist
                      .rates[
                        index
                      ]
                      .fee
                ) :
                undefined
            )
          };

          Object
            .keys(
              ratesZoneMap
            )
            .forEach(
              feeKey => {
                if (
                  (
                    ratesZoneMap[
                      feeKey
                    ]
                  ) &&
                  (
                    ratesZoneMap[
                      feeKey
                    ][
                      index
                    ]
                  )
                ) {
                  rateWithZone[
                    feeKey
                  ] = (
                    ratesZoneMap[
                      feeKey
                    ][
                      index
                    ].fee
                  );
                }
              }
            );

          return rateWithZone;
        }
      )
    );
  }

  public get pricelistRatesReturnWithZones() {
    if (!this.pricelist) {
      return [];
    }

    if (!this.pricelistHasRatesReturnZones) {
      return (
        this.pricelist
            .ratesReturn
      );
    }

    const ratesReturnZoneMap = {};

    this.pricelist
        .ratesReturnZones
        .forEach(
          ({
            zoneCode,
            rates
          }) => (
            ratesReturnZoneMap[
              `fee-${zoneCode}`
            ] = rates
          )
        );

    const pricelistRatesReturnZonesSorted = (
      cloneDeep(
        this.pricelist
            .ratesReturnZones
      )
      .sort(
        (
          {
            rates: ratesA
          },
          {
            rates: ratesB
          }
        ) => (
          (
            ratesB
              .length
          ) - 
          (
            ratesA
              .length
          )
        )
      )
    );

    return (
      (
        (
          (
            this.pricelist
                .ratesReturn
                .length
          ) >=
          (
            pricelistRatesReturnZonesSorted[0]
              .rates
              .length
          )
        ) ?
          (
            this.pricelist
                .ratesReturn
          ) :
          (
            pricelistRatesReturnZonesSorted[0]
              .rates
          )
      )
      .map(
        (
          {
            weightMax
          },
          index
        ) => {
          const rateReturnWithZone = {
            weightMax,
            fee: (
              (
                this.pricelist
                    .ratesReturn[
                      index
                    ]
              ) ?
                (
                  this.pricelist
                      .ratesReturn[
                        index
                      ]
                      .fee
                ) :
                undefined
            )
          };

          Object
            .keys(
              ratesReturnZoneMap
            )
            .forEach(
              feeKey => {
                if (
                  (
                    ratesReturnZoneMap[
                      feeKey
                    ]
                  ) &&
                  (
                    ratesReturnZoneMap[
                      feeKey
                    ][
                      index
                    ]
                  )
                ) {
                  rateReturnWithZone[
                    feeKey
                  ] = (
                    ratesReturnZoneMap[
                      feeKey
                    ][
                      index
                    ].fee
                  );
                }
              }
            );

          return rateReturnWithZone;
        }
      )
    );
  }

  public get pricelistRatesZoneCodes(): string[] {
    if (!this.pricelist) {
      return [];
    }

    return [
      null,
      ...(
        this.pricelist
            .ratesZones
            .map(
              ({
                zoneCode
              }) => (
                zoneCode
              )
            )
      )
    ];
  }

  public get pricelistRatesZoneLabels(): string[] {
    if (!this.pricelist) {
      return [];
    }

    return [
      (
        this.getPricelistRegionLabel(
          true
        )
      ),
      ...(
        this.pricelist
            .ratesZones
            .map(
              ({
                zoneCode
              }) => (
                this.getRegionCodeLabel(
                  zoneCode
                )
              )
            )
      )
    ];
  }

  public get pricelistRatesReturnZoneCodes(): string[] {
    if (!this.pricelist) {
      return [];
    }

    return [
      null,
      ...(
        this.pricelist
            .ratesReturnZones
            .map(
              ({
                zoneCode
              }) => (
                zoneCode
              )
            )
      )
    ];
  }

  public get pricelistRatesReturnZoneLabels(): string[] {
    if (!this.pricelist) {
      return [];
    }

    return [
      (
        this.getPricelistRegionLabel(
          true
        )
      ),
      ...(
        this.pricelist
            .ratesReturnZones
            .map(
              ({
                zoneCode
              }) => (
                this.getRegionCodeLabel(
                  zoneCode
                )
              )
            )
      )
    ];
  }

  public get pricelistServicesActive(): Service[] {
    if (
      !(
        (
          Array
            .isArray(
              this.services
            )
        ) &&
        (
          isArrayNotEmpty(
            this.pricelist
                .services
          )
        )
      )
    ) {
      return [];
    }

    return (
      this.services
          .filter(
            service => (
              (
                (
                  <Service[]>(
                    this.pricelist
                        .services
                  )
                )
                .map(
                  pricelistService => (
                    pricelistService
                      ._id
                  )
                )
                .includes(
                  service
                    ._id
                )
              )
            )
          )
    );
  }

  public get pricelistUsersActive(): User[] {
    if (
      !(
        (
          Array
            .isArray(
              this.users
            )
        ) &&
        (
          isArrayNotEmpty(
            this.pricelist
                .users
          )
        )
      )
    ) {
      return [];
    }

    return (
      this.users
          .filter(
            user => (
              (
                (
                  <User[]>(
                    this.pricelist
                        .users
                  )
                )
                .map(
                  pricelistUser => (
                    pricelistUser
                      ._id
                  )
                )
                .includes(
                  user
                    ._id
                )
              )
            )
          )
    );
  }

  public get rateCodPriceCurrencySymbol() {
    return (
      getCurrencySymbol(
        this.rateCodPriceCurrencyCode
      )
    );
  }

  public async ngOnInit() {
    this.setAuthEmail();
    this.setRateReturnTypes();
    this.setRateUnitFrankingTypes();
    this.setVatRates();

    this.countryOptions = (
      getCountryOptions()
    );

    await this.setServices();
    await this.setUsers();

    await this.setPartnerCodes();
    this.setCountryPartnerCodeMap();

    this.setPricelistDomain();
    await this.setPricelist();

    this.setCountryOptionActive(
      this.pricelist
          .countryCode
    );

    this.currencyOptions = (
      getCurrencyOptions()
    );

    await this.setPricelistIsClone();
    await this.setPricelistIsMarkup();

    this.setPricelistIsEdit();
    this.setPricelistIsReadOnly();

    this.setCurrencyOptionActive(
      this.pricelist
          .currencyCode
    );

    this.setCurrencyPriceMaxOptionActive(
      (
        this.pricelist
            .priceMaxCurrency
      ) ?
        (
          this.pricelist
              .priceMaxCurrency
        ) :
        (
          this.pricelist
              .currencyCode
        )
    );
    
    this.setRegionCodeOptionActive(
      this.pricelist
          .regionCodes
          .join(',')
    );
  }

  public getPricelistRegionLabel(
    isMultiSelect: boolean = true
  ): string {
    if (!this.pricelist) {
      return null;
    }

    if (!this.hasRegionCodes) {
      return 'No Regions';
    }

    if (
      (
        this.pricelist
            .countryCode
      ) ===
      'gr'
    ) {
      return `${(
        (
          this.pricelistHasRatesZones ||
          this.pricelistHasRatesReturnZones
        ) ?
          (
            isMultiSelect ? 
              'Within Attiki' :
              'Multiple Regions'
             
          ) :
          (
            this.pricelistHasRegionCodes ?
              `Region: ${(
                this.regionCodeOptionActive
                    .label
              )}` :
              `Within Attiki`
          )
      )}`;
    }

    return `${(
      (
        this.pricelistHasRatesZones ||
        this.pricelistHasRatesReturnZones
      ) ?
        (
          isMultiSelect ? 
            'Mainland' :
            'Multiple Regions'
          
        ) :
        (
          this.pricelistHasRegionCodes ?
            `Region: ${(
              this.regionCodeOptionActive
                  .label
            )}` :
            `Mainland`
        )
    )}`;
  }

  public getPricelistRegionLabelMainland() {
    if (!this.pricelist) {
      return null;
    }

    if (!this.hasRegionCodes) {
      return 'No Regions';
    }

    return (
      (
        (
          this.pricelist
              .countryCode
        ) ===
        'gr'
      ) ?
        'Within Attiki' :
        'Mainland'
    );
  }

  public onBack() {
    this.goBack();
  }

  public onEditInfo(
    key: string,
    data: {
      value: string;
      languageCode: string;
    }
  ) {
    let {
      value,
      languageCode
    } = data;

    value = (
      (
        isStringSafeNotEmpty(
          value
        )
      ) ?
        value :
        ''
    );

    if (
      [
        'ratesInfo',
        'ratesCodInfo',
        'ratesReturnInfo',
        'servicesExtraInfo',
        'surchargesInfo'
      ].includes(
        key
      )
    ) {
      if (
        !languageCode ||
        (
          languageCode ===
          DEFAULT_LANGUAGE_CODE
        )
      ) {
        this.pricelist[
          key
        ] = value;
      }
      else {
        if (
          Array
            .isArray(
              this.pricelist
                  .translations
            )
        ) {
          const index = (
            this.pricelist
                .translations
                .findIndex(
                  translation => (
                    (
                      (
                        translation
                          .key
                      ) ===
                      key
                    ) &&
                    (
                      (
                        translation
                          .languageCode
                      ) ===
                      languageCode
                    )
                  )
                )
          );

          if (
            index >
            -1
          ) {
            this.pricelist
                .translations[
                  index
                ]
                .value = (
                  value
                );
          }
          else {
            this.pricelist
                .translations
                .push({
                  key,
                  languageCode,
                  value
                });
          }
        }
        else {
          this.pricelist
              .translations = [{
                key,
                languageCode,
                value
              }];
        }
      }
    }
  }

  public onEditRatesWithZones(items) {
    if (!this.pricelist) {
      return false;
    }

    if (!this.pricelistHasRatesZones) {
      return (
        this.pricelist
            .rates = (
              items
                .map(
                  ({
                    weightMax,
                    fee
                  }) => ({
                    weightMax,
                    fee
                  })
                )
            )
      );
    }

    this.pricelist
        .rates = (
          items
            .map(
              ({
                weightMax,
                fee
              }) => ({
                weightMax,
                fee
              })
            )
        );

    this.pricelist
        .ratesZones = (
          this.pricelist
              .ratesZones
              .map(
                ({
                  zoneCode,
                  rates
                }) => ({
                  zoneCode,
                  rates: (
                    items
                      .map(
                        (
                          item,
                          index
                        ) => ({
                          weightMax: (
                            this.pricelist
                                .rates[
                                  index
                                ]
                                .weightMax
                          ),
                          fee: (
                            item[
                              `fee-${(
                                zoneCode
                              )}`
                            ]
                          )
                        })
                      )
                  )
                })
              )
        );
  }

  public onEditRatesCod(items) {
    this.pricelist
        .ratesCod = (
          items
            .map(
              item => {
                if (
                  !(
                    (
                      item
                        .feeType
                    ) &&
                    (
                      item
                        .feeType
                        .startsWith(
                          'stepped'
                        )
                    )
                  )
                ) {
                  return item;
                }

                return {
                  ...item,
                  priceCurrency: (
                    this.rateCodPriceCurrencyCode
                  )
                };
              }
            )
        );

    this.fieldsRatesCod = [
      ...this.fieldsRatesCod
    ]; // Triggers field update in OrderedListComponent
  }

  public onEditRatesReturnWithZones(items) {
    if (!this.pricelist) {
      return false;
    }

    if (!this.pricelistHasRatesReturnZones) {
      return (
        this.pricelist
            .ratesReturn = (
              items
                .map(
                  ({
                    weightMax,
                    fee
                  }) => ({
                    weightMax,
                    fee
                  })
                )
            )
      );
    }

    this.pricelist
        .ratesReturn = (
          items
            .map(
              ({
                weightMax,
                fee
              }) => ({
                weightMax,
                fee
              })
            )
        );

    this.pricelist
        .ratesReturnZones = (
          this.pricelist
              .ratesReturnZones
              .map(
                ({
                  zoneCode,
                  rates
                }) => ({
                  zoneCode,
                  rates: (
                    items
                      .map(
                        (
                          item,
                          index
                        ) => ({
                          weightMax: (
                            this.pricelist
                                .ratesReturn[
                                  index
                                ]
                                .weightMax
                          ),
                          fee: (
                            item[
                              `fee-${(
                                zoneCode
                              )}`
                            ]
                          )
                        })
                      )
                  )
                })
              )
        );
  }

  public onEditServicesExtra(items) {
    this.pricelist
        .servicesExtra = (
          items
        );
  }

  public onEditSurcharges(items) {
    this.pricelist
        .surcharges = (
          items
        );

    this.fieldsSurcharges = [
      ...this.fieldsSurcharges
    ]; // Triggers field update in OrderedListComponent
  }

  public onLanguageCodeActiveLabel(
    languageCode: string
  ) {
    this.languageCodeActiveLabel = (
      languageCode
    );

    if (
      this.languageCodeActiveLabel &&
      (
        this.languageCodeActiveLabel !==
        DEFAULT_LANGUAGE_CODE
      )
    ) {
      const translation = (
        (
          Array
            .isArray(
              this.pricelist
                  .translations
            )
        ) ?
          (
            this.pricelist
                .translations
                .find(
                  translation => (
                    (
                      (
                        translation
                          .key
                      ) ===
                      'label'
                    ) &&
                    (
                      (
                        translation
                          .languageCode
                      ) ===
                      this.languageCodeActiveLabel
                    )
                  )
                )
          ) :
          null
      );

      if (
        !(
          translation &&
          (
            isStringSafeNotEmpty(
              translation
                .value
            )
          )
        )
      ) {
        this.onPricelistLabel(
          this.pricelist
              .label
        );
      }
    }
  }

  public onPricelistLabel(
    value: string
  ) {
    value = (
      (
        isStringSafeNotEmpty(
          value
        )
      ) ?
        value :
        ''
    );

    if (
      !this.languageCodeActiveLabel ||
      (
        this.languageCodeActiveLabel ===
        DEFAULT_LANGUAGE_CODE
      )
    ) {
      this.pricelist
          .label = (
            value
          );
    }
    else {
      if (
        Array
          .isArray(
            this.pricelist
                .translations
          )
      ) {
        const index = (
          this.pricelist
              .translations
              .findIndex(
                translation => (
                  (
                    (
                      translation
                        .key
                    ) ===
                    'label'
                  ) &&
                  (
                    (
                      translation
                        .languageCode
                    ) ===
                    this.languageCodeActiveLabel
                  )
                )
              )
        );

        if (
          index >
          -1
        ) {
          this.pricelist
              .translations[
                index
              ]
              .value = (
                value
              );
        }
        else {
          this.pricelist
              .translations
              .push({
                key: 'label',
                languageCode: (
                  this.languageCodeActiveLabel
                ),
                value
              });
        }
      }
      else {
        this.pricelist
            .translations = [{
              key: 'label',
              languageCode: (
                this.languageCodeActiveLabel
              ),
              value
            }];
      }
    }
  }

  public onPricelistServicesActive(
    services: Service[]
  ) {
    if (
      !this.pricelist ||
      this.pricelistIsReadOnly
    ) {
      return false;
    }

    this.pricelist
        .services = (
          naturalSort(
            services,
            'codeLegacy'
          )
        );
  }

  public onPricelistUsersActive(
    users: User[]
  ) {
    if (
      !this.pricelist ||
      this.pricelistIsReadOnly
    ) {
      return false;
    }

    this.pricelist
        .users = (
          naturalSort(
            users,
            'code'
          )
        );
  }

  public async onSave() {
    if (
      !(
        this.pricelistIsClone ||
        this.pricelistIsEdit ||
        this.pricelistIsMarkup ||
        this.pricelistIsNew
      )
    ) {
      return false;
    }

    const pricelistObj = {
      ...this.pricelist
    };
  
    const context = (
      this.pricelist
          .context
    );

    this.pricelist = undefined;

    if (
      !(
        (
          pricelistObj
            .rateExtra
            .weightInc
        ) &&
        (
          pricelistObj
            .rateExtra
            .fee
        )
      )
    ) {
      (
        pricelistObj
          .rateExtra
      ) = null;
    }

    if (
      !(
        (
          pricelistObj
            .rateUnit
            .unitInc
        ) &&
        (
          pricelistObj
            .rateUnit
            .fee
        )
      )
    ) {
      (
        pricelistObj
          .rateUnit
      ) = null;
      
      (
        pricelistObj
          .rateUnitHasFranking
      ) = false;

      (
        pricelistObj
          .rateUnitFrankingType
      ) = null;
    }

    if (
      Array
        .isArray(
          pricelistObj
            .rates
        )
    ) {
      pricelistObj
        .rates = (
          pricelistObj
            .rates
            .filter(
              ({
                weightMax,
                fee
              }) => (
                (
                  weightMax !==
                  null
                ) &&
                (
                  typeof weightMax ===
                  'number'
                ) &&
                (
                  fee !==
                  null
                ) &&
                (
                  typeof fee ===
                  'number'
                )
              )
            )
        ); // Remove reassignment when proper validation with error messages is implemented
    }

    if (
      Array
        .isArray(
          pricelistObj
            .ratesZones
        )
    ) {
      pricelistObj
        .ratesZones = (
          pricelistObj
            .ratesZones
            .filter(
              ({
                zoneCode,
                rates
              }) => (
                (
                  isStringSafeNotEmpty(
                    zoneCode
                  )
                ) &&
                (
                  isArrayNotEmpty(
                    rates
                  )
                )
              )
            )
        ); // This is part of API update logic, do not remove after validation is implemented
    }

    if (
      Array
        .isArray(
          pricelistObj
            .ratesReturn
        )
    ) {
      if (
        (
          pricelistObj
            .rateReturnType
        ) ===
        'stepped'
      ) {
        pricelistObj
          .ratesReturn = (
            pricelistObj
              .ratesReturn
              .filter(
                ({
                  weightMax,
                  fee
                }) => (
                  (
                    weightMax !==
                    null
                  ) &&
                  (
                    typeof weightMax ===
                    'number'
                  ) &&
                  (
                    fee !==
                    null
                  ) &&
                  (
                    typeof fee ===
                    'number'
                  )
                )
              )
          ); // Also, remove reassignment when proper validation with error messages is implemented
      }
      else {
        pricelistObj
          .ratesReturn = [];
      }
    }

    if (
      Array
        .isArray(
          pricelistObj
            .ratesReturnZones
        )
    ) {
      if (
        (
          pricelistObj
            .rateReturnType
        ) ===
          'stepped'
      ) {
        pricelistObj
          .ratesReturnZones = (
            pricelistObj
              .ratesReturnZones
              .filter(
                ({
                  zoneCode,
                  rates
                }) => (
                  (
                    isStringSafeNotEmpty(
                      zoneCode
                    )
                  ) &&
                  (
                    isArrayNotEmpty(
                      rates
                    )
                  )
                )
              )
          ); // This is also part of API update logic, do not remove after validation is implemented
      }
      else {
        pricelistObj
          .ratesReturnZones = [];
      }
    }

    if (
      Array
        .isArray(
          pricelistObj
            .services
      )
    ) {
      pricelistObj
        .services = (
          (
            <Service[]>(
              pricelistObj
                .services
              )
          )
          .map(
            pricelistService => (
              pricelistService
                ._id
            )
          )
        )
    };

    if (
      Array
        .isArray(
          pricelistObj
            .surcharges
        )
    ) {
      pricelistObj
        .surcharges = (
          (
            <Surcharge[]>(
              pricelistObj
                .surcharges
            )
          )
          .map(
            surcharge => {
              if (
                (
                  surcharge
                    .feeType
                ) ===
                'fixed'
              ) {
                surcharge
                  .feeRatio = (
                    undefined
                  );
              }
              else if (
                (
                  surcharge
                    .feeType
                ) ===
                'deliveryFeeRatio'
              ) {
                surcharge
                  .fee = (
                    undefined
                  );
              }

              return surcharge;
            }
          )
        );
    }

    if (
      Array
        .isArray(
          pricelistObj
            .users
      )
    ) {
      pricelistObj
        .users = (
          (
            <User[]>(
              pricelistObj
                .users
              )
          )
          .map(
            pricelistUser => (
              pricelistUser
                ._id
            )
          )
        )
    };

    if (this.pricelistIsMarkup) {
      this.pricelistDomain = undefined;
    }

    await (
      this.pricelistService
          .upsert(
            [
              pricelistObj
            ],
            this.pricelistDomain
          )
    );

    this.goBack(
      context
    );
  }

  public setCountryOptionActive(
    code: string,
    forceSetPartnerCode: boolean = false
  ) {
    this.countryOptionActive = (
      this.getCountryOption(
        code
      )
    );

    this.pricelist
        .countryCode = (
          code
        );

    if (
      this.hasSinglePartnerCode ||
      forceSetPartnerCode
    ) {
      this.pricelist
          .partnerCode = (
            this.countryPartnerCodeMap[
              code
            ]
          );
    }

    this.setRegionCodeOptions();

    if (
      !(
        (
          Array
            .isArray(
              this.regionCodeOptions
            )
        ) &&
        (
          (
            this.regionCodeOptions
                .length
          ) >
          0
        )
      )
    ) {
      this.setRegionCodeOptionActive(
        null
      );
    }
  }

  public setCurrencyOptionActive(code) {
    this.currencyOptionActive = (
      this.getCurrencyOption(
        code
      )
    );

    this.pricelist
        .currencyCode = (
          code
        );

    this.pricelist
        .currencySymbol = (
          this.currencyOptionActive
              .symbol
        );

    this.setFeeLabelCurrency();
    this.setRateCurrency();
    this.setRegionCodeOptions();
  }

  public setPricelistIsCod = isCod => {
    this.pricelist
        .isCod = (
          isCod
        );
  }

  public setPricelistIsPos = isPos => {
    this.pricelist
        .isPos = (
          isPos
        );
  }

  public setPricelistRateReturnType = type => {
    this.pricelist
        .rateReturnType = (
          type
        );

    if (
      (
        this.pricelist
            .rateReturnType
      ) ===
      'stepped'
    ) {
      setTimeout(
        () => (
          this.window
          .scrollTo({
            top: (
              document
                .body
                .scrollHeight
            )
          })
        ),
        0
      );
    }
  }

  public setPricelistRateUnitFrankingType = type => {
    this.pricelist
        .rateUnitFrankingType = (
          type
        );
  }

  public setPricelistRateUnitHasFranking = value => {
    if (
      !(
        Array
          .isArray(
            this.rateUnitFrankingTypes
          )
      )
    ) {
      return false;
    }

    this.pricelist
        .rateUnitHasFranking = (
          value
        );

    this.pricelist
        .rateUnitFrankingType = (
          (
            this.pricelist
                .rateUnitHasFranking
          ) ?
            this.rateUnitFrankingTypes[0] :
            null
        );
  }

  public setRateCodPriceCurrencyCode(
    currencyCode: string
  ) {
    this.rateCodPriceCurrencyCode = (
      currencyCode
    );

    this.setRatesCodSteppedPriceCurrencyCode();
    this.setRatesCodItemEmpty();

    this.setFeeLabelCurrencyRatesClone(
      'fieldsRatesCod'
    );
  }

  public setValidTimestampStart(
    value: string
  ) {
    this.setValidTimestampRaw(
      'validTimestampStart',
      value
    );
  }

  public setValidTimestampEnd(
    value: string
  ) {
    this.setValidTimestampRaw(
      'validTimestampEnd',
      value,
      true
    );
  }

  public togglePricelistRatesZone(
    ratesZoneCodesRaw
  ) {
    const ratesZoneCodes = (
      ratesZoneCodesRaw
        .filter(
          zoneCode => (
            !!zoneCode
          )
        )
    );

    this.pricelist
        .ratesZones = (
          this.pricelist
              .ratesZones
              .filter(
                ({
                  zoneCode
                }) => (
                  ratesZoneCodes
                    .includes(
                      zoneCode
                    )
                )
              )
        );

    if (
      isArrayEmpty(
        ratesZoneCodes
      )
    ) {
      return true;
    }

    for (let i = 0; i < (
      ratesZoneCodes
        .length
    ); i++) {
      const ratesZoneCode = (
        ratesZoneCodes[i]
      );

      const pricelistRatesZoneIndex = (
        this.pricelist
            .ratesZones
            .findIndex(
              ratesZone => (
                (
                  ratesZone
                    .zoneCode
                ) ===
                ratesZoneCode
              )
            )
      );

      if (
        pricelistRatesZoneIndex ===
        -1
      ) {
        this.pricelist
            .ratesZones
            .push(
              new RatesZone(
                ratesZoneCode
              )
            );
      }
    }
  }

  public togglePricelistRatesReturnZone(
    ratesReturnZoneCodesRaw
  ) {
    const ratesReturnZoneCodes = (
      ratesReturnZoneCodesRaw
        .filter(
          zoneCode => (
            !!zoneCode
          )
        )
    );

    this.pricelist
        .ratesReturnZones = (
          this.pricelist
              .ratesReturnZones
              .filter(
                ({
                  zoneCode
                }) => (
                  ratesReturnZoneCodes
                    .includes(
                      zoneCode
                    )
                )
              )
        );

    if (
      isArrayEmpty(
        ratesReturnZoneCodes
      )
    ) {
      return true;
    }

    for (let i = 0; i < (
      ratesReturnZoneCodes
        .length
    ); i++) {
      const ratesReturnZoneCode = (
        ratesReturnZoneCodes[i]
      );

      const pricelistRatesReturnZoneIndex = (
        this.pricelist
            .ratesReturnZones
            .findIndex(
              ratesReturnZone => (
                (
                  ratesReturnZone
                    .zoneCode
                ) ===
                ratesReturnZoneCode
              )
            )
      );

      if (
        pricelistRatesReturnZoneIndex ===
        -1
      ) {
        this.pricelist
            .ratesReturnZones
            .push(
              new RatesZone(
                ratesReturnZoneCode
              )
            );
      }
    }
  }

  private setCountryPartnerCodeMap() {
    this.countryPartnerCodeMap = {};

    [
      ...(
        this.countryOptions
            .zones
      ),
      ...(
        this.countryOptions
            .preferred
      ),
      ...(
        this.countryOptions
            .other
      )
    ].forEach(
      country => {
        const partnerCodeOverride = (
          getCountryPartnerCodeOverride(
            country
              .code
          )
        );

        if (partnerCodeOverride) {
          return (
            this.countryPartnerCodeMap[
              country
                .code
            ] = partnerCodeOverride
          );
        }

        const partnerCodeLocal = (
          this.partnerCodes
              .filter(
                partnerCode => (
                  partnerCode
                    .toLowerCase()
                    .startsWith(
                      `${(
                        country
                          .code
                      )}-`
                    )
                )
              )[0]
        );

        if (partnerCodeLocal) {
          this.countryPartnerCodeMap[
            country
              .code
          ] = partnerCodeLocal;
        }
      }
    );
  }

  private setCurrencyPriceMaxOptionActive(code) {
    this.currencyPriceMaxOptionActive = (
      this.getCurrencyOption(
        code
      )
    );

    this.pricelist
        .priceMaxCurrency = (
          code
        );

    this.pricelist
        .priceMaxCurrencySymbol = (
          this.currencyPriceMaxOptionActive
              .symbol
        );
  }

  private getCountryOption(code): Country {
    return (
      getModelOption(
        this.countryOptions,
        code
      )
    );
  }

  private getCurrencyOption(code): Currency {
    return (
      getModelOption(
        this.currencyOptions,
        code
      )
    );
  }

  private getRegionCodeLabel(zoneCode: string) {
    if (!this.hasRegionCodes) {
      return null;
    }

    const regionCodeOption = (
      this.regionCodeOptions
          .find(
            ({
              value
            }) => (
              value ===
              zoneCode
            )
          )
    );

    if (!regionCodeOption) {
      return null;
    }

    return (
      regionCodeOption
        .label
    );
  }

  private goBack(
    context?: string
  ) {
    if (
      !context &&
      this.pricelist
    ) {
      context = (
        this.pricelist
            .context
      );
    }

    if (!context) {
      return (
        this.router
            .navigate(
              [
                (
                  this.pricelistDomainIsCosts ?
                    `/cost-lists/context/active` :
                    `/`
                )
              ],
              {
                queryParamsHandling: (
                  'preserve'
                )
              }
            )
      );
    }

    this.router
        .navigate(
          [
            (
              this.pricelistDomainIsCosts ?
                `/cost-lists/context/${(
                  (
                    getContextPath(
                      context
                    )
                  )
                )}` :
                `/${(
                  (
                    getContextPath(
                      context
                    )
                  )
                )}`
            )
          ],
          {
            queryParamsHandling: (
              'preserve'
            )
          }
        );
  }

  private setAuthEmail() {
    this.authEmail = (
      this.msalService
          .getAccount()
          .userName
    );
  }

  private setRateCurrency() {
    if (
      this.currencyOptionActive &&
      (
        (
          this.currencyOptionActive
              .code
        ) !==
        DEFAULT_CURRENCY_CODE
      )
    ) {
      this.currencyRateFieldShow = (
        this.pricelistDomainIsCosts &&
        !this.pricelistIsMarkup
      );
    }
    else {
      this.currencyRateFieldShow = false;

      this.pricelist
          .rateCurrency = null;
    }
  }
  
  private setRatesCodItemEmpty() {
    this.itemEmptyRatesCod = {
      priceCurrency: (
        this.rateCodPriceCurrencyCode
      ),
      priceMax: null,
      fee: null,
      feeType: null
    };
  }

  private setRatesCodSteppedPriceCurrencyCode() {
    this.pricelist
        .ratesCod = (
          this.pricelist
              .ratesCod
              .map(
                rateCod => {
                  if (
                    !(
                      (
                        rateCod
                          .feeType
                      ) &&
                      (
                        rateCod
                          .feeType
                          .startsWith(
                            'stepped'
                          )
                      )
                    )
                  ) {
                    return rateCod;
                  }

                  return {
                    ...rateCod,
                    priceCurrency: (
                      this.rateCodPriceCurrencyCode
                    )
                  };
                }
              )
        );
  }

  private setFeeLabelCurrency() {
    this.setFeeLabelCurrencyRatesClone(
      'fieldsRates'
    );
    
    this.setFeeLabelCurrencyRatesClone(
      'fieldsRatesCod'
    );

    this.setFeeLabelCurrencyRatesClone(
      'fieldsRatesReturn'
    );
  }

  private setFeeLabelCurrencyRatesClone(member) {
    const fieldsRates = [
      ...this[
        member
      ]
    ];

    this.setFeeLabelCurrencyRatesMutate(
      fieldsRates
    );

    this[
      member
    ] = (
      fieldsRates
    );
  }

  private setFeeLabelCurrencyRatesMutate(fieldsRates) {
    fieldsRates
      .forEach(
        (
          rate,
          index
        ) => {
          if (
            rate
              .label
              .includes(
                'Fee'
              )
          ) {
            fieldsRates[
              index
            ].label = `${(
                rate
                  .label
                  .split(
                    ' ('
                  )[0]
              )} (${
                this.pricelist
                    .currencySymbol
              })`;
          }

          if (
            this.rateCodPriceCurrencyCode &&
            (
              rate
                .label
                .includes(
                  'Max. COD Amount'
                )
            )
          ) {
            fieldsRates[
              index
            ].label = `${(
                rate
                  .label
                  .split(
                    ' ('
                  )[0]
              )} (${
                this.rateCodPriceCurrencySymbol
              })`;
          }
        }
      );
  }

  private setPricelistDomain() {
    this.pricelistDomain = (
      (
        this.router
            .url
            .startsWith(
              '/cost-lists'
            )
      ) ?
        'costs' :
        null
    );
  }

  private async setPartnerCodes() {
    this.partnerCodes = (
      await 
        this.pricelistService
            .getPartnerCodes()
    );
  }

  private async setPricelist() {
    const params = (
      await 
        this.route
            .params
            .pipe(
              first()
            )
            .toPromise()
    );
    
    this.pricelistIsNew = (
      !params
        ._id
    );
    
    if (this.pricelistIsNew) {
      this.pricelist = new Pricelist(
        (
          this.countryOptions
              .zones[0]
              .code
        ),
        DEFAULT_CURRENCY_CODE,
        getCurrencySymbol(
          DEFAULT_CURRENCY_CODE
        ),
        500,
        DEFAULT_CURRENCY_CODE,
        this.authEmail,
        (
          this.services
              .filter(
                service => (
                  (
                    PRICELIST_SERVICE_CODES_LEGACY_DEFAULT
                      .includes(
                        service
                          .codeLegacy
                      )
                  )
                )
              )
        )
      );

      this.setRateCodPriceCurrencyCode(
        DEFAULT_CURRENCY_CODE
      );

      return;
    }

    this.pricelist = (
      await
        this.pricelistService
            .get(
              params._id,
              this.pricelistDomain
            )
    );

    if (
      !(
      this.pricelist
          .rateExtra
      )
    ) {
      this.pricelist
          .rateExtra = {
            weightInc: null,
            fee: null
          };
    }

    if (
      !(
      this.pricelist
          .rateUnit
      )
    ) {
      this.pricelist
          .rateUnit = {
            unitInc: null,
            fee: null
          };
    }

    this.setRateCodPriceCurrencyCode(
      (
        this.pricelistRateCodStepped &&
        (
          this.pricelistRateCodStepped
              .priceCurrency
        )
      ) ?
        (
          this.pricelistRateCodStepped
              .priceCurrency
        ) :
        (
          this.pricelist
            .currencyCode
        )
    );
  }

  private async setPricelistIsClone() {
    const urlSegments =(
      await 
        this.route
            .url
            .pipe(
              first()
            )
            .toPromise()
    );

    this.pricelistIsClone = (
      (
        (
          urlSegments
            .length
        ) >
        2
      ) &&
      (
        (
          urlSegments[2]
            .path
        ).startsWith(
          'clone'
        )
      )
    );

    if (
      this.pricelist &&
      this.pricelistIsClone
    ) {
      this.showRefreshWarning = true;

      if (this.pricelistDomainIsCosts) {
        this.setPricelistIncrease();
      }
      else {
        this.setPricelistDiscount();
      }
      
      this.pricelist
          .isDraft = (
            true
          );

      this.pricelist
          .label = `${(
            this.pricelist
                .label
          )} (Clone)`;

      this.pricelist
          .ownerEmail = (
            this.authEmail
          );

      delete (
        this.pricelist
            ._id
      );

      delete (
        this.pricelist
            .currencySymbol
      );

      delete (
        this.pricelist
            .labelHuman
      );
    }
  }

  private async setPricelistIsMarkup() {
    const urlSegments =(
      await 
        this.route
            .url
            .pipe(
              first()
            )
            .toPromise()
    );

    this.pricelistIsMarkup = (
      (
        (
          urlSegments
            .length
        ) >
        2
      ) &&
      (
        (
          urlSegments[2]
            .path
        ) ===
        'markup'
      )
    );

    if (
      this.pricelist &&
      this.pricelistIsMarkup
    ) {
      this.showRefreshWarning = true;
      
      this.setPricelistIncrease();
      
      this.pricelist
          .isDraft = (
            true
          );

      this.pricelist
          .label = `${(
            this.pricelist
                .label
          )} - ${(
            PRICELIST_OFFICIAL_DEFAULT
              .labelSuffix
          )}`;

      this.pricelist
          .orderMin = (
            PRICELIST_OFFICIAL_DEFAULT
              .orderMin
          );

      this.pricelist
          .orderMax = (
            PRICELIST_OFFICIAL_DEFAULT
              .orderMax
          );

      this.pricelist
          .ownerEmail = (
            this.authEmail
          );

      delete (
        this.pricelist
            ._id
      );

      delete (
        this.pricelist
            .currencySymbol
      );

      delete (
        this.pricelist
            .labelHuman
      );
    }
  }

  private setPricelistIsEdit() {
    this.pricelistIsEdit = (
      this.pricelist &&
      !this.pricelistIsClone &&
      !this.pricelistIsMarkup &&
      !this.pricelistIsNew  &&
      (
        this.pricelist
            .isDraft
      ) &&
      (
        this.authEmail ===
        (
          this.pricelist
              .ownerEmail
        )
      )
    );

    if (this.pricelistIsEdit) {
      delete (
        this.pricelist
            .currencySymbol
      );

      delete (
        this.pricelist
            .labelHuman
      );
    }
  }

  private setPricelistIsReadOnly() {
    this.pricelistIsReadOnly = (
      !this.pricelistIsEdit &&
      (
        !(
          this.pricelistIsClone ||
          this.pricelistIsMarkup ||
          this.pricelistIsNew
        )
      )
    );
  }

  private setPricelistCurrency(
    markupRatioMap: Ratios
  ) {
    const {
      currencyCode,
      currencyRate
    } = markupRatioMap;

    this.setCurrencyOptionActive(
      currencyCode
    );

    if (
      !(
        currencyRate &&
        (
          currencyCode ===
          DEFAULT_CURRENCY_CODE
        )
      )
    ) {
      return false;
    }

    const applyConversion = (
      fee: number,
      ratioMap: Ratios,
      key: string,
      isRatio: boolean = false
    ) => (
      isRatio ?
        fee :
        (
          parseFloat(
            (
              fee *
              currencyRate
            )
            .toFixed(
              2
            )
          )
        )
    );

    this.setPricelistAdjustment(
      markupRatioMap,
      applyConversion
    );
  }

  private setPricelistDiscount() {
    const discountRatioMap = (
      this.pricelistService
          .cloneDiscountRatioMap[
            this.pricelist
                ._id
          ]
    );

    this.pricelistService
        .resetCloneRatioMap(
          this.pricelistDomainIsCosts
        );

    if (!discountRatioMap) {
      this.showRefreshWarning = false;

      return (
        this.goBack()
      );
    }

    const applyDiscount = (
      fee: number,
      ratioMap: Ratios,
      key: string,
      isRatio: boolean = false
    ) => (
      parseFloat(
        (
          fee *
          (
            100 -
            (
              ratioMap[
                key
              ]
            )
          ) /
          100
        ).toFixed(
          isRatio ?
            3 :
            2
        )
      )
    );

    this.setPricelistAdjustment(
      discountRatioMap,
      applyDiscount
    );
  }

  private setPricelistIncrease() {
    const markupRatioMap: Ratios = (
      this.pricelistIsClone ?
        (
          this.pricelistService
              .markupCostsRatioMap[
                this.pricelist
                    ._id
              ]
        ) :
        (
          this.pricelistService
              .markupRatioMap[
                this.pricelist
                    ._id
              ]
        )
    );

    if (this.pricelistIsClone) {
      this.pricelistService
          .resetCloneRatioMap(
            true
          );
    }
    else {
      this.pricelistService
          .resetMarkupRatioMap();
    }

    if (!markupRatioMap) {
      this.showRefreshWarning = false;

      return (
        this.goBack()
      );
    }

    if (
      this.pricelistIsClone &&
      !(
        markupRatioMap
          .currencyCode
      )
    ) {
      markupRatioMap
        .currencyCode = (
          this.pricelist
              .currencyCode
        );
    }

    this.setPricelistCurrency(
      markupRatioMap
    );

    const applyIncrease = (
      fee: number,
      ratioMap: Ratios,
      key: string,
      isRatio: boolean = false
    ) => (
      parseFloat(
        (
          fee *
          (
            100 +
            (
              ratioMap[
                key
              ]
            )
          ) /
          100
        ).toFixed(
          isRatio ?
            3 :
            2
        )
      )
    );

    this.setPricelistAdjustment(
      markupRatioMap,
      applyIncrease
    );
  }

  private setPricelistAdjustment(
    ratioMap: Ratios,
    applyAdjustment: Function
  ) {
    setPricelistAdjustment(
      this.pricelist,
      ratioMap,
      applyAdjustment
    );
  }

  private setRateReturnTypes() {
    this.rateReturnTypes = (
      getRateReturnTypes()
    );
  }

  private setRateUnitFrankingTypes() {
    this.rateUnitFrankingTypes = (
      getRateUnitFrankingTypes()
    );
  }

  private setRegionCodeOptions() {
    this.regionCodeOptions = (
      getRegionCodeOptions(
        this.countryOptionActive
            .code
      )
      .map(
        regionCodeOption => ({
          ...regionCodeOption,
          value: (
            regionCodeOption
              .regionCodes
              .join(',')
          )
        })
      )
    );
  }

  private setRegionCodeOptionActive(
    regionCodesValue: string
  ) {
    if (
      regionCodesValue ===
      null
    ) {
      this.regionCodeOptionActive = [];

      return (
        this.pricelist
            .regionCodes = []
      );
    }
    
    if (
      !(
        (
          typeof regionCodesValue ===
          'string'
        ) &&
        (
          Array
            .isArray(
              this.regionCodeOptions
            )
        ) &&
        (
          (
            this.regionCodeOptions
                .length
          ) >
          0
        )
      )
    ) {
      return false;
    }

    this.regionCodeOptionActive = (
      this.regionCodeOptions
          .filter(
            option => (
              (
                option
                  .value
              ) ===
              regionCodesValue
            )
          )[0]
    );

    this.pricelist
        .regionCodes = (
          this.regionCodeOptionActive ?
            (
              this.regionCodeOptionActive
               .regionCodes
            ) :
            []
        );
  }

  private async setServices() {
    this.services = (
      await (
        this.serviceService
            .getAll()
      )
    );
  }

  private async setUsers() {
    this.users = (
      await (
        this.authService
            .getAll()
      )
    );
  }

  private setValidTimestampRaw(
    key: string,
    value: string,
    isEndOfDay: boolean = false
  ) {
    if (!this.pricelist) {
      return false;
    }

    this.pricelist[
      key
    ] = (
      getUtcDateTimestamp(
        value,
        isEndOfDay
      )
    );
  }

  private setVatRates() {
    this.vatRates = (
      getVatRates()
    );
  }
}