<nb-spinner
  size="giant"
  status="primary"
  [message]="null"
  [ngClass]="{
    'has-overlay': (
      dialogActionLoading
    )
  }"
  *ngIf="(
    pricelistsLoading ||
    dialogActionLoading
  )">
</nb-spinner>

<ng-container
  *ngIf="!pricelistsLoading">
  <nb-list
    class="top-list">
    <nb-list-item
      class="add-item">
      <nb-flip-card
        [showToggleButton]="false">
        <nb-card-front>
          <nb-card
            class="card-actions">
            <nb-card-header>
              <span>
                Actions
              </span>
            </nb-card-header>

            <nb-card-body>
              <div
                class="filter-container">
                <mat-form-field>
                  <mat-label>
                    <span>
                      Search (Min. 3 Characters)
                    </span>
                  </mat-label>

                  <input
                    matInput
                    placeholder="Type here..."
                    [ngModel]="term"
                    (ngModelChange)="onTerm($event)"/>

                  <button
                    matSuffix
                    mat-icon-button
                    *ngIf="!hasTerm">
                    <img
                      src="assets/vectors/search.svg"/>
                  </button>

                  <button
                    matSuffix
                    mat-icon-button
                    class="active"
                    *ngIf="!hasTerm">
                    <img
                      src="assets/vectors/search-active.svg"/>
                  </button>

                  <button
                    matSuffix
                    mat-icon-button
                    class="clear-button"
                    (click)="(
                      onClearTerm()
                    )"
                    *ngIf="hasTerm">
                    <mat-icon>
                      close
                    </mat-icon>
                  </button>
                </mat-form-field>

                <mat-form-field>
                  <mat-label>
                    Partner Code
                  </mat-label>

                  <mat-select
                    disableOptionCentering
                    placeholder="Partner Code"
                    [ngModel]="pagePartnerCode"
                    (ngModelChange)="onSetPartnerCodeActive($event)">
                    <mat-select-trigger>
                      <span
                        [innerHTML]="(
                          (
                            pagePartnerCode !==
                            'null'
                          ) ?
                            pagePartnerCode :
                            'Select'
                        )">
                      </span>
                    </mat-select-trigger>

                    <mat-option
                      [value]="partnerCode"
                      *ngFor="
                        let partnerCode of (
                          partnerCodes
                        )
                      ">
                      {{
                        (
                          partnerCode !==
                          'null'
                        ) ?
                          partnerCode :
                          'None'
                      }}
                    </mat-option>
                  </mat-select>
                </mat-form-field>

                <mat-form-field
                  *ngIf="!isWms">
                  <mat-label>
                    {{ countryOptionLabel }}
                  </mat-label>

                  <mat-select
                    disableOptionCentering
                    placeholder="Country"
                    [ngModel]="countryOptionActive.code"
                    (ngModelChange)="onSetCountryOptionActive($event)">
                    <mat-select-trigger>
                      <span
                        [innerHTML]="(
                          (
                            (
                              countryOptionActive
                                .code
                            ) !==
                            'null'
                          ) ?
                            (
                              countryOptionActive
                                .name
                            ) :
                            'Select'
                        )">
                      </span>
                    </mat-select-trigger>

                    <mat-option
                      [value]="(
                        countryOptions
                          .null
                          .code
                      )">
                      {{(
                        countryOptions
                          .null
                          .name
                      )}}
                    </mat-option>

                    <mat-optgroup
                      label="Global Zones">
                      <mat-option
                        [value]="(
                          country
                            .code
                        )"
                        *ngFor="
                          let country of (
                            countryOptions
                              .zones
                          )
                        ">
                        {{(
                          country
                            .name
                        )}}
                      </mat-option>
                    </mat-optgroup>

                    <mat-optgroup
                      label="EU Network">
                      <mat-option
                        [value]="(
                          country
                            .code
                        )"
                        *ngFor="
                          let country of (
                            countryOptions
                              .preferred
                          )
                        ">
                        {{(
                          country
                            .name
                        )}}
                      </mat-option>
                    </mat-optgroup>

                    <mat-optgroup
                      label="Other">
                      <mat-option
                        [value]="(
                          country
                            .code
                        )"
                        *ngFor="
                          let country of (
                            countryOptions
                              .other
                          )
                        ">
                        {{(
                          country
                            .name
                        )}}
                      </mat-option>
                    </mat-optgroup>
                  </mat-select>
                </mat-form-field>
              </div>

              <div
                class="button-container"
                *ngIf="(
                  (
                    pageContext ===
                    'live'
                  ) &&
                  !isWms
                )">
                <button
                  nbButton
                  size="small"
                  (click)="onNew()">
                  <span>
                    &#43;
                  </span>
                  <span>
                    Add New
                  </span>
                </button>
              </div>
            </nb-card-body>
          </nb-card>
        </nb-card-front>
      </nb-flip-card>
    </nb-list-item>
  </nb-list>

  <nb-list
    class="top-list"
    *ngIf="(
      (
        pricelists
          .length
      ) ===
      0
    )">
    <nb-list-item
      class="no-items">
      <nb-flip-card
        [showToggleButton]="false">
        <nb-card-front>
          <nb-card
            [status]="(
              (
                pageContext ===
                'archived'
              ) ?
                'warning' :
                (
                  pageContext ===
                  'removed'
                ) ?
                  'danger' :
                  'primary'
            )">
            <nb-card-header>
              No {{ pageContext | titlecase }} {{ pageDomainModelName }}s{{searchActiveVerb}}
            </nb-card-header>

            <nb-card-body>
              <p>
                As soon as {{(
                  pageDomainModelName
                )}}s{{(
                  searchPhrase
                )}} are {{(
                  pageContextVerb
                )}}, they will be listed here
              </p>
            </nb-card-body>
          </nb-card>
        </nb-card-front>
      </nb-flip-card>
    </nb-list-item>
  </nb-list>

  <nb-actions
    size="large"
    *ngIf="(
      pagePrevious !==
      null
    )">
    <nb-action
      [ngClass]="{
        'loading': pageLoading
      }"
      [nbSpinner]="pageLoading"
      nbSpinnerSize="small"
      [badgeText]="(
        pageLoading ?
          (
            page +
            1
          ) :
          (
            pagePrevious +
            1
          )
      )"
      badgePosition="'bottom-end'"
      badgeStatus="primary"
      icon="arrow-up"
      (click)="(
        navigateToPage(
          page -
          1
        )
      )">
    </nb-action>

    <nb-action
      class="page-one"
      [ngClass]="{
        'loading': pageLoading
      }"
      [nbSpinner]="pageLoading"
      nbSpinnerSize="small"
      [badgeText]="1"
      badgePosition="'bottom-end'"
      badgeStatus="primary"
      icon="home"
      (click)="(
        navigateToPage(
          0
        )
      )"
      *ngIf="(
        !(
          pageLoading ||
          (
            [
              0,
              1
            ].includes(
              page
            )
          )
        )
      )">
    </nb-action>
  </nb-actions>

  <nb-list
    nbInfiniteList
    [ngClass]="{
      'wms': isWms
    }"
    [listenWindowScroll]="true"
    [threshold]="pageScrollThreshold"
    (bottomThreshold)="onBottomThreshold()">

    <nb-list-item
      [style.zIndex]="(
        (
          pricelists
            .length
        ) -
        $index
      )"
      *ngFor="
        let pricelist of pricelists;
        let $index = index;
      ">
      <nb-flip-card
        [showToggleButton]="(
          !isWms
        )">
        <nb-card-front>
          <nb-card
            status="primary">
            <nb-card-header>
              {{ pricelist.labelHuman }}

              <div
                class="badges"
                [ngClass]="{
                  'not-live': (
                    !isLive
                  )
                }">
                <nb-badge
                  text="Draft"
                  status="warning"
                  [ngClass]="{
                    'disabled': !(
                      pricelist
                        .isDraft
                    )
                  }"
                  *ngIf="isLive">
                </nb-badge>

                <ng-container
                  *ngIf="!isWms">
                  <nb-badge
                    text="POS"
                    status="success"
                    class="pos-badge"
                    [ngClass]="{
                      'disabled': !(
                        pricelist
                          .isPos
                      )
                    }">
                  </nb-badge>
                  <nb-badge
                    text="COD"
                    status="success"
                    [ngClass]="{
                      'disabled': !(
                        pricelist
                          .isCod
                      )
                    }">
                  </nb-badge>
                </ng-container>
              </div>

              <img
                class="flag"
                [src]="
                '/assets/vectors/flags/' + pricelist.countryCode + '.svg'
                "/>
            </nb-card-header>

            <nb-card-body>
              <p>
                Currency:
                <b>
                  {{ pricelist.currencySymbol }} ({{
                    pricelist.currencyCode
                  }})
                </b>
              </p>
              <p
                *ngIf="pricelist.partnerCode">
                Partner:
                <b>
                  {{ pricelist.partnerCode }}
                </b>
              </p>
              <p
                *ngIf="(
                  allUserNameMap &&
                    allUserNameMap[
                      pricelist
                        .ownerEmail
                    ]
                )">
                Author:
                <b>
                  {{ allUserNameMap[
                    pricelist
                      .ownerEmail
                  ] }}
                </b>
              </p>
            </nb-card-body>
          </nb-card>
        </nb-card-front>
        <nb-card-back>
          <nb-card>
            <nb-card-header>
              {{ pricelist.labelHuman }} - Actions

              <div
                class="badges"
                *ngIf="isLive">
                <nb-badge
                  text="Draft"
                  status="warning"
                  *ngIf="pricelist.isDraft">
                </nb-badge>
                <nb-badge
                  text="Live"
                  status="success"
                  *ngIf="!pricelist.isDraft">
                </nb-badge>
              </div>
            </nb-card-header>

            <nb-card-body>
              <p>
                Select preferred action below:
              </p>
              <p>
                <button
                  nbButton
                  size="small"
                  status="success"
                  (click)="onView(
                    pricelist
                  )"
                  *ngIf="(
                    !isRemoved &&
                    (
                      !pricelist.isDraft ||
                      (
                        (
                          pricelist
                            .ownerEmail
                        ) !==
                        authEmail
                      )
                    )
                  )">
                  <nb-icon
                    icon="eye">
                  </nb-icon>
                  View
                </button>

                <button
                  nbButton
                  size="small"
                  status="success"
                  (click)="onEdit(
                    pricelist
                  )"
                  *ngIf="(
                    pricelist.isDraft &&
                    isLive &&
                    (
                      (
                        pricelist
                          .ownerEmail
                      ) ===
                      authEmail
                    )
                  )">
                  <nb-icon
                    icon="edit">
                  </nb-icon>
                  Edit
                </button>

                <button
                  nbButton
                  size="small"
                  status="primary"
                  (click)="(
                    onMarkup(
                      pricelist
                    )
                  )"
                  *ngIf="(
                    isLive &&
                    isCostLists
                  )">
                  <nb-icon
                    icon="copy">
                  </nb-icon>
                  Mark Up
                </button>

                <button
                  nbButton
                  size="small"
                  status="info"
                  class="open-button"
                  (click)="(
                    onOpen(
                      pricelist
                    )
                  )">
                  <nb-icon
                    icon="book-open-outline">
                  </nb-icon>
                  Open

                  <biz-language-select
                    [languageCodes]="(
                      getPricelistLanguageCodes(
                        pricelist
                      )
                    )"
                    [languageCodeActive]="languageCodeDefault"
                    (languageCodeActiveChange)="(
                      onOpen(
                        pricelist,
                        $event
                      )
                    )">
                  </biz-language-select>
                </button>

                <button
                  nbButton
                  size="small"
                  status="warning"
                  (click)="(
                    onClone(
                      pricelist
                    )
                  )"
                  *ngIf="isLive">
                  <nb-icon
                    icon="copy">
                  </nb-icon>
                  Clone
                </button>

                <button
                  nbButton
                  size="small"
                  status="primary"
                  (click)="(
                    onGenerate(
                      pricelist
                    )
                  )"
                  *ngIf="(
                    isLive &&
                    !isCostLists
                  )">
                  <nb-icon
                    icon="grid">
                  </nb-icon>
                  Generate
                </button>

                <button
                  nbButton
                  size="small"
                  status="warning"
                  class="publish-button"
                  (click)="(
                    onPublish(
                      pricelist
                    )
                  )"
                  *ngIf="(
                    pricelist.isDraft &&
                    isLive
                  )">
                  <nb-icon
                    icon="cloud-upload">
                  </nb-icon>
                  Publish
                </button>

                <button
                  nbButton
                  size="small"
                  status="warning"
                  class="republish-button"
                  (click)="(
                    onRepublish(
                      pricelist
                    )
                  )"
                  *ngIf="isArchived">
                  <nb-icon
                    icon="trash-2">
                  </nb-icon>
                  Republish
                </button>

                <button
                  nbButton
                  size="small"
                  status="danger"
                  (click)="(
                    onRemove(
                      pricelist
                    )
                  )"
                  *ngIf="(
                    pricelist.isDraft &&
                    !isRemoved &&
                    (
                      (
                        pricelist
                          .ownerEmail
                      ) ===
                      authEmail
                    )
                  )">
                  <nb-icon
                    icon="trash-2">
                  </nb-icon>
                  Remove
                </button>

                <button
                  nbButton
                  size="small"
                  status="warning"
                  (click)="(
                    onRestore(
                      pricelist
                    )
                  )"
                  *ngIf="(
                    isRemoved &&
                    (
                      (
                        pricelist
                          .ownerEmail
                      ) ===
                      authEmail
                    )
                  )">
                  <nb-icon
                    icon="trash-2">
                  </nb-icon>
                  Restore
                </button>
                
                <button
                  nbButton
                  size="small"
                  (click)="(
                    onArchive(
                      pricelist
                    )
                  )"
                  *ngIf="(
                    !(
                      pricelist.isDraft ||
                      isArchived
                    )
                  )">
                  <nb-icon
                    icon="archive">
                  </nb-icon>
                  Archive
                </button>
              </p>
            </nb-card-body>
          </nb-card>
        </nb-card-back>
      </nb-flip-card>
    </nb-list-item>
  </nb-list>

  <nb-actions
    class="next"
    size="large"
    *ngIf="(
      pageNext !==
      null
    )">
    <nb-action
      [ngClass]="{
        'loading': pageLoading
      }"
      [nbSpinner]="pageLoading"
      nbSpinnerSize="small"
      [badgeText]="pageNext + 1"
      badgePosition="'top-end'"
      badgeStatus="primary"
      icon="arrow-down"
      (click)="getPage(
        page +
        1
      )">
    </nb-action>
  </nb-actions>
</ng-container>