import {
  NgModule
} from '@angular/core';

import {
  DragDropModule
} from '@angular/cdk/drag-drop';

import {
  FormsModule
} from '@angular/forms';

import {
  BrowserModule
} from '@angular/platform-browser';

import {
  NbIconModule,
  NbInputModule,
  NbSelectModule
} from '@nebular/theme';

import {
  OrderedListComponent
} from './ordered-list.component';

@NgModule({
  declarations: [
    OrderedListComponent
  ],
  imports: [
    BrowserModule,
    DragDropModule,
    FormsModule,
    NbIconModule,
    NbInputModule,
    NbSelectModule
  ],
  exports: [
    OrderedListComponent
  ]
})
export class OrderedListModule { }