import {
  Component,
  Input,
  Output,
  EventEmitter
} from '@angular/core';

import {
  Translation
} from '../../models';

import { 
  DEFAULT_LANGUAGE_CODE
} from '../../app/app.const';

@Component({
  selector: 'biz-wysiwyg',
  templateUrl: 'wysiwyg.component.html',
  styleUrls: [
    'wysiwyg.component.scss'
  ]
})
export class WysiwygComponent {
  @Input(
    'key'
  ) public key: string;

  @Input(
    'value'
  ) public value: string;

  @Input(
    'placeholder'
  ) public placeholder: string;

  @Input(
    'readOnly'
  ) public readOnly: boolean = false;

  @Input(
    'required'
  ) public required: boolean = false;

  @Input(
    'minLength'
  ) public minLength: string;

  @Input(
    'maxLength'
  ) public maxLength: string;

  @Input(
    'translations'
  ) public translations: Translation[];

  @Output(
    'edit'
  ) public onEdit: EventEmitter<{
    value: string;
    languageCode: string;
  }> = (
    new EventEmitter()
  );

  public format: string = 'html';
  public theme: string = 'snow';

  public languageCodeActive: string = (
    DEFAULT_LANGUAGE_CODE
  );

  public preserveWhitespace: boolean = true;
  public trimOnValidation: boolean = true;

  public modules: object = {
    toolbar: [
      [
        'bold',
        'italic',
        'underline',
        'strike'
      ],
      [
        {
          'list': 'ordered'
        },
        {
          'list': 'bullet'
        }
      ],
      [
        {
          'script': 'sub'
        },
        {
          'script': 'super'
        }
      ],
      [
        'clean'
      ]
    ]
  };

  public ngOnInit() {
    if (!this.valueIsValid) {
      this.value = '';
    }
  }

  public get valueText() {
    if (
      !this.languageCodeActive ||
      (
        this.languageCodeActive ===
        DEFAULT_LANGUAGE_CODE
      )
    ) {
      return this.value;
    }

    const translationActive = (
      (
        Array
          .isArray(
            this.translations
          )
      ) ?
        (
          this.translations
              .find(
                translation => (
                  (
                    this.key ===
                    (
                      translation
                        .key
                    )
                  ) &&
                  (
                    this.languageCodeActive ===
                    (
                      translation
                        .languageCode
                    )
                  )
                )
              )
        ) :
        undefined
    );

    return (
      translationActive ?
        (
          translationActive
            .value
        ) :
        ''
    );
  }

  public get valueIsValid() {
    return (
      (
        this.value !==
        null
      ) &&
      (
        typeof this.value ===
        'string'
      )
    );
  }

  public get valueTextIsValid() {
    return (
      (
        this.value !==
        null
      ) &&
      (
        typeof this.value ===
        'string'
      )
    );
  }

  public setValue(value) {
    value = (
      value ||
      ''
    );

    this.onEdit
        .emit({
          value,
          languageCode: (
            this.languageCodeActive
          )
        });
  }
}