<div
  class="row has-labels">
  <nb-form-field
    class="percentage-field"
    [ngClass]="{
      'disabled-field': (
        disabled
      )
    }">
    <label>
      Consignment and Delivery {{ titleSuffix }}
    </label>

    <input
      nbInput
      type="number"
      min="0"
      step="1"
      max="100"
      placeholder="0"
      [disabled]="disabled"
      [(ngModel)]="map[
        'delivery'
      ]"/>

    <div
      class="percentage-sign">
      %
    </div>
  </nb-form-field>

  <nb-form-field
    class="percentage-field"
    [ngClass]="{
      'disabled-field': (
        disabled
      )
    }">
    <label>
      COD {{ titleSuffix }}
    </label>

    <input
      nbInput
      type="number"
      min="0"
      step="1"
      max="100"
      placeholder="0"
      [disabled]="disabled"
      [(ngModel)]="map[
        'cod'
      ]"/>

    <div
      class="percentage-sign">
      %
    </div>
  </nb-form-field>
  </div>

<div
  class="row has-labels">
  <nb-form-field
    class="percentage-field"
    [ngClass]="{
      'disabled-field': (
        disabled
      )
    }">
    <label>
      Sucharge {{ titleSuffix }}
    </label>

    <input
      nbInput
      type="number"
      min="0"
      step="1"
      max="100"
      placeholder="0"
      [disabled]="disabled"
      [(ngModel)]="map[
        'surcharges'
      ]"/>
      
    <div
      class="percentage-sign">
      %
    </div>
  </nb-form-field>

  <nb-form-field
    class="percentage-field"
    [ngClass]="{
      'disabled-field': (
        disabled
      )
    }">
    <label>
      Return {{ titleSuffix }}
    </label>

    <input
      nbInput
      type="number"
      min="0"
      step="1"
      max="100"
      placeholder="0"
      [disabled]="disabled"
      [(ngModel)]="map[
        'returns'
      ]"/>
      
    <div
      class="percentage-sign">
      %
    </div>
  </nb-form-field>
</div>

<div
  class="row has-labels"
  *ngIf="showCurrencyRow">
  <nb-form-field
    class="currency-field"
    [ngClass]="{
      'currency-two-digits': (
        (
          currencyOptionActive
            .symbol
            .length
        ) <=
        2
      ),
      'currency-three-digits': (
        (
          currencyOptionActive
            .symbol
            .length
        ) >
        2
      ),
      'disabled-field': (
        disabled
      )
    }">
    <label>
      Pricelist Currency
    </label>

    <span
      nbPrefix
      class="char-prefix">
      {{(
        currencyOptionActive
          .symbol
          .substr(
            0,
            3
          )
      )}}
    </span>

    <nb-select
      placeholder="Currency"
      [disabled]="disabled"
      [selected]="currencyCode"
      (selectedChange)="setCurrencyOptionActive($event)">
      <nb-select-label>
        Currency: {{ currencyCode }}
      </nb-select-label>
      
      <nb-option
        [value]="currencyOptionDefault.code">
        {{ currencyOptionDefault.code }} ({{ currencyOptionDefault.symbol }})
      </nb-option>

      <nb-option
        [value]="currencyOptionInitial.code">
        {{ currencyOptionInitial.code }} ({{ currencyOptionInitial.symbol }})
      </nb-option>
    </nb-select>
  </nb-form-field>

  <nb-form-field
    [ngClass]="(
      (
        disabled ?
          'disabled-field' :
          (
            (
              (
                currencyOptionDefault
                  .code
              ) !==
              (
                currencyOptionActive
                  .code
              )
            ) ?
              'transparent-field' :
              ''
          )
      )
    )">
    <label>
      Exchange Rate
    </label>

    <nb-icon
      nbPrefix
      icon="trending-up-outline">
    </nb-icon>

    <input
      nbInput
      class="has-suffix"
      type="number"
      min="0.000001"
      step="0.000001"
      autocomplete="off"
      placeholder="Exchange Rate"
      [disabled]="disabled"
      [ngModel]="currencyRate"
      (ngModelChange)="setCurrencyRate($event)"/>

    <span
      nbPrefix
      class="char-suffix">
      {{(
        currencyOptions
          .preferred[0]
          .symbol
      )}}
    </span>
  </nb-form-field>
</div>