import {
  Injectable
} from '@angular/core';

import {
  HttpClient
} from '@angular/common/http';

import {
  Router,
  UrlSerializer
} from '@angular/router';

import {
  map
} from 'rxjs/operators';

import {
  Discount,
  Pricelist,
  Ratios
} from '../models';

import {
  getAuthHeaders,
  getDeepCloneArray,
  getDomainUrlPath,
  getCountryName,
  getCurrencySymbol
} from '../app/app.utils';

import {
  _SERVER_URL,

  COST_LIST_MARKUP_DEFAULT_MAP,
  PRICELIST_DISCOUNT_DEFAULT_MAP,
  PRICELIST_MARKUP_DEFAULT_MAP,

  PRICELIST_DISCOUNT_DEFAULTS
} from '../app/app.const';

@Injectable()
export class PricelistService {
  public authToken: string;

  public cloneDiscountRatioMap: Ratios = {
    ...PRICELIST_DISCOUNT_DEFAULT_MAP
  };

  public discountDefaults: Discount[] = (
    getDeepCloneArray(
      PRICELIST_DISCOUNT_DEFAULTS
    )
  );

  public markupRatioMap: Ratios = {
    ...PRICELIST_MARKUP_DEFAULT_MAP
  };

  public markupCostsRatioMap: Ratios = {
    ...COST_LIST_MARKUP_DEFAULT_MAP
  };
  
  constructor(
    private http: HttpClient,
    private router: Router,
    private urlSerializer: UrlSerializer
  ) {}

  public get(
    _id: string,
    domain?: string
  ): Promise<Pricelist> {
    return (
      <any>(
        this.http
            .get(
              `${_SERVER_URL}api/${(
                getDomainUrlPath(
                  domain
                )
              )}/${_id}`,
              getAuthHeaders(
                this.authToken
              )
            )
            .pipe(
              map(
                (
                  pricelist: Pricelist
                ) => ({
                  ...pricelist,
                  currencySymbol: (
                    getCurrencySymbol(
                      pricelist
                        .currencyCode
                    )
                  ),
                })
              )
            )
            .toPromise()
      )
    );
  }
  
  public getAll(
    context?: string,
    domain?: string,
    term?: string,
    partnerCode?: string,
    countryCode?: string,
    sort?: string,
    order: string = 'asc',
    limit?: number,
    skip?: number
  ): Promise<Pricelist[]> {
    const query = (
      this.urlSerializer
          .serialize(
            this.router
                .createUrlTree(
                  [
                    `${(
                      (
                        context ===
                        'live'
                      ) ?
                        '' :
                        context
                    )}`
                  ],
                  {
                    queryParams: {
                      term,
                      partnerCode,
                      countryCode,
                      sort,
                      order,
                      limit,
                      skip
                    }
                  }
                )
          )
    );

    return (
      <any>(
        this.http
            .get(
              `${_SERVER_URL}api/${(
                  getDomainUrlPath(
                    domain
                  )
                )}${query}`,
              getAuthHeaders(
                this.authToken
              )
            )
            .pipe(
              map(
                (
                  data: {
                    count: number,
                    pricelists: Pricelist[]
                  }
                ) => (
                  data
                    .pricelists
                    .map(
                      pricelist => ({
                        ...pricelist,
                        currencySymbol: (
                          getCurrencySymbol(
                            pricelist
                              .currencyCode
                          )
                        ),
                        label: `${(
                          getCountryName(
                            pricelist
                              .countryCode
                          )
                        )} - ${(
                          pricelist
                            .label
                        )}`
                      })
                    )
                )
              )
            )
            .toPromise()
      )
    );
  }

  public getCountryCodes(
    domain?: string
  ): Promise<string[]> {
    return (
      <any>(
        this.http
            .get(
              `${_SERVER_URL}api/${(
                getDomainUrlPath(
                  domain
                )
              )}/countryCodes`,
              getAuthHeaders(
                this.authToken
              )
            )
            .toPromise()
      )
    );
  }

  public getPartnerCodes(
    domain?: string
  ): Promise<string[]> {
    return (
      <any>(
        this.http
            .get(
              `${_SERVER_URL}api/${(
                getDomainUrlPath(
                  domain
                )
              )}/partnerCodes`,
              getAuthHeaders(
                this.authToken
              )
            )
            .toPromise()
      )
    );
  }

  public upsert(
    pricelists: Pricelist[],
    domain?: string
  ): Promise<Pricelist[]> {
    return (
      <any>(
        this.http
            .post(
              `${_SERVER_URL}api/${(
                getDomainUrlPath(
                  domain
                )
              )}`,
              pricelists,
              getAuthHeaders(
                this.authToken
              )
            )
            .toPromise()
      )
    );
  }

  public archive(
    _id: string,
    domain?: string
  ): Promise<boolean[]> {
    return (
      <any>(
        this.http
            .post(
              `${_SERVER_URL}api/${(
                getDomainUrlPath(
                  domain
                )
              )}/archive`,
              [
                _id
              ],
              getAuthHeaders(
                this.authToken
              )
            )
            .toPromise()
      )
    );
  }

  public publish(
    _id: string,
    domain?: string
  ): Promise<boolean[]> {
    return (
      <any>(
        this.http
            .post(
              `${_SERVER_URL}api/${(
                getDomainUrlPath(
                  domain
                )
              )}/publish`,
              [
                _id
              ],
              getAuthHeaders(
                this.authToken
              )
            )
            .toPromise()
      )
    );
  }

  public republish(
    _id: string,
    domain?: string
  ): Promise<boolean[]> {
    return (
      <any>(
        this.http
            .post(
              `${_SERVER_URL}api/${(
                getDomainUrlPath(
                  domain
                )
              )}/republish`,
              [
                _id
              ],
              getAuthHeaders(
                this.authToken
              )
            )
            .toPromise()
      )
    );
  }

  public restore(
    _id: string,
    domain?: string
  ): Promise<boolean[]> {
    return (
      <any>(
        this.http
            .post(
              `${_SERVER_URL}api/${(
                getDomainUrlPath(
                  domain
                )
              )}/restore`,
              [
                _id
              ],
              getAuthHeaders(
                this.authToken
              )
            )
            .toPromise()
      )
    );
  }

  public remove(
    _id: string,
    domain?: string
  ): Promise<boolean[]> {
    return (
      <any>(
        this.http
            .delete(
              `${_SERVER_URL}api/${(
                getDomainUrlPath(
                  domain
                )
              )}/${_id}`,
              getAuthHeaders(
                this.authToken
              )
            )
            .toPromise()
      )
    );
  }

  public getCloneRatioMapKey(
    isCostLists: boolean = false
  ) {
    return (
      isCostLists ?
        'markupCostsRatioMap' :
        'cloneDiscountRatioMap'
    );
  }

  public resetCloneRatioMap(
    isCostLists: boolean = false
  ) {
    isCostLists ?
      this.resetMarkupCostsRatioMap() :
      this.resetCloneDiscountRatioMap()
  }

  public resetDiscountDefaults() {
    this.discountDefaults = (
      getDeepCloneArray(
        PRICELIST_DISCOUNT_DEFAULTS
      )
    );
  }

  public resetMarkupRatioMap() {
    this.markupRatioMap = {
      ...PRICELIST_MARKUP_DEFAULT_MAP
    };
  }

  private resetCloneDiscountRatioMap() {
    this.cloneDiscountRatioMap = {
      ...PRICELIST_DISCOUNT_DEFAULT_MAP
    };
  }

  private resetMarkupCostsRatioMap() {
    this.markupCostsRatioMap = {
      ...COST_LIST_MARKUP_DEFAULT_MAP
    }
  }
}