import {
  RateGroupWms
} from './rate-group.wms.model';

import {
  Service
} from './service.model';

import {
  Translation
} from './translation.model';

export class PricelistWms {
  _id?: string;
  context?: string;
  countryCode: string;
  currencyCode: string;
  currencySymbol: string;
  isDraft: boolean = true;
  label: string;
  labelHuman?: string;
  ownerEmail: string;
  partnerCode?: string;
  rateCurrency?: string;
  rateGroups: RateGroupWms[] = [];
  services: Service[]|string[];
  terms?: string;
  translations: Translation[] = [];
  validTimestampStart?: Date;
  validTimestampEnd?: Date;
  vatRate: number;
  warehouse: string;

  constructor(
    countryCode: string,
    currencyCode: string,
    currencySymbol: string,
    ownerEmail: string
  ) {
    this.countryCode = countryCode;
    this.currencyCode = currencyCode;
    this.currencySymbol = currencySymbol;
    this.ownerEmail = ownerEmail;
  }
};