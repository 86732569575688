import {
  Injectable
} from '@angular/core';

import {
  HttpClient
} from '@angular/common/http';

import {
  Service
} from '../models';

import {
  getAuthHeaders
} from '../app/app.utils';

import {
  _SERVER_URL
} from '../app/app.const';

@Injectable()
export class ServiceService {
  public authToken: string;
  
  constructor(
    private http: HttpClient
  ) {}

  public get(
    _id: string
  ): Promise<Service> {
    return (
      <any>(
        this.http
            .get(
              `${_SERVER_URL}api/services/${_id}`,
              getAuthHeaders(
                this.authToken
              )
            )
            .toPromise()
      )
    );
  }
  
  public getAll(): (
    Promise<Service[]>
  ) {
    return (
      <any>(
        this.http
            .get(
              `${_SERVER_URL}api/services`,
              getAuthHeaders(
                this.authToken
              )
            )
            .toPromise()
      )
    );
  }
}