import * as numeral from 'numeral';
import * as moment from 'moment-timezone';
import * as countryQuery from 'countries-code';
import * as currencyCodes from 'currency-codes';
import * as currencySymbol from 'currency-symbol';
import * as naturalSortByKey from 'natural-sort-by-key';
import * as parseDecimalNumber from 'parse-decimal-number';

import {
  AllHtmlEntities
} from 'html-entities';

import {
  getCountries,
  getLanguages
} from 'country-language';

import {
  isEuMember
} from 'is-eu-member';

import {
  production
} from '../environments/environment';

import {
  Pricelist,
  PricelistWms,
  Rate,
  RateCod,
  ServiceExtra,
  Surcharge,
  Ratios
} from '../models';

import {
  COUNTRY_PARTNER_CODE_OVERRIDE_MAP,
  COUNTRY_PARTNER_CODE_OVERRIDE_MAP_WMS,
  COUNTRY_REGION_LABEL_MAP,
  COUNTRY_ZONE_MAP,
  DEFAULT_CURRENCY_CODE,
  DEFAULT_LOCALES,
  PREFERRED_COUNTRY_CODES,
  RATE_RETURN_TYPES,
  RATE_UNIT_FRANKING_TYPES,
  VAT_RATES
} from './app.const';

const entitiesHtml = (
  new AllHtmlEntities()
);

const countryCodes = (
  getCountries()
    .map(
      country => (
        country
          .code_2
          .toLowerCase()
      )
    )
);

export const cloneDeep = value => (
  JSON.parse(
    JSON.stringify(
      value
    )
  )
);

export const decodeHtml = string => (
  (
    string &&
    (
      typeof string ===
      'string'
    )
  ) ?
    (
      entitiesHtml.decode(
        string
      )
    ) :
    string
);

export const getAuthHeaders = token => ({
  headers: {
    Authorization: `Bearer ${token}`
  }
});

export const getContextPath = context => (
  (
    !context ||
    (
      context ===
      'live'
    )
  ) ?
    'active' :
    context
);

export const getCountryCodes = () => (
  countryCodes
);

export const getCountryCodeThreeDigits = countryCode => {
  if (
    [
      'cy',
      'el',
      'gr'
    ]
    .includes(
      countryCode
        .toLowerCase()
    )
  ) {
    return 'grc';
  }

  if (
    (
      countryCode
        .toLowerCase()
    ) ===
    'en'
  ) {
    return 'gbr';
  }

  if (
    (
      countryCode
        .toLowerCase()
    ) ===
    'tr'
  ) {
    return 'tur';
  }
  
  const country = (
    getCountries()
      .find(
        country => (
          (
            country
              .code_2
              .toLowerCase()
          ) ===
          (
            countryCode
              .toLowerCase()
          )
        )
      )
  );

  return (
    country ?
      (
        country
          .code_3
      ) :
      undefined
  );
};

export const getCountryCodesPreferredThreeDigits = () => (
  PREFERRED_COUNTRY_CODES
    .map(
      countryCode => (
        getCountryCodeThreeDigits(
          countryCode
        )
      )
    )
    .filter(
      countrCodeThreeDigits => (
        !!countrCodeThreeDigits
      )
    )
    .map(
      countrCodeThreeDigits => (
        countrCodeThreeDigits
          .toLowerCase()
      )
    )
);

export const getCountryCodesZone = () => (
  Object
    .keys(
      COUNTRY_ZONE_MAP
    )
);

export const getCountryPartnerCodeOverride = (
  countryCode: string,
  isWms: boolean = false
) => {
  const overrideMap = (
    isWms ?
      COUNTRY_PARTNER_CODE_OVERRIDE_MAP_WMS :
      COUNTRY_PARTNER_CODE_OVERRIDE_MAP
  );
    
  return (
    (
      overrideMap[
        countryCode
      ]
    ) ?
      (
        overrideMap[
          countryCode
        ]
      ) :
      null
  );
};

export const getCountryOptions = () => ({
  zones: (
    getCountryCodesZone()
      .map(
        code => ({
          code,
          name: (
            COUNTRY_ZONE_MAP[
              code
            ]
          )
        })
      )
  ),
  preferred: (
    countryCodes
      .filter(
        code => (
          PREFERRED_COUNTRY_CODES
            .includes(
              code
            )
        )
      )
      .map(
        code => ({
          code,
          name: (
            getCountryName(
              code
            )
          )
        })
      )
  ),
  other: (
    countryCodes
      .filter(
        code => (
          !(
            PREFERRED_COUNTRY_CODES
              .includes(
                code
              )
          )
        )
      )
      .map(
        code => ({
          code,
          name: (
            getCountryName(
              code
            )
          )
        })
      )
  )
});

export const getCountryName = (
  code: string
): string => (
  (
    code ===
    'cz'
  ) ?
    'Czech Republic' :
    (
      countryQuery
        .getCountry(
          code
        )
    )
);

export const getCountryNameRaw = (
  code: string
): string => (
  countryQuery
    .getCountry(
      code
    )
);

export const getCurrencies = () => {
  const currencyCodesUsed = [];

  return (
    countryCodes
      .map(
        countryCode => {
          const countryName = (
            getCountryNameRaw(
              countryCode
            )
          );

          const countryCurrencies = (
            currencyCodes
              .country(
                countryName
                  .toLowerCase()
              )
          );

          if (
            (
              (
                countryCurrencies
                  .length
              ) ===
              0
            ) ||
            (
              currencyCodesUsed
                .includes(
                  countryCurrencies[0]
                    .code
                )
            )
          ) {
            return null;
          }
          
          currencyCodesUsed
            .push(
              countryCurrencies[0]
                .code
            );

          return {
            code: (
              countryCurrencies[0]
                .code
            ),
            countryCode: countryCode,
            symbol: getCurrencySymbol(
              countryCurrencies[0]
                .code
            )
          };
        }
      )
      .filter(
        currency => (
          !!currency
        )
      )
  )
};

export const getCurrencyOptions = () => ({
  zones: (
    getCountryCodesZone()
      .map(
        () => (
          getCurrency(
            DEFAULT_CURRENCY_CODE
          )
        )
      )
  ),
  preferred: (
    sortCurrencies(
      getCurrencies()
        .filter(
          currency => (
            (
              PREFERRED_COUNTRY_CODES
                .includes(
                  currency
                    .countryCode
                )
            ) ||
            (
              (
                currency
                  .code
              ) ===
              DEFAULT_CURRENCY_CODE
            )
          )
        )
    )
  ),
  other: (
    sortCurrencies(
      getCurrencies()
        .filter(
          currency => (
            (
              !(
                PREFERRED_COUNTRY_CODES
                  .includes(
                    currency
                      .countryCode
                  )
              )
            ) &&
            (
              (
                currency
                  .code
              ) !==
              DEFAULT_CURRENCY_CODE
            )
          )
        )
    )
  )
});

export const getLanguageOptions = () => {
  const countryCodesPreferredThreeDigits = (
    getCountryCodesPreferredThreeDigits()
  );

  return {
    preferred: (
      getLanguages()
        .filter(
          language => {
            if (
              !(
                Array
                  .isArray(
                    language
                      .countries
                  )
              )
            ) {
              return false;
            }
            
            if (
              (
                language
                  .iso639_1
              ) ===
              'cy'
            ) {
              return (
                language
                  .countries
                  .map(
                    country => (
                      country
                        .toLowerCase()
                    )
                  )
                  .includes(
                    'grc'
                  )
              );
            }

            if (
              language
                .countries
                .map(
                  country => (
                    country
                      .toLowerCase()
                  )
                )
                .includes(
                  'grc'
                )
            ) {
              return (
                (
                  language
                    .iso639_1
                ) ===
                'el'
              );
            }

            return (
              !!(
                language
                  .countries
                  .find(
                    country => (
                      countryCodesPreferredThreeDigits
                        .includes(
                          country
                            .toLowerCase()
                        )
                    )
                  )
              )
            );
          }
        )
        .map(
          language => ({
            code: (
              language
                .iso639_1
            ),
            name: (
              language
                .name[0]
            ),
            urlIcon: (
              `https://trackit.bizcourier.eu/language-icon/${(
                language
                  .iso639_1
              )}`
            )
          })
        )
    ),
    other: (
      getLanguages()
        .filter(
          language => (
            (
              Array
                .isArray(
                  language
                    .countries
                )
            ) &&
            (
              !(
                language
                  .countries
                  .find(
                    country => (
                      countryCodesPreferredThreeDigits
                        .includes(
                          country
                          .toLowerCase()
                        )
                    )
                  )
              )
            )
          )
        )
        .map(
          language => ({
            code: (
              language
                .iso639_1
            ),
            name: (
              language
                .name[0]
            ),
            urlIcon: (
              `https://trackit.bizcourier.eu/language-icon/${(
                language
                  .iso639_1
              )}`
            )
          })
        )
    )
  }
};

export const getDeepCloneArray = value => (
  (
    Array
      .isArray(
        value
      )
  ) ?
    (
      JSON.parse(
        JSON.stringify(
          value
        )
      )
    ) :
    value
);

export const getDomainUrlPath = domain => (
  (
    (
      isStringSafe(
        domain
      )
    ) &&
    (
      domain
        .startsWith(
          'costs'
        )
    )
  ) ?
    'cost-lists' :
    'pricelists'
);

export const getExcelColumns = (
  row: string,
  valueType: string = 'string',
  removeStems?: string[]
) => (
  row
    .split(
      String
        .fromCharCode(
          9
        )
    )
    .map(
      value => {
        let valueClean = (
          value
            .trim()
        );

        if (
          Array
            .isArray(
              removeStems
            )
        ) {
          removeStems
            .forEach(
              removeStem => {
                valueClean = (
                  valueClean
                    .replace(
                      new RegExp(
                        removeStem,
                        'i'
                      ),
                      ''
                    )
                    .trim()
                );
              }
            )
        }

        return (
          (
            [
              'float',
              'number'
            ].includes(
              valueType
            )
          ) ?
            (
              parseLocaleNumber(
                valueClean
              )
            ) :
            valueClean
        );
      }
    )
);

export const getExcelRows = plaintext => (
  plaintext
    .replace(
      /"((?:[^"]*(?:\r\n|\n\r|\n|\r))+[^"]+)"/mg,
      (match, p1) => (
        p1.replace(
            /""/g,
            '"'
          )
          .replace(
            /\r\n|\n\r|\n|\r/g,
            ' '
          )
      )
    )
    .split(
      /\r\n|\n\r|\n|\r/g
    )
);

export const getModelOption = (
  options,
  code
) => {
  if (
    !(
      (
        typeof options ===
        'object'
      ) &&
      (
        Array
          .isArray(
            options
              .zones
          )
      ) &&
      (
        Array
          .isArray(
            options
              .preferred
          )
      ) &&
      (
        Array
          .isArray(
            options
              .other
          )
      )
    )
  ) {
    return null;
  }

  return (
    [
      ...(
        options
          .zones
      ),
      ...(
        options
          .preferred
      ),
      ...(
        options
          .other
      )
    ].filter(
      option => (
        (
          option
            .code
        ) ===
        code
      )
    )[0]
  );
};

export const getRateReturnTypes = () => (
  RATE_RETURN_TYPES
);

export const getRateUnitFrankingTypes = () => (
  RATE_UNIT_FRANKING_TYPES
);

export const getUtcDateTimestamp = (
  timestampString: string,
  isEndOfDay: boolean = false
) => {
  const timestamp = (
    moment(
      timestampString
    )
  );

  if (
    !(
      timestamp
        .isValid
    )
  ) {
    return null;
  }

  return `${(
    timestamp
      .format(
        'YYYY'
      )
  )}-${(
    timestamp
      .format(
        'MM'
      )
  )}-${(
    timestamp
      .format(
        'DD'
      )
  )}T23:59:59Z`;
}

export const getVatRates = () => (
  VAT_RATES
);

export const getCurrency = code => (
  getCurrencies()
    .find(
      currency => (
        (
          currency
            .code
        ) ===
        code
      )
    )
);

export const getCurrencySymbol = code => (
  (
    currencySymbol
      .symbol(
        code
      )
  ) ?
    (
      decodeHtml(
        currencySymbol
          .symbol(
            code
          )
      )
    ) :
    code
);

export const getRegionCodeOptions = countryCode => (
  (
    COUNTRY_REGION_LABEL_MAP[
      countryCode
    ]
  ) ?
    COUNTRY_REGION_LABEL_MAP[
      countryCode
    ] :
    []
);

export const isIntegerPositiveString = string => (
  (
    isStringSafe(
      string
    )
  ) &&
  (
    (
      string >>>
      0
    ) ===
    parseFloat(
      string
    )
  )
);

export const isStringSafe = value => (
  (
    value !==
    null
  ) &&
  (
    typeof value ===
    'string'
  )
);

export const isStringSafeNotEmpty = value => (
  (
    isStringSafe(
      value
    )
  ) &&
  (
    (
      value
        .length
    ) >
    0
  )
);

export const hasRegionCodeOptions = countryCode => (
  Array
    .isArray(
      COUNTRY_REGION_LABEL_MAP[
        countryCode
      ]
    )
);

export const initDefaultLocales = () => {
  DEFAULT_LOCALES
    .forEach(
      locale => (
        numeral
          .register(
            'locale',
            locale.name,
            {
              delimiters: (
                locale.delimiters
              )
            }
      )
    )
  )
};

export const isArrayEmpty = value => (
  (
    Array
      .isArray(
        value
      )
  ) &&
  (
    value.length ===
    0
  )
);

export const isArrayNotEmpty = value => (
  (
    Array
      .isArray(
        value
      )
  ) &&
  (
    value.length >
    0
  )
);

export const isEU = code => (
  (
    isStringSafeNotEmpty(
      code
    )
  ) ?
    (
      (
        [
          'gr',
          'mc'
        ]
        .includes(
          code
        )
      ) ||
      (
        isEuMember(
          code
        )
      )
    ) :
    false
);

export const isFrame = window => (
  (
    window !==
    (
      window
        .parent
    )
  ) &&
  !(
    window
      .opener
  )
);

export const isIE = () => (
  (
    window
      .navigator
      .userAgent
      .includes(
        'MSIE '
      )
  ) || (
    window
      .navigator
      .userAgent
      .includes(
        'Trident/'
      )
  )
);

export const isProd = () => (
  (
    production ===
    true
  )
);

export const naturalSort = (
  array: any[],
  key: string
): any[] => (
  array
    .sort(
      (
        itemA,
        itemB
      ) => (
        (
          (
            typeof itemA[key] ===
            'string'
          ) &&
          (
            typeof itemB[key] ===
            'string'
          ) &&
          (
            itemA[key]
              .startsWith(
                itemB[key]
                  .split(
                    ' - '
                  )[0]
              )
          ) &&
          (
            itemA[key]
              .includes(
                'Official'
              )
          )
        ) ?
          -1 :
          (
            (
              (
                typeof itemA[key] ===
                'string'
              ) &&
              (
                typeof itemB[key] ===
                'string'
              ) &&
              (
                itemA[key]
                  .startsWith(
                    itemB[key]
                      .split(
                        ' - '
                      )[0]
                  )
              ) &&
              (
                itemB[key]
                  .includes(
                    'Official'
                  )
              )
            ) ?
              1 :
              (
                naturalSortByKey(
                  key
                )(
                  itemA,
                  itemB
                )
              )
          )
      )
    )
);

export const objectHasValue = obj => (
  obj &&
  (
    typeof obj ===
    'object'
  ) &&
  (
    (
      Object
        .keys(
          obj
        )
        .map(
          key => (
            obj[
              key
            ]
          )
        )
        .filter(
          value => (
            (
              value !==
              null
            ) &&
            (
              typeof value !==
              'undefined'
            )
          )
        )
        .length
    ) >
    0
  )
);

export const parseLocaleNumber = value => {
  let number = (
    parseDecimalNumber(
      value
    )
  );
  
  if (!number) {
    number = (
      parseDecimalNumber(
        value,
        (
          numeral
            .localeData(
              'gr'
            )
            .delimiters
        )
      )
    );
  }

  if (!number) {
    number = (
      parseDecimalNumber(
        value,
        (
          numeral
            .localeData(
              'intl'
            )
            .delimiters
        )
      )
    );
  }

  return number;
};

export const setPricelistAdjustment = (
  pricelist: Pricelist,
  ratioMap: Ratios,
  applyAdjustment: Function
) => {
  if (
    (
      ratioMap[
        'delivery'
      ] !==
      null
    ) &&
    (
      typeof ratioMap[
        'delivery'
      ] ===
      'number'
    )
  ) {
    pricelist
      .rates = (
        pricelist
          .rates
          .map(
            (
              rate: Rate
            ) => ({
              ...rate,
              fee: (
                applyAdjustment(
                  (
                    rate
                      .fee
                  ),
                  ratioMap,
                  'delivery'
                )
              )
            })
          )
      );

    if (
      pricelist
        .rateExtra
    ) {
      pricelist
        .rateExtra = {
          ...(
            pricelist
                .rateExtra
          ),
          fee: (
            applyAdjustment(
              (
                pricelist
                  .rateExtra
                  .fee
              ),
              ratioMap,
              'delivery'
            )
          )
        };
    }

    if (
      pricelist
        .rateUnit
    ) {
      pricelist
        .rateUnit = {
          ...(
            pricelist
                .rateUnit
          ),
          fee: (
            applyAdjustment(
              (
                pricelist
                  .rateUnit
                  .fee
              ),
              ratioMap,
              'delivery'
            )
          )
        };
    }
  }

  if (
    (
      ratioMap[
        'cod'
      ] !==
      null
    ) &&
    (
      typeof ratioMap[
        'cod'
      ] ===
      'number'
    )
  ) {
    pricelist
      .ratesCod = (
        pricelist
          .ratesCod
          .map(
            (
              rateCod: RateCod
            ) => ({
              ...rateCod,
              fee: (
                applyAdjustment(
                  (
                    rateCod
                      .fee
                  ),
                  ratioMap,
                  'cod'
                )
              ),
              feeMin: (
                applyAdjustment(
                  (
                    rateCod
                      .feeMin
                  ),
                  ratioMap,
                  'cod'
                )
              ),
              priceRatio: (
                applyAdjustment(
                  (
                    rateCod
                      .priceRatio
                  ),
                  ratioMap,
                  'cod',
                  true
                )
              ),
              mPosPriceRatio: (
                applyAdjustment(
                  (
                    rateCod
                      .mPosPriceRatio
                  ),
                  ratioMap,
                  'cod',
                  true
                )
              )
            })
          )
      );
  }

  if (
    (
      ratioMap[
        'surcharges'
      ] !==
      null
    ) &&
    (
      typeof ratioMap[
        'surcharges'
      ] ===
      'number'
    )
  ) {
    pricelist
      .servicesExtra = (
        pricelist
          .servicesExtra
          .map(
            (
              serviceExtra: ServiceExtra
            ) => ({
              ...serviceExtra,
              feeRatio: (
                applyAdjustment(
                  (
                    serviceExtra
                      .fee
                  ),
                  ratioMap,
                  'servicesExtra',
                  true
                )
              )
            })
          )
      );

    pricelist
      .surcharges = (
        pricelist
          .surcharges
          .map(
            (
              surcharge: Surcharge
            ) => ({
              ...surcharge,
              feeRatio: (
                applyAdjustment(
                  (
                    surcharge
                      .feeRatio
                  ),
                  ratioMap,
                  'surcharges',
                  true
                )
              )
            })
          )
      );
  }

  if (
    (
      ratioMap[
        'returns'
      ] !==
      null
    ) &&
    (
      typeof ratioMap[
        'returns'
      ] ===
      'number'
    )
  ) {
    pricelist
      .ratesReturn = (
        pricelist
          .ratesReturn
          .map(
            (
              rateReturn: Rate
            ) => ({
                ...rateReturn,
                fee: (
                  applyAdjustment(
                    (
                      rateReturn
                        .fee
                    ),
                    ratioMap,
                    'returns'
                  )
                )
              })
          )
      );

    if (
      pricelist
        .rateReturnFee
    ) {
      pricelist
        .rateReturnFee = (
          applyAdjustment(
            (
              pricelist
                .rateReturnFee
            ),
            ratioMap,
            'returns'
          )
        );
    }
  }
};

export const setPricelistAdjustmentWms = (
  pricelist: PricelistWms,
  ratioMap: Ratios,
  applyAdjustment: Function
) => {
};

export const sortCurrencies = (
  currencies
) => (
  currencies
    .sort(
      (
        currencyA,
        currencyB
      ) => {
        if (
          (
            currencyA
              .code
          ) ===
          DEFAULT_CURRENCY_CODE
        ) {
          return -1;
        }

        if (
          (
            currencyB
              .code
          ) ===
          DEFAULT_CURRENCY_CODE
        ) {
          return 1;
        }

        return (
          currencyA
            .code
            .localeCompare(
              currencyB
                .code
            )
        );
      }
    )
);