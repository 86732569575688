import {
  Component,
  Input
} from '@angular/core';

@Component({
  selector: 'biz-header',
  templateUrl: './header.component.html',
  styleUrls: [
    './header.component.scss'
  ]
})
export class HeaderComponent {
  @Input(
    'label'
  ) label: string
}