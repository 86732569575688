import {
  Component,
  Inject,
  OnInit,
  OnDestroy
} from '@angular/core';

import {
  ActivatedRoute,
  Router
} from '@angular/router';

import {
  NbDialogService
} from '@nebular/theme';

import {
  Subscription
} from 'rxjs';

import {
  first
} from 'rxjs/operators';

import {
  MsalService
} from '@azure/msal-angular';

import {
  Country,
  Pricelist,
  PricelistWms,
  Ratios
} from '../../models';

import {
  GraphService,
  PricelistService,
  PricelistWmsService
} from '../../providers';

import {
  DialogComponent
} from '../../components';

import {
  getDomainUrlPath,
  getCountryName,
  getCountryOptions,
  getModelOption,
  isIntegerPositiveString,
  isStringSafe,
  isStringSafeNotEmpty,
  setPricelistAdjustment
} from '../../app/app.utils';

import {
  _SERVER_URL,
  DEFAULT_CURRENCY_CODE,
  DEFAULT_LANGUAGE_CODE,
  PAGE_SCROLL_MARGIN_TOP,
  PAGE_SCROLL_MARGIN_TOP_FIRST,
  PRICELIST_OFFICIAL_DEFAULT,
  SEARCH_MIN_CHARACTERS
} from '../../app/app.const';

@Component({
  selector: 'biz-page-home',
  templateUrl: './home.component.html',
  styleUrls: [
    './home.component.scss'
  ],
  host: {
    class: 'biz-page'
  }
})
export class HomeComponent implements OnInit, OnDestroy {
  public countryOptions: {
    null: Country,
    zones: Country[],
    preferred: Country[],
    other: Country[]
  };
  public countryOptionActive: Country;

  public languageCodeDefault = (
    DEFAULT_LANGUAGE_CODE
  );

  public pricelists: Pricelist|PricelistWms[] = [];
  public pricelistsLoading: boolean = false;

  public page: number = 0;
  public pageContext: string = 'live';
  public pageDomain: string = null;
  public pageLoading: boolean = false;
  public pageNext: number = null;
  public pagePrevious: number = null;
  public pagePartnerCode: string = null;
  public pageScrollThreshold: number = 20;
  public partnerCodes: string[] = [];

  public allUserNameMap: object = {};
  public dialogActionLoading: boolean = false;

  public term: string;
  private termTimeout: any;

  private authEmail: string;
  private pageLimit: number = 10;
  private pricelistServiceDomain;

  private contextSub: Subscription;
  private queryParamSub: Subscription;

  private dialogVisible: boolean = false;
  
  public constructor(
    @Inject(
      'Window'
    ) private window: Window,
    private route: ActivatedRoute,
    private router: Router,
    private dialogService: NbDialogService,
    private graphService: GraphService,
    private msalService: MsalService,
    private pricelistService: PricelistService,
    private pricelistWmsService: PricelistWmsService
  ) {}

  public async ngOnInit() {
    this.pricelistsLoading = true;
    
    this.setAuthEmail();
    this.setAllUserNameMap();

    await (
      this.setPageContext()
    );
    
    this.setPageDomain();
    
    await (
      this.initQueryParams()
    );
    
    this.initSubs();
  }

  public ngOnDestroy() {
    this.destroySubs();
  }

  public get countryOptionLabel(): string {
    return (
      (
        (
          this.countryOptions !==
          null
        ) &&
        (
          typeof this.countryOptions ===
          'object'
        ) &&
        (
          Array
            .isArray(
              this.countryOptions
                  .zones
            )
        ) &&
        (
          this.countryOptionActive
        ) &&
        (
          this.countryOptions
              .zones
              .map(
                zone => (
                  zone
                    .code
                )
              )
              .includes(
                this.countryOptionActive
                    .code
              )
        )
      ) ?
        'Zone' :
        'Country'
    );
  }

  public get hasSearch() {
    return (
      (
        isStringSafeNotEmpty(
          this.term
        )
      ) ||
      (
        this.pagePartnerCode !==
        'null'
      ) ||
      (
        (
          this.countryOptionActive
              .code
        ) !==
        'null'
      )
    );
  }
  
  public get hasTerm() {
    return (
      isStringSafeNotEmpty(
        this.term
      )
    );
  }

  public get isLive() {
    return (
      [
        '',
        'live'
      ].includes(
        this.pageContext
      )
    );
  }

  public get isArchived() {
    return (
      this.pageContext ===
      'archived'
    );
  }

  public get isRemoved() {
    return (
      this.pageContext ===
      'removed'
    );
  }

  public get isCostLists() {
    return (
      (
        isStringSafe(
          this.pageDomain
        )
      ) &&
      (
        this.pageDomain
            .startsWith(
              'costs'
            )
      )
    );
  }

  public get isWms() {
    return (
      (
        isStringSafe(
          this.pageDomain
        )
      ) &&
      (
        this.pageDomain
            .endsWith(
              'wms'
            )
      )
    );
  }

  public get pageContextVerb() {
    return (
      (
        [
          'archived',
          'removed'
        ].includes(
          this.pageContext
        )
      ) ?
        this.pageContext :
        'created'
    )
  }

  public get pageDomainModelName() {
    return (
      this.isCostLists ?
        'Cost List' :
        'Pricelist'
    );
  }

  public get searchActiveVerb() {
    return (
      this.hasSearch ?
        ' found' :
        ''
    );
  }

  public get searchPhrase() {
    return (
      this.hasSearch ?
        ' with the specified filter criteria' :
        ''
    );
  }

  public async getPage(
    page: number
  ) {
    const currentPage = (
      this.page
    );

    this.page = page;
    this.pageLoading = true;

    const pricelists = (
      await (
        this.pricelistServiceDomain
            .getAll(
              this.pageContext,
              this.pageDomain,
              this.term,
              (
                (
                  this.pagePartnerCode !==
                  'null'
                ) ?
                  this.pagePartnerCode :
                  undefined
              ),
              (
                (
                  (
                    this.countryOptionActive
                        .code
                  ) !==
                  'null'
                ) ?
                  (
                    this.countryOptionActive
                        .code
                  ) :
                  undefined
              ),
              'labelHuman',
              'asc',
              this.pageLimit,
              (
                this.page *
                this.pageLimit
              )
            )
      )
    );

    if (!
      (
        (
          Array
            .isArray(
              pricelists
            )
        ) &&
        (
          (
            pricelists
              .length
          ) >
          0
        )
      )
    ) {
      this.page = currentPage;
      this.pageLoading = false;
      this.pageNext = null;

      this.pricelists = [];
      this.pricelistsLoading = false;
      
      return false;
    }

    this.pricelists = pricelists;

    this.window
        .scrollTo({
          top: (
            this.pageScrollThreshold +
            (
              (
                this.page ===
                0
              ) ?
                PAGE_SCROLL_MARGIN_TOP_FIRST :
                PAGE_SCROLL_MARGIN_TOP
            )
          )
        })

    this.pageNext = (
      (
        (
          this.pricelists
              .length
        ) ===
        this.pageLimit
      ) ?
        (
          this.page +
          1
        ) :
        null
    );

    this.pagePrevious = (
      (
        this.page !==
        0
      ) ?
        (
          this.page -
          1
        ) :
        null
    );

    this.pageLoading = false;
    this.pricelistsLoading = false;
  }

  public getPricelistLanguageCodes(
    pricelist: Pricelist|PricelistWms
  ) {
    return (
      [
        ...(
          new Set(
            pricelist
              .translations
              .map(
                translation => (
                  translation
                    .languageCode
                )
              )
          )
        )
      ]
    );
  }

  public onEdit(
    pricelist: Pricelist|PricelistWms
  ) {
    this.router
        .navigate(
          [
            `${(
              this.isWms ?
                '/wms' :
                ''
            )}/${(
              getDomainUrlPath(
                this.pageDomain
              )
            )}/${pricelist._id}`
          ],
          {
            queryParamsHandling: 'preserve'
          }
        );
  }

  public onNew() {
    this.router
        .navigate(
          [
            `${(
              this.isWms ?
                '/wms' :
                ''
            )}/${(
              getDomainUrlPath(
                this.pageDomain
              )
            )}/new`
          ],
          {
            queryParamsHandling: 'preserve'
          }
        );
  }

  public onOpen(
    pricelist: Pricelist|PricelistWms,
    languageCode?: string
  ) {
    this.window
        .open(
          `${_SERVER_URL}docs/${(
              getDomainUrlPath(
                this.pageDomain
              )
            )}/${(
              pricelist
                ._id
            )}${(
              (
                languageCode &&
                (
                  languageCode !==
                  this.languageCodeDefault
                )
              ) ?
                `?languageCode=${languageCode}` :
                ``
            )}`
        );
  }

  public onView(
    pricelist: Pricelist|PricelistWms
  ) {
    this.router
        .navigate(
          [
            `${(
              this.isWms ?
                '/wms' :
                ''
              )}/${(
                getDomainUrlPath(
                  this.pageDomain
                )
              )}/${pricelist._id}`
          ],
          {
            queryParamsHandling: 'preserve'
          }
        );
  }

  public onClearTerm() {
    this.term = (
      undefined
    );

    this.router
        .navigate(
          [],
          {
            relativeTo: (
              this.route
            ),
            queryParams: {
              term: undefined,
              page: undefined
            },
            queryParamsHandling: (
              'merge'
            )
          }
        );
  }

  public onClone(
    pricelist: Pricelist|PricelistWms
  ) {
    this.onDialogAction(
      `clone${(
        this.isCostLists ?
          '-costs' :
          ''
      )}`,
      pricelist,
      async data => {
        this.pricelistServiceDomain[(
          this.pricelistServiceDomain
              .getCloneRatioMapKey(
                this.isCostLists
              )
        )][(
          pricelist
            ._id
        )] = (
          (
            this.isCostLists ?
              (
                data
                  .markupCostsRatioMap
              ) :
              (
                data
                  .discountRatioMap
              )
          )
        );

        this.router
            .navigate(
              [
                `${(
                  this.isWms ?
                    '/wms' :
                    ''
                  )}/${(
                    getDomainUrlPath(
                      this.pageDomain
                      )
                  )}/${(
                      pricelist
                        ._id
                  )}/clone`
              ],
              {
                queryParamsHandling: (
                  'preserve'
                )
              }
            )
      }
    );
  }

  public onGenerate(
    pricelist: any
  ) {
    const applyDiscount = (
      fee: number,
      ratioMap: Ratios,
      key: string,
      isRatio: boolean = false
    ) => (
      parseFloat(
        (
          fee *
          (
            100 -
            (
              ratioMap[
                key
              ]
            )
          ) /
          100
        ).toFixed(
          isRatio ?
            3 :
            2
        )
      )
    );

    this.onDialogAction(
      'generate',
      pricelist,
      async data => {
        const pricelistsDiscount = (
          data
            .discountDefaults
            .map(
              discount => {
                const {
                  labelSuffix,
                  orderMin,
                  orderMax,
                  ratios
                } = discount;

                const countryName = (
                  getCountryName(
                    pricelist
                      .countryCode
                  )
                );

                const pricelistDiscount = {
                  ...pricelist,
                  isDraft: true,
                  label: (
                    (
                      pricelist
                        .label
                        .endsWith(
                          `- ${(
                            PRICELIST_OFFICIAL_DEFAULT
                              .labelSuffix
                          )}`
                        )
                    ) ?
                      (
                        pricelist
                          .label
                          .replace(
                            `${(
                              countryName
                            )} - `,
                            ``
                          )
                          .replace(
                            `- ${(
                              PRICELIST_OFFICIAL_DEFAULT
                                .labelSuffix
                            )}`,
                            `- ${(
                              labelSuffix
                            )}`
                          )
                          .trim()
                      ) :
                      `${(
                        pricelist
                          .label
                          .replace(
                            `${(
                              countryName
                            )} - `,
                            ``
                          )
                          .trim()
                      )} - ${(
                        labelSuffix
                      )}`
                  ),
                  orderMin,
                  orderMax,
                  ownerEmail: this.authEmail
                };

                delete (
                  pricelistDiscount
                    ._id
                );
          
                delete (
                  pricelistDiscount
                    .currencySymbol
                );
          
                delete (
                  pricelistDiscount
                    .labelHuman
                );

                setPricelistAdjustment(
                  pricelistDiscount,
                  ratios,
                  applyDiscount
                );

                return pricelistDiscount;
              }
            )
        );

        await (
          this.pricelistServiceDomain
              .upsert(
                pricelistsDiscount,
                this.pageDomain
              )
        );
      }
    );
  }

  public onMarkup(costList) {
    this.onDialogAction(
      'markup',
      {
        ...costList,
        currencyCodeInitial: (
          costList
            .currencyCode
        ),
        currencyCode: (
          DEFAULT_CURRENCY_CODE
        )
      },
      async data => {
        this.pricelistServiceDomain
            .markupRatioMap[
              costList
                ._id
            ] = (
              data
                .markupRatioMap
            );

        this.router
            .navigate(
              [
                `${(
                  this.isWms ?
                    '/wms' :
                    ''
                  )}/cost-lists/${(
                    costList
                      ._id
                  )}/markup`
              ],
              {
                queryParamsHandling: (
                  'preserve'
                )
              }
            )
      }
    );
  }

  public async onArchive(
    pricelist: Pricelist|PricelistWms
  ) {
    this.onDialogAction(
      'archive',
      pricelist,
      async () => (
        await (
          this.pricelistServiceDomain
              .archive(
                (
                  pricelist
                    ._id
                ),
                this.pageDomain
              )
        )
      )
    );
  }

  public async onPublish(
    pricelist: Pricelist|PricelistWms
  ) {
    this.onDialogAction(
      'publish',
      pricelist,
      async () => (
        await (
          this.pricelistServiceDomain
              .publish(
                (
                  pricelist
                    ._id
                ),
                this.pageDomain
              )
        )
      )
    );
  }

  public async onRepublish(
    pricelist: Pricelist|PricelistWms
  ) {
    this.onDialogAction(
      'republish',
      pricelist,
      async () => (
        await (
          this.pricelistServiceDomain
              .republish(
                (
                  pricelist
                    ._id
                ),
                this.pageDomain
              )
        )
      )
    );
  }

  public async onRestore(
    pricelist: Pricelist|PricelistWms
  ) {
    if (
      (
        pricelist
          .ownerEmail
      ) !==
      this.authEmail
    ) {
      return false;
    }

    this.onDialogAction(
      'restore',
      pricelist,
      async () => (
        await (
          this.pricelistServiceDomain
              .restore(
                (
                  pricelist
                    ._id
                ),
                this.pageDomain
              )
        )
      )
    );
  }

  public async onRemove(
    pricelist: Pricelist|PricelistWms
  ) {
    if (
      (
        pricelist
          .ownerEmail
      ) !==
      this.authEmail
    ) {
      return false;
    }

    this.onDialogAction(
      'remove',
      pricelist,
      async () => (
        await (
          this.pricelistServiceDomain
              .remove(
                (
                  pricelist
                    ._id
                ),
                this.pageDomain
              )
        )
      )
    );
  }

  public onTerm(
    term: string
  ) {
    if (this.termTimeout) {
      clearTimeout(
        this.termTimeout
      );

      this.termTimeout = (
        undefined
      );
    }

    this.term = (
      (
        isStringSafeNotEmpty(
          term
        )
      ) ?
        term :
        undefined
    );

    this.termTimeout = (
      setTimeout(
        () => {
          if (!this.term) {
            return (
              this.onClearTerm()
            );
          }

          if (
            (
              this.term
                  .length
            ) <
            SEARCH_MIN_CHARACTERS
          ) {
            return false;
          }

          this.router
              .navigate(
                [],
                {
                  relativeTo: (
                    this.route
                  ),
                  queryParams: {
                    term: (
                      this.term
                    ),
                    page: undefined
                  },
                  queryParamsHandling: (
                    'merge'
                  )
                }
              )
        },
        400
      )
    );
  }

  public async onTopThreshold() {
    if (
      this.dialogVisible ||
      (
        this.pagePrevious ===
        null
      )
    ) {
      return false;
    }

    this.navigateToPage(
      this.pagePrevious
    );
  }

  public async onBottomThreshold() {
    if (
      this.dialogVisible ||
      (
        this.pageNext ===
        null
      )
    ) {
      return false;
    }

    this.navigateToPage(
      this.pageNext
    );
  }

  public onSetCountryOptionActive(
    code: string,
    countryOptions: {
      null: Country,
      zones: Country[],
      preferred: Country[],
      other: Country[]
    } = (
      this.countryOptions
    )
  ) {
    this.countryOptionActive = (
      this.getCountryOption(
        code,
        countryOptions
      )
    );

    this.router
        .navigate(
          [],
          {
            relativeTo: (
              this.route
            ),
            queryParams: {
              countryCode: (
                (
                  (
                    this.countryOptionActive
                        .code
                  ) !==
                  'null'
                ) ?
                  (
                    this.countryOptionActive
                        .code
                  ) :
                  undefined
              ),
              page: undefined
            },
            queryParamsHandling: (
              'merge'
            )
          }
        );
  }

  public onSetPartnerCodeActive(
    code: string
  ) {
    this.pagePartnerCode = (
      code
    );

    this.router
        .navigate(
          [],
          {
            relativeTo: (
              this.route
            ),
            queryParams: {
              partnerCode: (
                (
                  this.pagePartnerCode !==
                  'null'
                ) ?
                  this.pagePartnerCode :
                  undefined
              ),
              page: undefined
            },
            queryParamsHandling: (
              'merge'
            )
          }
        );
  }

  private getCountryOption(
    code: string,
    countryOptions: {
      null: Country,
      zones: Country[],
      preferred: Country[],
      other: Country[]
    } = (
      this.countryOptions
    )
  ): Country {
    if (
      code ===
      'null'
    ) {
      return (
        countryOptions
          .null
      );
    }

    return (
      getModelOption(
        countryOptions,
        code
      )
    );
  }

  private navigateToPage(
    page: number
  ) {
    this.router
        .navigate(
          [],
          {
            relativeTo: (
              this.route
            ),
            queryParams: {
              page: (
                page +
                1
              )
            },
            queryParamsHandling: (
              'merge'
            )
          }
        );
  }

  private onDialogAction(
    type,
    pricelist,
    callback
  ) {
    this.dialogVisible = true;

    this.dialogService
        .open(
          DialogComponent,
          {
            context: {
              type,
              pricelist,
              pricelistDomain: (
                this.pageDomain
              )
            }
          }
        )
        .onClose
        .subscribe(
          async (
            data: {
              confirm: boolean,
              type: string
            }
          ) => {
            this.dialogVisible = false;

            if (!
              (
                data &&
                (
                  typeof data ===
                  'object'
                ) &&
                (
                  data.confirm ===
                  true
                )
              )
            ) {
              if (
                !data ||
                (
                  data
                    .type
                    .startsWith(
                      'clone'
                    )
                )
              ) {
                this.pricelistServiceDomain
                    .resetCloneRatioMap(
                      this.isCostLists
                    );
              }

              if (
                !data ||
                (
                  data
                    .type
                    .startsWith(
                      'generate'
                    )
                )
              ) {
                this.pricelistServiceDomain
                    .resetDiscountDefaults()
              }

              if (
                !data ||
                (
                  data
                    .type
                    .startsWith(
                      'markup'
                    )
                )
              ) {
                this.pricelistServiceDomain
                    .resetMarkupRatioMap();
              }

              return false;
            }
            
            this.dialogActionLoading = true;

            await callback(
              data
            );
        
            await this.getPage(
              this.page
            );

            this.dialogActionLoading = false;
        });
  }
  
  private setAuthEmail() {
    this.authEmail = (
      this.msalService
          .getAccount()
          .userName
    );
  }

  private async setAllUserNameMap() {
    (
      <any>(
        await (
          this.graphService
              .getUsers()
        )
      )
    )
    .value
    .forEach(
      user => (
        this.allUserNameMap[
          user.mail
        ] = (
          user.displayName
        )
      )
    );
  }

  private async setPageContext() {
    const params = (
      await 
        this.route
            .params
            .pipe(
              first()
            )
            .toPromise()
    );

    this.pageContext = (
      (
        [
          'archived',
          'removed'
        ].includes(
          params[
            'context'
          ]
        )
      ) ?
        (
          params[
            'context'
          ]
        ) :
        'live'
    );
  }

  private setPageDomain() {
    const isWms = (
      this.router
          .url
          .startsWith(
            '/wms'
          )
    );

    this.pageDomain = (
      (
        this.router
            .url
            .includes(
              '/cost-lists'
            )
      ) ?
        (
          isWms ?
            'costs-wms' :
            'costs'
        ) :
        (
          isWms ?
            'wms' :
            null
        )
    );

    this.pricelistServiceDomain = (
      this.isWms ?
        this.pricelistWmsService :
        this.pricelistService
    );
  }

  private async setCountryOptions(
    countryCode: string
  ) {
    const countryCodes = (
      await (
        this.pricelistServiceDomain
            .getCountryCodes()
      )
    );

    const countryOptionsGrouped = (
      getCountryOptions()
    );

    for (const key in countryOptionsGrouped) {
      countryOptionsGrouped[
        key
      ] = (
        countryOptionsGrouped[
          key
        ]
        .filter(
          country => (
            countryCodes
              .includes(
                country
                  .code
              )
          )
        )
      );
    }

    const countryOptions = {
      null: {
        code: 'null',
        name: 'None'
      },
      ...countryOptionsGrouped
    };

    this.countryOptionActive = (
      this.getCountryOption(
        (
          (
            isStringSafeNotEmpty(
              countryCode
            )
          ) ?
            countryCode :
            (
              countryOptions
                .null
                .code
            )
        ),
        countryOptions
      )
    );

    this.countryOptions = (
      countryOptions
    );
  }

  private async setPartnerCodes(
    partnerCode
  ) {
    const partnerCodes = [
      'null',
      ...(
        await 
          this.pricelistServiceDomain
              .getPartnerCodes()
      )
    ];

    this.pagePartnerCode = (
      (
        isStringSafeNotEmpty(
          partnerCode
        )
      ) ?
        partnerCode :
        partnerCodes[0]
    );
    
    this.partnerCodes = (
      partnerCodes
    );
  }

  private async initQueryParams() {
    const queryParamMap = (
      await (
        this.route
            .queryParamMap
            .pipe(
              first()
            )
            .toPromise()
      )
    );

    this.term = (
      queryParamMap
        .get(
          'term'
        )
    );

    await (
      this.setCountryOptions(
        queryParamMap
          .get(
            'countryCode'
          )
      )
    );

    await (
      this.setPartnerCodes(
        queryParamMap
          .get(
            'partnerCode'
          )
      )
    );
  }

  private initSubs() {
    this.contextSub = (
      this.route
          .paramMap
          .subscribe(
            async paramMap => {
              const contextNew = (
                (
                  [
                    'archived',
                    'removed'
                  ]
                  .includes(
                    paramMap
                      .get(
                        'context'
                      )
                  )
                ) ?
                  (
                    paramMap
                      .get(
                        'context'
                      )
                  ) :
                  'live'
              );

              if (
                contextNew &&
                (
                  contextNew !==
                  this.pageContext
                )
              ) {
                this.term = null;

                this.countryOptionActive = (
                  this.countryOptions
                      .null
                );

                this.pagePartnerCode = (
                  this.partnerCodes[0]
                );

                await (
                  this.setPageContext()
                );

                await (
                  this.getPage(
                    0
                  )
                );
              }
            }
          )
    );
    
    this.queryParamSub = (
      this.route
          .queryParamMap
          .subscribe(
            async queryParamMap => {
              const pageStr = (
                queryParamMap
                  .get(
                    'page'
                  )
              );

              if (
                !(
                  isIntegerPositiveString(
                    pageStr
                  )
                )
              ) {
                return (
                  await (
                    this.getPage(
                      0
                    )
                  )
                );
              }

              const pageNew = (
                (
                  (
                    (
                      parseInt(
                        pageStr
                      )
                    ) -
                    1
                  ) !==
                  this.page
                ) ?
                  (
                    (
                      parseInt(
                        pageStr
                      )
                    ) -
                    1
                  ) :
                  0
              );

              await (
                this.getPage(
                  pageNew
                )
              )
            }
          )
    );
  }

  private destroySubs() {
    if (this.contextSub) {
      this.contextSub
          .unsubscribe();

      this.contextSub = (
        undefined
      );
    }

    if (this.queryParamSub) {
      this.queryParamSub
          .unsubscribe();

      this.queryParamSub = (
        undefined
      );
    }
  }
}
