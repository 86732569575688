import {
  Component,
  Inject,
  OnDestroy,
  OnInit
} from '@angular/core';

import {
  Router
} from '@angular/router';

import {
  Subscription
} from 'rxjs';

import {
  MsalService,
  BroadcastService
} from '@azure/msal-angular';

import {
  GraphService,
  PricelistService,
  PricelistWmsService,
  ServiceService
} from '../providers';

import {
  getContextPath,
  initDefaultLocales,
  isFrame,
  isIE
} from './app.utils';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  public authReady: boolean;
  public authUser: any;
  public authUserPhoto: string;

  private authSuccessSub: Subscription;
  private authFailureSub: Subscription;
  private authReadySub: Subscription;

  private expandedListMap = {
    'pricelists': true,
    'cost-lists': true,
    'wms-pricelists': false,
    'wms-cost-lists': false
  };
  
  constructor(
    @Inject(
      'Window'
    ) private window: Window,

    private router: Router,
    private broadcastService: BroadcastService,
    private graphService: GraphService,
    private msalService: MsalService,
    private pricelistService: PricelistService,
    private pricelistWmsService: PricelistWmsService,
    private serviceService: ServiceService,
  ) {
    initDefaultLocales();
  }

  public async ngOnInit() {
    if (
      isFrame(
        this.window
      )
    ) {
      return false;
    }

    this.initSubs();
    
    await this.setAuthUser();
    this.setAuthReady();
  }

  public ngOnDestroy() {
    if (this.authSuccessSub) {
      this.authSuccessSub
          .unsubscribe();

      this.authSuccessSub = null;
    }

    if (this.authFailureSub) {
      this.authFailureSub
          .unsubscribe();

      this.authFailureSub = null;
    }

    if (this.authReadySub) {
      this.authReadySub
          .unsubscribe();

      this.authReadySub = null;
    }
  }

  public get contextIsLive() {
    return (
      (
        this.router
            .url
            .includes(
              `/${(
                getContextPath(
                  'live'
                )
              )}`
            )
      ) ||
      (
        (
          this.router
              .url
              .split(
                '?'
              )[0]
        ) ===
        '/'
      )
    );
  }

  public get contextIsArchived() {
    return (
      this.router
          .url
          .includes(
            `/${(
              getContextPath(
                'archived'
              )
            )}`
          )
    );
  }

  public get contextIsRemoved() {
    return (
      this.router
          .url
          .includes(
            `/${(
              getContextPath(
                'removed'
              )
            )}`
          )
    );
  }

  public get domainIsCostLists() {
    return (
      this.router
          .url
          .includes(
            '/cost-lists'
          )
    );
  }
  
  public get pageIsWms() {
    return (
      this.router
          .url
          .startsWith(
            '/wms'
          )
    );
  }

  public getExpandedIcon(
    key: string
  ) {
    return `${(
      (
        this.isExpanded(
          key
        )
      ) ?
        'minus' :
        'plus'
    )}-square-outline`;
  }

  public isExpanded(
    key: string
  ) {
    return (
      (
        this.expandedListMap[
          key
        ]
      ) ===
      true
    );
  }

  public login() {
    if (isIE) {
      this.msalService
          .loginRedirect({
            extraScopesToConsent: [
              'user.read',
              'openid',
              'profile'
            ]
          });
    }
    else {
      this.msalService
          .loginPopup({
            extraScopesToConsent: [
              'user.read',
              'openid',
              'profile'
            ]
          });
    }
  }

  public logout() {
    this.msalService
        .logout();
  }

  public toggleExpanded(
    key: string
  ) {
    if (
      typeof (
        this.expandedListMap[
          key
        ]
      ) ===
      'boolean'
    ) {
      this.expandedListMap[
        key
      ] = (
        !(
          this.expandedListMap[
            key
          ]
        )
      );
    }
  }

  private initSubs() {
    this.authSuccessSub = (
      this.broadcastService
          .subscribe(
            'msal:loginSuccess',
            async () => {
              await this.setAuthUser();
              this.setAuthReady();
            }
          )
    );

    this.authFailureSub = (
      this.broadcastService
          .subscribe(
            'msal:loginFailure',
            err => {
              console.warn(
                err.message
              );

              this.authReady = false;
              this.authUser = null;
            }
          )
    );

    this.msalService
        .handleRedirectCallback(
          (
            authError,
            response
          ) => {
            if (authError) {
              console.error(
                'Redirect Error: ',
                (
                  authError
                    .errorMessage
                )
              );

              return;
            }
          }
        );
  }

  private async setAuthUser() {
    this.authUser = (
      this.msalService
          .getAccount()
    );

    this.authUserPhoto = (
      await (
        this.graphService
            .getPhoto()
      )
    );
  }

  private setAuthReady() {
    const value = (
      this.broadcastService
          .getMSALSubject()
          .getValue()
    );

    if (
      value &&
      (
        value
          .payload
      ) &&
      (
        value
          .payload
          .idToken
      )
    ) {
      this.pricelistService
          .authToken = (
            value
              .payload
              .idToken
              .rawIdToken
          );

      this.pricelistWmsService
          .authToken = (
            value
              .payload
              .idToken
              .rawIdToken
          );

      this.serviceService
          .authToken = (
            value
              .payload
              .idToken
              .rawIdToken
          );

      this.authReady = true;
    }
  }
}
