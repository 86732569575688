import {
  NgModule
} from '@angular/core';

import {
  FormsModule
} from '@angular/forms';

import {
  BrowserModule
} from '@angular/platform-browser';

import {
  LanguageSelectComponent
} from './language-select.component';

@NgModule({
  declarations: [
    LanguageSelectComponent
  ],
  imports: [
    BrowserModule,
    FormsModule
  ],
  exports: [
    LanguageSelectComponent
  ]
})
export class LanguageSelectModule { }