import {
  Component,
  Input,
  Output,
  EventEmitter
} from '@angular/core';

import {
  CdkDragDrop,
  moveItemInArray
} from '@angular/cdk/drag-drop';

import {
  Field,
  FieldTyped
} from '../../models';

import {
  getExcelColumns,
  getExcelRows,
  objectHasValue
} from '../../app/app.utils';

@Component({
  selector: 'biz-ordered-list',
  templateUrl: './ordered-list.component.html',
  styleUrls: [
    './ordered-list.component.scss'
  ]
})
export class OrderedListComponent {
  @Input(
    'items'
  ) public itemsRaw: any[] = [];

  @Input(
    'itemEmpty'
  ) public itemEmpty: any;

  @Input(
    'fields'
  ) public set fieldsRaw(
      fieldsRaw: Field[]
  ) {
    const fields = (
      fieldsRaw
        .map(
          field => ({
            ...field,
            type: (
              this.getFieldType(
                field
              )
            )
          })
        )
    );

    this.fieldsHidden = (
      fields
        .filter(
          field => (
            field
              .isHidden
          )
        )
    );

    this.fieldModifierIndex = (
      fields
        .filter(
          field => (
            field
              .isModifier
          )
        )
        .map(
          (
            field,
            index
          ) => (
            index
          )
        )[0]
    );

    if(
      typeof (
        this.fieldModifierIndex
      ) !== 'number'
    ) {
      this.fields = fields;
      return;
    }

    this.fieldModifierValueKey = (
      fields[
        this.fieldModifierIndex
      ].valueKey
    );

    const modifierValues = (
      this.getModifierValues()
    );

    this.fields = (
      fields
        .filter(
          field => (
            (
              !(
                Array
                  .isArray(
                    field
                      .modifierValues
                  )
              ) ||
              (
                (
                  field
                    .modifierValues
                    .filter(
                      fieldModifierValue => (
                        modifierValues
                          .includes(
                            fieldModifierValue
                          )
                      )
                    ).length
                ) >
                0
              )
            )
          )
        )
    );
  };

  @Input(
    'pasteRemoveStems'
  ) public pasteRemoveStems: string[];

  @Input(
    'labelKey'
  ) public labelKey: string;

  @Input(
    'disabled'
  ) public disabled: boolean;

  @Output(
    'edit'
  ) public onEdit: EventEmitter<any[]> = (
    new EventEmitter()
  );

  public items: any[] = [];
  public itemEmptyIndex: number = null;

  public fields: FieldTyped[] = [];
  public fieldsHidden: FieldTyped[] = [];
  
  public fieldModifierIndex: number;
  public fieldModifierValueKey: string;

  public ngOnInit() {
    if (
      Array
        .isArray(
          this.itemsRaw
        )
    ) {
      this.items = ([
        ...this.itemsRaw,
        ...(
          this.itemEmpty ?
            [{...
              this.itemEmpty
            }] :
            []
        )
      ]);

      if (this.itemEmpty) {
        this.itemEmptyIndex = (
          (
            this.items
                .length
          ) -
          1
        );
      }
    }
  }

  public get isValid() {
    return true; // TODO: Implement actual, working validation
    return (
      (
        Array
          .isArray(
            this.items
          )
      ) &&
      (
        (
          this.items
              .length
        ) >
        0
      ) &&
      (
        (
          !this.labelKey &&
          (
            [
              'string',
              'number'
            ].includes(
              typeof this.items[0]
            )
          )
        ) ||
        (
          this.labelKey &&
          (
            typeof this.labelKey ===
            'string'
          ) &&
          (
            typeof this.items[0] ===
            'object'
          ) &&
          (
            this.items[0][
              this.labelKey
            ]
          ) &&
          (
            [
              'string',
              'number'
            ].includes(
              typeof (
                this.items[0][
                  this.labelKey
                ]
              )
            )
          )
        )
      )
    );
  }

  public drop(
    event: CdkDragDrop<string[]>
  ) {
    moveItemInArray(
      this.items,
      event.previousIndex,
      event.currentIndex
    );

    this.setItemEmptyIndex();

    this.onEdit
        .emit(
          this.getItemsRaw()
        );
  }

  public isItemFieldDisabled(
    field,
    item
  ) {
    if (!(
      field &&
      (
        this.itemHasValue(
          item
        )
      ) &&
      (
        Array
          .isArray(
            this.fields
          )
      ) &&
      (
        (
          this.fieldModifierIndex !==
          null
        ) &&
        (
          typeof this.fieldModifierIndex !==
          'undefined'
        )
      ) &&
      this.fieldModifierValueKey
    )) {
      return false;
    }

    const fieldModifierValue = (
      item[
        this.fieldModifierValueKey
      ]
    );

    return (
      (
        Array
          .isArray(
            field
              .modifierValues
          )
      ) &&
      (
        !(
          field
            .modifierValues
            .includes(
              fieldModifierValue
            )
        )
      )
    );
  }

  public onPaste(
    event,
    itemIndex: number,
    valueType: string,
    fieldIndex: number
  ) {
    const data = (
      (
        event
          .clipboardData
          .getData(
            'Text'
          )
      ) ||
      (
        event
          .clipboardData
          .getData(
            'text/plain'
          )
      )
    );

    if (!data) {
      return false;
    }

    event
      .preventDefault();

    const fieldModifier = (
      this.fields
          .filter(
            field => (
              field
                .isModifier
            )
          )[0]
    );

    const rows = (
      getExcelRows(
        data
      )
    );

    let fieldModifierValue;

    rows
      .forEach(
        (
          row,
          rowIndex
        ) => {
          const columns = (
            getExcelColumns(
              row,
              valueType,
              this.pasteRemoveStems
            )
          );

          if (fieldModifier) {
            if (
              rowIndex ===
              0
            ) {
              fieldModifierValue = (
                this.items[
                  itemIndex
                ][
                  fieldModifier
                    .valueKey
                ]
              );
            }
            else {
              this.set(
                (
                  itemIndex +
                  rowIndex
                ),
                fieldModifier.valueKey,
                fieldModifier.valueType,
                fieldModifierValue
              );
            }
          }

          let columnFieldIndex = (
            fieldIndex
          );

          columns
            .forEach(
              column => {
                const field = (
                  this.fields[
                    columnFieldIndex++
                  ]
                );

                if (!field) {
                  return false;
                }

                this.set(
                  (
                    itemIndex +
                    rowIndex
                  ),
                  field.valueKey,
                  field.valueType,
                  column
                );
              }
            );
        }
      );
  }
  
  public remove(
    itemIndex: number
  ) {
    const item = (
      this.items[
        itemIndex
      ]
    );

    if (!item) {
      return false;
    }
    
    this.items
        .splice(
          itemIndex,
          1
        );

    this.setItemEmptyIndex();

    this.onEdit
        .emit(
          this.getItemsRaw()
        );
  }

  public set(
    itemIndex: number,
    valueKey: string,
    valueType: string,
    value: any
  ) {
    const item = (
      this.items[
        itemIndex
      ]
    );

    if (!item) {
      return false;
    }

    item[
      valueKey
    ] = (
      value ?
        (
          (
            [
              'float',
              'number'
            ].includes(
              valueType
            )
          ) ?
            (
              parseFloat(
                value
              )
            ) :
            (
              (
                valueType ===
                'integer'
              ) ?
                parseInt(
                  value
                ) :
                value
            )
        ) :
        null
    );

    this.handleEmptyItems();
    this.handleEmptyItemModifiers();

    this.onEdit
        .emit(
          this.getItemsRaw()
        );
  }

  public trackFieldBy(
    index: number,
    field: any
  ) {
    return index;
  }

  public getItemsRaw() {
    return (
      this.items
          .filter(
            item => (
              this.itemHasValue(
                item
              )
            )
          )
    );
  }

  private getFieldType(field) {
    return (
      (
        field
          .valueType
      ) ?
        (
          (
            [
              'integer',
              'float'
            ].includes(
              field
                .valueType
            )
          ) ?
            'number' :
            (
              field
                .valueType
            )
        ) :
        'text'
    );
  }

  private getModifierValues() {
    const modifierValues = [];

    for (let i = 0; i < this.itemsRaw.length; i++) {
      const item = (
        this.itemsRaw[i]
      );

      modifierValues
        .push(
          item[
            this.fieldModifierValueKey
          ]
        );
    }

    return [
      ...new Set(
        modifierValues
      )
    ];
  }

  private handleEmptyItems() {
    if (!this.itemEmpty) {
      return false;
    }

    this.itemEmptyIndex = null;
    for (let i = this.items.length - 1; i >= 0; i--) {
      const item = (
        this.items[i]
      );

      const clone = {
        ...item
      };

      if (
        !this.itemHasValue(
          clone
        )
      ) {
        this.itemEmptyIndex = i;

        this.items
            .splice(
              i,
              1
            );
      }
    }

    if (
      typeof this.itemEmptyIndex !==
      'number'
    ) {
      this.itemEmptyIndex = (
        this.items
            .length
      );
    }

    this.items
        .splice(
          this.itemEmptyIndex,
          0,
          {
            ...this.itemEmpty
          }
        );
  }

  private handleEmptyItemModifiers() {
    if (!(
      (
        Array
          .isArray(
            this.fields
          )
      ) &&
      (
        this.fields[
          this.fieldModifierIndex
        ]
      )
    )) {
      return false;
    }
    
    this.items
        .some(
          (
            item,
            index 
          ) => {
            if (
              (
                this.itemHasValue(
                  item
                ) &&
                item[
                  this.fieldModifierValueKey
                ]
              ) ||
              (
                !this.itemHasValue(
                  item
                )
              )
            ) {
              return false;
            }

            let match = false;

            Object
              .keys(
                item
              )
              .some(
                key => {
                  if (
                    !!item[
                      key
                    ]
                  ) {
                    const keyField = (
                      this.fields
                          .filter(
                            field => (
                              (
                                field
                                  .valueKey
                              ) ===
                              key
                            )
                          )[0]
                    );

                    if (
                      keyField &&
                      (
                        Array
                          .isArray(
                            keyField
                              .modifierValues
                          )
                      )
                    ) {
                      this
                        .items[
                          index
                        ][
                          this.fieldModifierValueKey
                        ] = (
                          keyField
                            .modifierValues[0]
                        );

                      match = true;
                      return true;
                    }
                  }
                }
              )

            if (match) {
              return true;
            }

            this
              .items[
                index
              ][
                this.fieldModifierValueKey
              ] = (
                this.fields[
                  this.fieldModifierIndex
                ].values[0]
              );

            return true;
          }
        )
  }

  private itemHasValue(item) {
    const clone = {
      ...item
    };

    this.fieldsHidden
        .forEach(
          fieldHidden => (
            clone[
              fieldHidden
                .valueKey
            ] = undefined
          )
        );
    
    return objectHasValue(
      clone
    );
  }

  private setItemEmptyIndex() {
    for (let i = this.items.length - 1; i >= 0; i--) {
      const item = (
        this.items[i]
      );

      if (
        !this.itemHasValue(
          item
        )
      ) {
        this.itemEmptyIndex = i;
      }
    }

    if (
      typeof this.itemEmptyIndex !==
      'number'
    ) {
      this.itemEmptyIndex = (
        (
          this.items
              .length
        ) -
        1
      );
    }
  }
}