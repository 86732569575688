<div
  class="active"
  (click)="(
    onToggleSelect(
      $event
    )
  )"
  *ngIf="(
    languageOptionActive
  )">
  <img
    class="icon"
    [src]="(
      languageOptionActive
        .urlIcon
    )"/>

  <span
    class="label"
    [innerHTML]="(
      languageOptionActive
        .name
    )">
  </span>
</div>

<div
  class="select"
  *ngIf="showSelect">
  <div
    class="option"
    [ngClass]="{
      'active': (
        languageCodeActive ===
        (
          languageOptionPreferred
            .code
        )
      )
    }"
    (click)="(
      onLanguageOptionActive(
        languageOptionPreferred,
        $event
      )
    )"
    *ngFor="
      let languageOptionPreferred of
        languageOptionsPreferred
    ">
    <img
      class="icon"
      [src]="(
        languageOptionPreferred
          .urlIcon
      )"/>
  
    <span
      class="label"
      [innerHTML]="(
        languageOptionPreferred
          .name
      )">
    </span>
  </div>
</div>