import {
  NgModule
} from '@angular/core';

import {
  Routes,
  RouterModule
} from '@angular/router';

import {
  MsalGuard
} from '@azure/msal-angular';

import {
  HomeComponent
} from '../pages/home/home.component';

import {
  PricelistComponent
} from '../pages/pricelist/pricelist.component';

import {
  PricelistWmsComponent
} from '../pages/pricelist-wms/pricelist-wms.component';

const routes: Routes = [
  {
    path: '',
    component: HomeComponent,
    canActivate: [
      MsalGuard
    ]
  },
  {
    path: ':context',
    component: HomeComponent,
    canActivate: [
      MsalGuard
    ]
  },
  {
    path: 'cost-lists/context/:context',
    component: HomeComponent,
    canActivate: [
      MsalGuard
    ]
  },
  {
    path: 'wms/context/:context',
    component: HomeComponent,
    canActivate: [
      MsalGuard
    ]
  },
  {
    path: 'wms/cost-lists/context/:context',
    component: HomeComponent,
    canActivate: [
      MsalGuard
    ]
  },
  {
    path: 'pricelists/new',
    component: PricelistComponent,
    canActivate: [
      MsalGuard
    ]
  },
  {
    path: 'pricelists/:_id',
    component: PricelistComponent,
    canActivate: [
      MsalGuard
    ]
  },
  {
    path: 'pricelists/:_id/clone',
    component: PricelistComponent,
    canActivate: [
      MsalGuard
    ]
  },
  {
    path: 'cost-lists/new',
    component: PricelistComponent,
    canActivate: [
      MsalGuard
    ]
  },
  {
    path: 'cost-lists/:_id',
    component: PricelistComponent,
    canActivate: [
      MsalGuard
    ]
  },
  {
    path: 'cost-lists/:_id/clone',
    component: PricelistComponent,
    canActivate: [
      MsalGuard
    ]
  },
  {
    path: 'cost-lists/:_id/markup',
    component: PricelistComponent,
    canActivate: [
      MsalGuard
    ]
  },
  {
    path: 'wms/pricelists/new',
    component: PricelistWmsComponent,
    canActivate: [
      MsalGuard
    ]
  },
  {
    path: 'wms/pricelists/:_id',
    component: PricelistWmsComponent,
    canActivate: [
      MsalGuard
    ]
  },
  {
    path: 'wms/pricelists/:_id/clone',
    component: PricelistWmsComponent,
    canActivate: [
      MsalGuard
    ]
  },
  {
    path: 'wms/cost-lists/new',
    component: PricelistWmsComponent,
    canActivate: [
      MsalGuard
    ]
  },
  {
    path: 'wms/cost-lists/:_id',
    component: PricelistWmsComponent,
    canActivate: [
      MsalGuard
    ]
  },
  {
    path: 'wms/cost-lists/:_id/clone',
    component: PricelistWmsComponent,
    canActivate: [
      MsalGuard
    ]
  },
  {
    path: 'wms/cost-lists/:_id/markup',
    component: PricelistWmsComponent,
    canActivate: [
      MsalGuard
    ]
  }
];

@NgModule({
  imports: [
    RouterModule
      .forRoot(
        routes
      )
  ],
  exports: [
    RouterModule
  ]
})
export class AppRoutingModule { }
