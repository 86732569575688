import localeEnGb from
  '@angular/common/locales/en-GB';

import {
  NgModule,
  LOCALE_ID
} from '@angular/core';

import {
  CommonModule,
  registerLocaleData
} from '@angular/common';

import {
  HTTP_INTERCEPTORS
} from '@angular/common/http';

import {
  BrowserModule
} from '@angular/platform-browser';

import {
  BrowserAnimationsModule
} from '@angular/platform-browser/animations';

import {
  MsalModule
} from '@azure/msal-angular';

import {
  NbEvaIconsModule
} from '@nebular/eva-icons';

import {
  NbButtonModule,
  NbDatepickerModule,
  NbDialogModule,
  NbIconModule,
  NbLayoutModule,
  NbListModule,
  NbSidebarModule,
  NbSpinnerModule,
  NbThemeModule
} from '@nebular/theme';

import {
  QuillModule
} from 'ngx-quill';

import {
  HomeModule,
  PricelistModule,
  PricelistWmsModule
} from '../pages';

import {
  AppRoutingModule
} from './app-routing.module';

import {
  AzureInterceptor,
  GraphService
} from '../providers';

import {
  AppComponent
} from './app.component';

import {
  isIE
} from './app.utils';

import {
  _PAY_URL
} from './app.const';

const browserIsIE = (
  isIE()
);

registerLocaleData(
  localeEnGb,
  'en-GB'
);

@NgModule({
  declarations: [
    AppComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    CommonModule,
    MsalModule.forRoot({
      auth: {
        clientId: 'a4c9d6d8-0c54-463d-93ac-5c181b6aa6e3',
        authority: 'https://login.microsoftonline.com/6b0f0948-9549-4b27-b0d7-bbac7fba728f', // Azure Tenant ID
        redirectUri: _PAY_URL
      },
      cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: browserIsIE,
      },
    }, {
      popUp: !browserIsIE,
      consentScopes: [
        'user.read',
        'openid',
        'profile',
      ],
      unprotectedResources: [],
      protectedResourceMap: [
        [
          'https://graph.microsoft.com/v1.0/me',
          [
            'user.read'
          ]
        ],
        [
          'https://graph.microsoft.com/v1.0/users',
          [
            'user.read'
          ]
        ]
      ],
      extraQueryParameters: {}
    }),
    NbButtonModule,
    NbEvaIconsModule,
    NbIconModule,
    NbLayoutModule,
    NbListModule,
    NbDatepickerModule.forRoot(),
    NbDialogModule.forRoot(),
    NbSidebarModule.forRoot(),
    NbSpinnerModule,
    NbThemeModule.forRoot({
      name: 'biz'
    }),
    QuillModule.forRoot(),
    HomeModule,
    PricelistModule,
    PricelistWmsModule,
    AppRoutingModule
  ],
  providers: [
    {
      provide: 'Window',
      useValue: window
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AzureInterceptor,
      multi: true
    },
    {
      provide: LOCALE_ID,
      useValue: 'en-GB'
    },
    GraphService
  ],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule { }
