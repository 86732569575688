import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  Input,
  QueryList,
  ViewChild,
  ViewChildren
} from '@angular/core';

import {
  NbDialogRef,
  NbStepperComponent
} from '@nebular/theme';

import {
  Discount,
  Ratios,
  Pricelist
} from '../../models';

import {
  PricelistService
} from '../../providers';

import {
  isArrayNotEmpty,
  isStringSafe
} from '../../app/app.utils';

import {
  DEFAULT_CURRENCY_CODE
} from '../../app/app.const';

@Component({
  selector: 'biz-dialog',
  templateUrl: './dialog.component.html',
  styleUrls: [
    './dialog.component.scss'
  ]
})
export class DialogComponent implements AfterViewInit {
  @HostListener(
    'document:click',
    [
      '$event'
    ]
  ) handleCheckboxClick(
    event: MouseEvent
  ) {
    if (!this.discountStepper) {
      return false;
    }

    if (
      (
        (
          <HTMLElement>(
            event
              .target
          )
        )
      ) &&
      (
        (
          <HTMLElement>(
            event
              .target
          )
        )
        .className
      ) &&
      (
        (
          (
            <HTMLElement>(
              event
                .target
            )
          )
          .className
        ) ===
        'label'
      ) &&
      (
        (
          <HTMLElement>(
            event
              .target
          )
        )
        .childNodes
      ) &&
      (
        (
          <HTMLElement>(
            event
              .target
          )
        )
        .childNodes[0]
      ) &&
      (
        (
          (
            <HTMLElement>(
              (
                <HTMLElement>(
                  event
                    .target
                )
              )
              .childNodes[0]
            )
          )
          .getAttribute(
            'type'
          )
        ) ===
        'checkbox'
      )
    ) {
      (
        <HTMLElement>(
          event
            .target
        )
      )
      .parentElement
      .parentElement
      .parentElement
      .parentElement
      .classList
      .toggle(
        'disabled'
      );
    }
  }

  @ViewChild(
    'discountStepper',
    {
      read: NbStepperComponent
    }
  ) discountStepper: NbStepperComponent;

  @ViewChildren(
    'discountCheckbox',{
      read: ElementRef
    }
  ) discountCheckboxes: QueryList<ElementRef>;

  @Input('type')
  public type: string;

  @Input('pricelist')
  public pricelist: Pricelist;

  @Input('pricelistDomain')
  public pricelistDomain: string;

  public discountDefaults: Discount[];
  public discountDefaultsOffIndexes: number[] = [
    2,
    3,
    5
  ];

  public discountRatioMap: Ratios;
  public markupRatioMap: Ratios;
  public markupCostsRatioMap: Ratios;

  constructor(
    private dialogRef: NbDialogRef<DialogComponent>,
    private pricelistService: PricelistService
  ) {
    this.discountDefaults = [
      ...(
        this.pricelistService
            .discountDefaults
      )
    ];

    this.discountRatioMap = (
      this.pricelistService
          .cloneDiscountRatioMap
    );

    this.markupCostsRatioMap = (
      this.pricelistService
          .markupCostsRatioMap
    );

    this.markupRatioMap = (
      this.pricelistService
          .markupRatioMap
    );
  }

  public ngAfterViewInit() {
    const discountCheckboxes = (
      this.discountCheckboxes
          .toArray()
          .filter(
            discountCheckbox => (
              !!(
                discountCheckbox
                  .nativeElement
              )
            )
          )
    );

    if (
      isArrayNotEmpty(
        discountCheckboxes
      )
    ) {
      this.discountDefaultsOffIndexes
          .forEach(
            discountOffIndex => (
              discountCheckboxes[
                discountOffIndex
              ]
              .nativeElement
              .parentElement
              .parentElement
              .parentElement
              .classList
              .add(
                'disabled'
              )
            )
          );
    }
  }

  public get pageDomainModelName() {
    return (
      (
        (
          isStringSafe(
            this.pricelistDomain
          )
        ) &&
        (
          this.pricelistDomain
            .startsWith(
              'costs'
            )
        )
      ) ?
        'Cost List' :
        'Pricelist'
    );
  }

  public get pricelistHasCurrencyCodeDefault() {
    return (
      this.pricelist &&
      (
        (
          this.pricelist
              .currencyCode
        ) ===
        DEFAULT_CURRENCY_CODE
      )
    );
  }

  public isDiscountOn(
    index
  ) {
    return (
      !(
        this.discountDefaultsOffIndexes
            .includes(
              index
            )
      )
    );
  }

  public onConfirm() {
    this.dialogRef
        .close({
          confirm: true,
          type: this.type,
          ...(
            (
              this.type ===
              'clone'
            ) ?
              {
                discountRatioMap: (
                  this.discountRatioMap
                )
              } :
              {}
          ),
          ...(
            (
              this.type ===
              'clone-costs'
            ) ?
              {
                markupCostsRatioMap: (
                  this.markupCostsRatioMap
                )
              } :
              {}
          ),
          ...(
            (
              this.type ===
              'generate'
            ) ?
              {
                discountDefaults: (
                  this.discountDefaults
                      .filter(
                        (
                          discountEach,
                          index
                        ) => (
                          !(
                            this.discountDefaultsOffIndexes
                                .includes(
                                  index
                                )
                          )
                        )
                      )
                )
              } :
              {}
          ),
          ...(
            (
              this.type ===
              'markup'
            ) ?
              {
                markupRatioMap: {
                  ...this.markupRatioMap,
                  currencyCode: (
                    this.pricelist
                        .currencyCode
                  ),
                  currencyRate: (
                    this.pricelistHasCurrencyCodeDefault ?
                      (
                        this.pricelist
                            .rateCurrency
                      ) :
                      undefined
                  )
                }
              } :
              {}
          )
        });
  }

  public onClose() {
    this.dialogRef
        .close({
          confirm: false,
          type: this.type
        });
  }

  public onToggleDiscountDefault(
    index: number
  ) {
    const discountOffIndex = (
      this.discountDefaultsOffIndexes
          .indexOf(
            index
          )
    );

    if (
      discountOffIndex >
      -1
    ) {
      this.discountDefaultsOffIndexes
          .splice(
            discountOffIndex,
            1
          );
    }
    else {
      this.discountDefaultsOffIndexes
          .push(
            index
          );
    }
  }
}