<nb-spinner
  size="giant"
  status="primary"
  [message]="null"
  *ngIf="!pricelist">
</nb-spinner>

<ng-container
  *ngIf="pricelist">
  <div
    class="row button-row"
    [ngClass]="{
      'read-only': (
        pricelistIsReadOnly
      )
    }">
    <button
      nbButton
      size="medium"
      status="success"
      (click)="onSave()"
      *ngIf="(
        pricelistIsEdit &&
        pricelist.isDraft
      )">
      <nb-icon
        icon="save">
      </nb-icon>
      Save Changes
    </button>

    <button
      nbButton
      size="medium"
      status="success"
      (click)="onSave()"
      *ngIf="pricelistIsClone">
      <nb-icon
        icon="save">
      </nb-icon>
      Save Clone
    </button>

    <button
      nbButton
      size="medium"
      status="success"
      (click)="onSave()"
      *ngIf="pricelistIsMarkup">
      <nb-icon
        icon="save">
      </nb-icon>
      Save New Pricelist
    </button>

    <button
      nbButton
      size="medium"
      status="success"
      (click)="onSave()"
      *ngIf="pricelistIsNew">
      <nb-icon
        icon="save">
      </nb-icon>
      Save New
    </button>

    <div
      class="warning text-danger"
      *ngIf="showRefreshWarning">
      Do not refresh browser! Any discount, markup or currency conversion options will be lost and the process will be aborted!
    </div>

    <button
      nbButton
      size="medium"
      class="abort-button"
      (click)="onBack()">
      <nb-icon
        icon="close">
      </nb-icon>
      {{
        pricelistIsReadOnly ?
          'Close' :
          'Abort'
      }}
    </button>
  </div>
  
  <biz-header
    label="Core Info">
  </biz-header>

  <div
    class="row">
    <nb-form-field
      class="primary-field label-field"
      [ngClass]="{
        'disabled-field': (
          pricelistIsReadOnly
        )
      }">
      <nb-icon
        nbPrefix
        icon="bookmark-outline">
      </nb-icon>

      <input
        nbInput
        type="text"
        autocomplete="off"
        placeholder="Name"
        [ngModel]="pricelistLabel"
        (ngModelChange)="onPricelistLabel($event)"/>

      <biz-language-select
        [languageCodeActive]="languageCodeActiveLabel"
        (languageCodeActiveChange)="onLanguageCodeActiveLabel($event)">
      </biz-language-select>
    </nb-form-field>

    <div
      class="subrow">
      <nb-form-field
        class="currency-field"
        [ngClass]="{
          'currency-two-digits': (
            (
              currencyOptionActive
                .symbol
                .length
            ) <=
            2
          ),
          'currency-three-digits': (
            (
              currencyOptionActive
                .symbol
                .length
            ) >
            2
          ),
          'disabled-field': pricelistIsReadOnly,
          'has-sibling': currencyRateFieldShow
        }"
        *ngIf="(
          currencyOptions &&
          currencyOptionActive
        )">
        <span
          nbPrefix
          class="char-prefix">
          {{(
            currencyOptionActive
              .symbol
              .substr(
                0,
                3
              )
          )}}
        </span>

        <nb-select
          placeholder="Currency"
          [selected]="currencyOptionActive.code"
          (selectedChange)="setCurrencyOptionActive($event)">
          <nb-select-label>
            Currency: {{ currencyOptionActive.code }}
          </nb-select-label>

          <nb-option-group
            title="EU Network">
            <nb-option
              [value]="currency.code"
              *ngFor="
                let currency of (
                  currencyOptions
                    .preferred
                )
              ">
              {{ currency.code }} <span
                *ngIf="(
                  currency.code !==
                  currency.symbol
                )">({{
                currency.symbol
              }})</span>
            </nb-option>
          </nb-option-group>
          
          <nb-option-group
            title="Other">
            <nb-option
              [value]="currency.code"
              *ngFor="
                let currency of (
                  currencyOptions
                    .other
                )
              ">
              {{ currency.code }} <span
                *ngIf="(
                  currency.code !==
                  currency.symbol
                )">({{
                currency.symbol
              }})</span>
            </nb-option>
          </nb-option-group>
        </nb-select>
      </nb-form-field>

      <nb-form-field
        [ngClass]="{
          'disabled-field': pricelistIsReadOnly,
          'has-sibling': currencyRateFieldShow
        }"
        *ngIf="currencyRateFieldShow">
        <nb-icon
          nbPrefix
          icon="trending-up-outline">
        </nb-icon>

        <input
          nbInput
          class="has-suffix"
          type="number"
          min="0.000001"
          step="0.000001"
          autocomplete="off"
          placeholder="Exchange Rate"
          [(ngModel)]="pricelist.rateCurrency"/>

        <span
          nbPrefix
          class="char-suffix">
          {{(
            currencyOptions
              .preferred[0]
              .symbol
          )}}
        </span>
      </nb-form-field>
    </div>
  </div>

  <div
    class="row">
    <nb-form-field
      [ngClass]="{
        'disabled-field': (
          pricelistIsReadOnly
        )
      }"
      *ngIf="(
        countryOptions &&
        countryOptionActive
      )">
      <nb-icon
        nbPrefix
        icon="flag-outline">
      </nb-icon>

      <nb-select
        placeholder="Country"
        [ngClass]="{
          'align-ignore-arrow': (
            (
              countryOptionActive
                .name
                .length
            ) <
            20
          )
        }"
        [selected]="countryOptionActive.code"
        (selectedChange)="setCountryOptionActive(
          $event,
          true
        )">
        <nb-select-label>
          {{ countryOptionLabel }}: {{ countryOptionActive.name }}
        </nb-select-label>

        <nb-option-group
          title="Global Zones">
          <nb-option
            [value]="country.code"
            *ngFor="
              let country of (
                countryOptions
                  .zones
              )
            ">
            {{ country.name }}
          </nb-option>
        </nb-option-group>

        <nb-option-group
          title="EU Network">
          <nb-option
            [value]="country.code"
            *ngFor="
              let country of (
                countryOptions
                  .preferred
              )
            ">
            {{ country.name }}
          </nb-option>
        </nb-option-group>
        
        <nb-option-group
          title="Other">
          <nb-option
            [value]="country.code"
            *ngFor="
              let country of (
                countryOptions
                  .other
              )
            ">
            {{ country.name }}
          </nb-option>
        </nb-option-group>
      </nb-select>
    </nb-form-field>

    <nb-form-field
      [ngClass]="{
        'disabled-field': (
          pricelistIsReadOnly ||
          hasSinglePartnerCode
        )
      }"
      *ngIf="(
        hasCountryPartnerCodeMapValue &&
        (
          countryOptionActive &&
          (
            (
              countryOptionActive
                .code
            ) !==
            'gr'
          )
        )
      )">
      <nb-icon
        nbPrefix
        icon="shuffle-2-outline">
      </nb-icon>

      <nb-select
        placeholder="Partner Code"
        [selected]="pricelist.partnerCode"
        *ngIf="hasSinglePartnerCode">
        <nb-select-label>
          Partner Code: {{ pricelist.partnerCode }}
        </nb-select-label>

        <nb-option
          [value]="partnerCode"
          *ngFor="
            let partnerCode of (
              partnerCodes
            )
          ">
          {{ partnerCode }}
        </nb-option>
      </nb-select>

      <input
        nbInput
        type="text"
        autocomplete="off"
        placeholder="Partner Code"
        [(ngModel)]="pricelist.partnerCode"
        *ngIf="!hasSinglePartnerCode"/>
    </nb-form-field>
  </div>

  <div
    class="row">
    <nb-form-field
      class="toggle-field"
      [ngClass]="{
        'disabled-field': (
          pricelistIsReadOnly
        )
      }">
      <nb-toggle
        labelPosition="end"
        [ngModel]="pricelist.isCod"
        (ngModelChange)="(
          setPricelistIsCod($event)
        )">
        Cash on Delivery
      </nb-toggle>
    </nb-form-field>

    <nb-form-field
      class="toggle-field"
      [ngClass]="{
        'disabled-field': (
          pricelistIsReadOnly
        )
      }">
      <nb-toggle
        labelPosition="end"
        [ngModel]="pricelist.isPos"
        (ngModelChange)="(
          setPricelistIsPos($event)
        )">
        Point of Sale (POS)
      </nb-toggle>
    </nb-form-field>
  </div>

  <ng-container
    *ngIf="hasVat">
    <biz-header
      label="Regional Info">
    </biz-header>
    
    <div
      class="row">
      <nb-form-field
        [ngClass]="{
          'disabled-field': (
            pricelistIsReadOnly
          )
        }"
        *ngIf="vatRates">
        <nb-icon
          nbPrefix
          icon="pie-chart-outline">
        </nb-icon>

        <nb-select
          placeholder="VAT Rate"
          [(selected)]="pricelist.vatRate">
          <nb-select-label>
            VAT Rate: {{ pricelist.vatRate | percent : '1.0-1' }}
          </nb-select-label>
          <nb-option
            [value]="vatRate"
            *ngFor="let vatRate of vatRates">
            {{ vatRate | percent : '1.0-1' }}
          </nb-option>
        </nb-select>
      </nb-form-field>

      <nb-form-field
        [ngClass]="{
          'disabled-field': (
            pricelistIsReadOnly ||
            !hasRegionCodes ||
            pricelistHasRatesZones ||
            pricelistHasRatesReturnZones
          ),
          'transparent-field': (
            !hasRegionCodes ||
            pricelistHasRatesZones ||
            pricelistHasRatesReturnZones
          )
        }">
        <nb-icon
          nbPrefix
          icon="map">
        </nb-icon>

        <nb-select
          [placeholder]="(
            getPricelistRegionLabel(
              false
            )
          )"
          [selected]="(
            regionCodeOptionActive ?
              regionCodeOptionActive.value :
              null
          )"
          (selectedChange)="setRegionCodeOptionActive($event)">
          <nb-select-label>
            Region{{(
              regionCodeOptionActive ?
                (
                  ': ' +
                  regionCodeOptionActive.label
                ) :
                ''
            )}}
          </nb-select-label>

          <nb-option
            [value]="null"
            [innerHTML]="(
              getPricelistRegionLabelMainland()
            )">
          </nb-option>
          
          <nb-option
            [value]="regionCodeOption.value"
            *ngFor="let regionCodeOption of regionCodeOptions">
            {{ regionCodeOption.label }}
          </nb-option>
        </nb-select>
      </nb-form-field>
    </div>
  </ng-container>

  <biz-header
    label="Date Range of Validity">
  </biz-header>

  <div
    class="row">
    <nb-form-field
      [ngClass]="{
        'disabled-field': (
          pricelistIsReadOnly
        )
      }">
      <nb-icon
        nbPrefix
        icon="calendar-outline">
      </nb-icon>

      <input
        nbInput
        placeholder="Start Date"
        [value]="pricelist.validTimestampStart | date : 'dd MMMM yyyy' : '+0000'"
        [nbDatepicker]="dateTimePickerDateStart">

      <nb-datepicker
        #dateTimePickerDateStart
        (dateChange)="setValidTimestampStart($event)">
      </nb-datepicker>
    </nb-form-field>

    <nb-form-field
      [ngClass]="{
        'disabled-field': (
          pricelistIsReadOnly
        )
      }">
      <nb-icon
        nbPrefix
        icon="calendar">
      </nb-icon>

      <input
        nbInput
        placeholder="End Date"
        [value]="pricelist.validTimestampEnd | date : 'dd MMMM yyyy' : '+0000'"
        [nbDatepicker]="dateTimePickerDateEnd">

      <nb-datepicker
        #dateTimePickerDateEnd
        (dateChange)="setValidTimestampEnd($event)">
      </nb-datepicker>
    </nb-form-field>
  </div>

  <biz-header
    label="Associated Services">
  </biz-header>

  <div
    class="row">
    <nb-form-field
      [ngClass]="{
        'disabled-field': (
          pricelistIsReadOnly
        )
      }"
      *ngIf="services">
      <nb-icon
        nbPrefix
        icon="briefcase-outline">
      </nb-icon>

      <nb-select
        multiple
        placeholder="Service Codes"
        [selected]="pricelistServicesActive"
        (selectedChange)="onPricelistServicesActive($event)">
        <nb-option
          [value]="service"
          *ngFor="let service of services">
          {{service.codeLegacy}} - {{ service.label }}
        </nb-option>
      </nb-select>
    </nb-form-field>

    <!-- <nb-form-field
      [ngClass]="{
        'disabled-field': (
          pricelistIsReadOnly
        )
      }"
      *ngIf="services">
      <nb-icon
        nbPrefix
        icon="link-outline">
      </nb-icon>

      <input
        placeholder="Service Protocol URL"
        [(ngModel)]="pricelist.serviceProtocolUrl"/>
    </nb-form-field> -->
  </div>

  <biz-header
    label="Assigned Users">
  </biz-header>

  <div
    class="row">
    <nb-form-field
      [ngClass]="{
        'disabled-field': (
          pricelistIsReadOnly
        )
      }"
      *ngIf="users">
      <nb-icon
        nbPrefix
        icon="person-outline">
      </nb-icon>

      <nb-select
        multiple
        placeholder="CRM Profiles"
        [selected]="pricelistUsersActive"
        (selectedChange)="onPricelistUsersActive($event)">
        <nb-option
          [value]="user"
          *ngFor="let user of users">
          {{ user.code }}<br/>
          {{ user.organisation }}
        </nb-option>
      </nb-select>
    </nb-form-field>
  </div>

  <biz-header
    label="Consignments per Month">
  </biz-header>

  <div
    class="row">
    <nb-form-field
      [ngClass]="{
        'disabled-field': (
          pricelistIsReadOnly
        )
      }">
      <nb-icon
        nbPrefix
        icon="arrow-ios-forward-outline">
      </nb-icon>

      <input
        nbInput
        type="number"
        min="1"
        step="1"
        autocomplete="off"
        placeholder="Min. Consignments"
        [(ngModel)]="pricelist.orderMin"/>
    </nb-form-field>

    <nb-form-field
      [ngClass]="{
        'disabled-field': (
          pricelistIsReadOnly
        )
      }">
      <nb-icon
        nbPrefix
        icon="arrow-ios-back-outline">
      </nb-icon>

      <input
        nbInput
        type="number"
        min="1"
        step="1"
        autocomplete="off"
        placeholder="Max. Consignments"
        [(ngModel)]="pricelist.orderMax"/>
    </nb-form-field>
  </div>

  <biz-header
    label="Consignment Rates">
  </biz-header>

  <div
    class="row">
    <nb-form-field
      [ngClass]="{
        'disabled-field': (
          pricelistIsReadOnly
        )
      }">
      <nb-icon
        nbPrefix
        icon="shopping-bag-outline">
      </nb-icon>

      <input
        nbInput
        type="number"
        min="1"
        step="1"
        autocomplete="off"
        placeholder="Increment"
        [(ngModel)]="pricelist.rateUnit.unitInc"/>
    </nb-form-field>

    <nb-form-field
      class="currency-field"
      [ngClass]="{
        'currency-two-digits': (
          (
            currencyOptionActive
              .symbol
              .length
          ) <=
          2
        ),
        'currency-three-digits': (
          (
            currencyOptionActive
              .symbol
              .length
          ) >
          2
        ),
        'disabled-field': (
          pricelistIsReadOnly
        )
      }"
      *ngIf="(
        currencyOptions &&
        currencyOptionActive
      )">
      <span
        nbPrefix
        class="char-prefix">
        {{(
          currencyOptionActive
            .symbol
            .substr(
              0,
              3
            )
        )}}
      </span>

      <input
        nbInput
        type="number"
        min="0.01"
        step="0.01"
        autocomplete="off"
        placeholder="Fee"
        [(ngModel)]="pricelist.rateUnit.fee"/>
    </nb-form-field>
  </div>

  <div
    class="row">
    <nb-form-field
      class="toggle-field"
      [ngClass]="{
        'disabled-field': (
          pricelistIsReadOnly
        )
      }">
      <nb-toggle
        labelPosition="end"
        [ngModel]="pricelist.rateUnitHasFranking"
        (ngModelChange)="setPricelistRateUnitHasFranking($event)">
        Consignment fee is waived due to Online Franking
      </nb-toggle>
    </nb-form-field>

    <nb-form-field
      [ngClass]="{
        'disabled-field': (
          pricelistIsReadOnly
        )
      }"
      *ngIf="pricelist.rateUnitHasFranking">
      <nb-icon
        nbPrefix
        icon="minus-outline">
      </nb-icon>

      <nb-select
        placeholder="Select Franking Cover"
        [selected]="pricelist.rateUnitFrankingType"
        (selectedChange)="setPricelistRateUnitFrankingType($event)">
        <nb-select-label>
          Franking Cover: {{ (
            (
              pricelist
                .rateUnitFrankingType
            ) | titlecase
          ) }}
        </nb-select-label>
        <nb-option
          [value]="rateUnitFrankingType"
          *ngFor="let rateUnitFrankingType of rateUnitFrankingTypes">
          {{ rateUnitFrankingType | titlecase }}
        </nb-option>
      </nb-select>
    </nb-form-field>
  </div>

  <biz-header
    label="Delivery Rates">
  </biz-header>

  <div
    class="row">
    <nb-form-field
      [ngClass]="{
        'disabled-field': (
          pricelistIsReadOnly ||
          !hasRegionCodes ||
          pricelistHasRegionCodes
        ),
        'transparent-field': (
          !hasRegionCodes ||
          pricelistHasRegionCodes
        )
      }">
      <nb-icon
        nbPrefix
        icon="map">
      </nb-icon>

      <nb-select
        multiple
        [placeholder]="(
          getPricelistRegionLabel()
        )"
        [selected]="(
          pricelistRatesZoneCodes
        )"
        (selectedChange)="(
          togglePricelistRatesZone($event)
        )">
        <nb-select-label>
          {{(
            pricelistRatesZoneLabels
              .join(
                ', '
              )
          )}}
        </nb-select-label>

        <nb-option
          [value]="null"
          [innerHTML]="(
            getPricelistRegionLabel()
          )">
        </nb-option>
        
        <nb-option
          [value]="regionCodeOption.value"
          *ngFor="let regionCodeOption of regionCodeOptions">
          {{ regionCodeOption.label }}
        </nb-option>
      </nb-select>
    </nb-form-field>
  </div>

  <biz-ordered-list
    [disabled]="pricelistIsReadOnly"
    [items]="pricelistRatesWithZones"
    [itemEmpty]="itemEmptyRatesWithZones"
    [fields]="fieldsRatesWithZones"
    [pasteRemoveStems]="[
      pricelist.currencySymbol
    ]"
    (edit)="onEditRatesWithZones($event)"
    *ngIf="(
      pricelist
        .rates
    )">
  </biz-ordered-list>

  <biz-header
    label="Extra Weight (kg)">
  </biz-header>

  <div
    class="row">
    <nb-form-field
      [ngClass]="{
        'disabled-field': (
          pricelistIsReadOnly
        )
      }">
      <nb-icon
        nbPrefix
        icon="shopping-bag-outline">
      </nb-icon>

      <input
        nbInput
        type="number"
        min="0.250"
        step="0.250"
        autocomplete="off"
        placeholder="Weight Increment"
        [(ngModel)]="pricelist.rateExtra.weightInc"/>
    </nb-form-field>

    <nb-form-field
      class="currency-field"
      [ngClass]="{
        'currency-two-digits': (
          (
            currencyOptionActive
              .symbol
              .length
          ) <=
          2
        ),
        'currency-three-digits': (
          (
            currencyOptionActive
              .symbol
              .length
          ) >
          2
        ),
        'disabled-field': (
          pricelistIsReadOnly
        )
      }"
      *ngIf="(
        currencyOptions &&
        currencyOptionActive
      )">
      <span
        nbPrefix
        class="char-prefix">
        {{(
          currencyOptionActive
            .symbol
            .substr(
              0,
              3
            )
        )}}
      </span>

      <input
        nbInput
        type="number"
        min="0.01"
        step="0.01"
        autocomplete="off"
        placeholder="Fee"
        [(ngModel)]="pricelist.rateExtra.fee"/>
    </nb-form-field>
  </div>

  <biz-header
    label="Delivery Information">
  </biz-header>

  <biz-wysiwyg
    key="ratesInfo"
    [translations]="pricelist.translations"
    [value]="pricelist.ratesInfo"
    (edit)="onEditInfo(
      'ratesInfo',
      $event
    )">
  </biz-wysiwyg>

  <biz-header
    label="Maximum Physical Weight (kg)">
  </biz-header>
  
  <div
    class="row">
    <nb-form-field
      [ngClass]="{
        'disabled-field': (
          pricelistIsReadOnly
        )
      }">
      <nb-icon
        nbPrefix
        icon="shopping-bag-outline">
      </nb-icon>

      <input
        nbInput
        type="number"
        min="0.250"
        step="0.250"
        autocomplete="off"
        placeholder="Max. Weight"
        [(ngModel)]="pricelist.weightMax"/>
    </nb-form-field>
  </div>

  <biz-header
    [label]="(
      'Volumetric Weight Calculation (kg / m' +
      '<sup>3</sup>' +
      ')'
    )">
  </biz-header>

  <div
    class="row">
    <nb-form-field
      [ngClass]="{
        'disabled-field': (
          pricelistIsReadOnly
        )
      }">
      <span
        nbPrefix
        class="char-prefix single-char">
        &times;
      </span>

      <input
        nbInput
        placeholder="Volumetric Weight Factor"
        type="number"
        min="1"
        step="1"
        [(ngModel)]="pricelist.volumeWeightFactor"/>
    </nb-form-field>

    <nb-form-field
      class="toggle-field"
      [ngClass]="{
        'disabled-field': (
          pricelistIsReadOnly
        )
      }">
      <nb-toggle
        labelPosition="end"
        [(ngModel)]="pricelist.volumeFirst">
        Charge volumetric weight if greater than physical weight
      </nb-toggle>
    </nb-form-field>
  </div>

  <biz-header
    label="Maximum COD Amount">
  </biz-header>

  <div
    class="row">
    <nb-form-field
      [ngClass]="{
        'disabled-field': (
          pricelistIsReadOnly
        )
      }">
      <nb-icon
        nbPrefix
        icon="pricetags">
      </nb-icon>

      <input
        nbInput
        type="number"
        min="0.01"
        step="0.01"
        autocomplete="off"
        placeholder="Max. COD Amount"
        [(ngModel)]="pricelist.priceMax"/>
    </nb-form-field>

    <nb-form-field
      class="currency-field"
      [ngClass]="{
        'currency-two-digits': (
          (
            currencyPriceMaxOptionActive
              .symbol
              .length
          ) <=
          2
        ),
        'currency-three-digits': (
          (
            currencyPriceMaxOptionActive
              .symbol
              .length
          ) >
          2
        ),
        'disabled-field': (
          pricelistIsReadOnly
        )
      }"
      *ngIf="(
        currencyOptions &&
        currencyPriceMaxOptionActive
      )">
      <span
        nbPrefix
        class="char-prefix">
        {{(
          currencyPriceMaxOptionActive
            .symbol
            .substr(
              0,
              3
            )
        )}}
      </span>

      <nb-select
        placeholder="Currency"
        [selected]="currencyPriceMaxOptionActive.code"
        (selectedChange)="setCurrencyPriceMaxOptionActive($event)">
        <nb-select-label>
          Currency: {{ currencyPriceMaxOptionActive.code }}
        </nb-select-label>

        <nb-option-group
          title="EU Network">
          <nb-option
            [value]="currency.code"
            *ngFor="
              let currency of (
                currencyOptions
                  .preferred
              )
            ">
            {{ currency.code }} ({{ currency.symbol }})
          </nb-option>
        </nb-option-group>
        
        <nb-option-group
          title="Other">
          <nb-option
            [value]="currency.code"
            *ngFor="
              let currency of (
                currencyOptions
                  .other
              )
            ">
            {{ currency.code }} ({{ currency.symbol }})
          </nb-option>
        </nb-option-group>
      </nb-select>
    </nb-form-field>
  </div>

  <ng-container
    *ngIf="pricelist.isCod">
    <biz-header
      label="Cash on Delivery Rates">
    </biz-header>

    <div
      class="row">
      <nb-form-field
        class="currency-field"
        [ngClass]="{
          'currency-two-digits': (
            (
              currencyOptionActive
                .symbol
                .length
            ) <=
            2
          ),
          'currency-three-digits': (
            (
              currencyOptionActive
                .symbol
                .length
            ) >
            2
          ),
          'disabled-field': pricelistIsReadOnly,
          'has-sibling': currencyRateFieldShow
        }"
        *ngIf="(
          currencyOptions &&
          currencyOptionActive &&
          pricelistHasRatesCodStepped
        )">
        <span
          nbPrefix
          class="char-prefix">
          {{(
            currencyOptionActive
              .symbol
              .substr(
                0,
                3
              )
          )}}
        </span>

        <nb-select
          placeholder="Currency"
          [selected]="rateCodPriceCurrencyCode"
          (selectedChange)="setRateCodPriceCurrencyCode($event)">
          <nb-select-label>
            Max. COD Amount Currency: {{ rateCodPriceCurrencyCode }}
          </nb-select-label>

          <nb-option-group
            title="EU Network">
            <nb-option
              [value]="currency.code"
              *ngFor="
                let currency of (
                  currencyOptions
                    .preferred
                )
              ">
              {{ currency.code }} ({{ currency.symbol }})
            </nb-option>
          </nb-option-group>
          
          <nb-option-group
            title="Other">
            <nb-option
              [value]="currency.code"
              *ngFor="
                let currency of (
                  currencyOptions
                    .other
                )
              ">
              {{ currency.code }} ({{ currency.symbol }})
            </nb-option>
          </nb-option-group>
        </nb-select>
      </nb-form-field>
    </div>

    <biz-ordered-list
      [disabled]="pricelistIsReadOnly"
      [items]="pricelist.ratesCod"
      [itemEmpty]="itemEmptyRatesCod"
      [fields]="fieldsRatesCod"
      [pasteRemoveStems]="[
        pricelist.currencySymbol,
        rateCodPriceCurrencySymbol
      ]"
      (edit)="onEditRatesCod($event)"
      *ngIf="(
        pricelist
          .ratesCod
      )">
    </biz-ordered-list>

    <biz-header
      label="COD Information">
    </biz-header>

    <biz-wysiwyg
      key="ratesCodInfo"
      [translations]="pricelist.translations"
      [value]="pricelist.ratesCodInfo"
      (edit)="onEditInfo(
        'ratesCodInfo',
        $event
      )">
    </biz-wysiwyg>
  </ng-container>

  <biz-header
    label="Optional Services">
  </biz-header>

  <biz-ordered-list
    [disabled]="pricelistIsReadOnly"
    [items]="pricelist.servicesExtra"
    [itemEmpty]="itemEmptyServicesExtra"
    [fields]="fieldsServicesExtraCurrencyActive"
    (edit)="onEditServicesExtra($event)"
    *ngIf="(
      pricelist
        .servicesExtra
    )">
  </biz-ordered-list>

  <biz-header
    label="Optional Service Information">
  </biz-header>

  <biz-wysiwyg
    key="servicesExtraInfo"
    [translations]="pricelist.translations"
    [value]="pricelist.servicesExtraInfo"
    (edit)="onEditInfo(
      'servicesExtraInfo',
      $event
    )">
  </biz-wysiwyg>

  <biz-header
    label="Additional Components">
  </biz-header>

  <biz-ordered-list
    [disabled]="pricelistIsReadOnly"
    [items]="pricelist.surcharges"
    [itemEmpty]="itemEmptySurcharges"
    [fields]="fieldsSurcharges"
    [pasteRemoveStems]="[
      pricelist.currencySymbol,
      rateCodPriceCurrencySymbol
    ]"
    (edit)="onEditSurcharges($event)"
    *ngIf="(
      pricelist
        .surcharges
    )">
  </biz-ordered-list>

  <biz-header
    label="Additional Component Information">
  </biz-header>

  <biz-wysiwyg
    key="surchargesInfo"
    [translations]="pricelist.translations"
    [value]="pricelist.surchargesInfo"
    (edit)="onEditInfo(
      'surchargesInfo',
      $event
    )">
  </biz-wysiwyg>

  <biz-header
    label="Return Rates">
  </biz-header>

  <div
    class="row">
    <nb-form-field
      [ngClass]="{
        'disabled-field': (
          pricelistIsReadOnly
        )
      }"
      *ngIf="rateReturnTypes">
      <nb-icon
        nbPrefix
        icon="corner-up-left-outline">
      </nb-icon>

      <nb-select
        placeholder="Select Return Charge"
        [selected]="pricelist.rateReturnType"
        (selectedChange)="setPricelistRateReturnType($event)">
        <nb-select-label>
          Return Type: {{ (
            (
              pricelist
                .rateReturnType
            ) | titlecase
          ) }}
        </nb-select-label>
        <nb-option
          [value]="rateReturnType"
          *ngFor="let rateReturnType of rateReturnTypes">
          {{ rateReturnType | titlecase }}
        </nb-option>
      </nb-select>
    </nb-form-field>

    <nb-form-field
      class="currency-field"
      [ngClass]="{
        'currency-two-digits': (
          (
            currencyOptionActive
              .symbol
              .length
          ) <=
          2
        ),
        'currency-three-digits': (
          (
            currencyOptionActive
              .symbol
              .length
          ) >
          2
        ),
        'disabled-field': (
          pricelistIsReadOnly
        )
      }"
      *ngIf="(
        currencyOptions &&
        currencyOptionActive &&
        (
          (
            pricelist
              .rateReturnType
          ) ===
          'fixed'
        )
      )">
      <span
        nbPrefix
        class="char-prefix">
        {{(
          currencyOptionActive
            .symbol
            .substr(
              0,
              3
            )
        )}}
      </span>

      <input
        nbInput
        type="text"
        autocomplete="off"
        placeholder="Name"
        [(ngModel)]="pricelist.rateReturnFee"/>
    </nb-form-field>
  </div>

  <div
    class="row"
    *ngIf="(
      pricelist.isCod ||
      pricelist.isPos
    )">
    <nb-form-field
      class="toggle-field"
      [ngClass]="{
        'disabled-field': (
          pricelistIsReadOnly
        )
      }"
      *ngIf="pricelistHasRatesCod">
      <nb-toggle
        labelPosition="end"
        [(ngModel)]="pricelist.rateReturnHasCod">
        Invoice COD charges even if consigment is returned
      </nb-toggle>
    </nb-form-field>

    <nb-form-field
      class="toggle-field"
      [ngClass]="{
        'disabled-field': (
          pricelistIsReadOnly
        )
      }"
      *ngIf="pricelistHasRatesCodPos">
      <nb-toggle
        labelPosition="end"
        [(ngModel)]="pricelist.rateReturnHasPos">
        Apply POS charges even if consigment is returned
      </nb-toggle>
    </nb-form-field>
  </div>

  <ng-container
    *ngIf="(
      (
        pricelist
          .ratesReturn
      ) &&
      (
        (
          pricelist
            .rateReturnType
        ) ===
        'stepped'
      )
    )">
    <div
      class="row">
      <nb-form-field
        [ngClass]="{
          'disabled-field': (
            pricelistIsReadOnly ||
            !hasRegionCodes ||
            pricelistHasRegionCodes
          ),
          'transparent-field': (
            !hasRegionCodes ||
            pricelistHasRegionCodes
          )
        }">
        <nb-icon
          nbPrefix
          icon="map">
        </nb-icon>

        <nb-select
          multiple
          [placeholder]="(
            getPricelistRegionLabel()
          )"
          [selected]="(
            pricelistRatesReturnZoneCodes
          )"
          (selectedChange)="(
            togglePricelistRatesReturnZone($event)
          )">
          <nb-select-label>
            {{(
              pricelistRatesReturnZoneLabels
                .join(
                  ', '
                )
            )}}
          </nb-select-label>

          <nb-option
            [value]="null"
            [innerHTML]="(
              getPricelistRegionLabel()
            )">
          </nb-option>
          
          <nb-option
            [value]="regionCodeOption.value"
            *ngFor="let regionCodeOption of regionCodeOptions">
            {{ regionCodeOption.label }}
          </nb-option>
        </nb-select>
      </nb-form-field>
    </div>

    <biz-ordered-list
      [disabled]="pricelistIsReadOnly"
      [items]="pricelistRatesReturnWithZones"
      [itemEmpty]="itemEmptyRatesReturnWithZones"
      [fields]="fieldsRatesReturnWithZones"
      [pasteRemoveStems]="[
        pricelist.currencySymbol
      ]"
      (edit)="onEditRatesReturnWithZones($event)">
    </biz-ordered-list>
  </ng-container>

  <biz-header
    label="Returns Information">
  </biz-header>
  
  <biz-wysiwyg
    key="ratesReturnInfo"
    [translations]="pricelist.translations"
    [value]="pricelist.ratesReturnInfo"
    (edit)="onEditInfo(
      'ratesReturnInfo',
      $event
    )">
  </biz-wysiwyg>
</ng-container>