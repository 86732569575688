import {
  Rate
} from './rate.model';

export class RatesZone {
  zoneCode: string;
  rates: Rate[] = [];

  constructor(zoneCode: string) {
    this.zoneCode = zoneCode;
  }
};