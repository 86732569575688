<nb-card
  *ngIf="(
    [
      'archive',
      'clone',
      'clone-costs',
      'generate',
      'markup',
      'publish',
      'republish',
      'restore',
      'remove'
    ].includes(
      type
    )
  )">
  <nb-card-header
    *ngIf="(
      type ===
      'archive'
    )">
    Confirmation Required
  </nb-card-header>

  <nb-card-header
    *ngIf="(
      [
        'clone',
        'generate'
      ].includes(
        type
      )
    )">
    Discount Options
  </nb-card-header>

  <nb-card-header
    *ngIf="(
      [
        'clone-costs',
        'markup'
      ]
      .includes(
        type
      )
    )">
    Markup Options
  </nb-card-header>

  <nb-card-body
    *ngIf="(
      [
        'archive',
        'publish',
        'republish',
        'restore',
        'remove'
      ].includes(
        type
      )
    )">
    Are you certain you would like to {{ type }} {{ pageDomainModelName }} &quot;{{(
      pricelist
        .labelHuman
    )}}&quot;?
  </nb-card-body>

  <nb-card-body
    *ngIf="(
      type ===
      'clone'
    )">
    Would you like to apply a pretedermined discount to the new clone of {{ pageDomainModelName }} &quot;{{(
      pricelist
        .labelHuman
    )}}&quot;?<br/>If not, leave blank and press the "Next" button.

    <biz-ratio-grid
      [(map)]="discountRatioMap">
    </biz-ratio-grid>
  </nb-card-body>

  <nb-card-body
    *ngIf="(
      type ===
      'clone-costs'
    )">
    Would you like to apply a pretedermined markup to the new clone of {{ pageDomainModelName }} &quot;{{(
      pricelist
        .labelHuman
    )}}&quot;?

    <biz-ratio-grid
      [(map)]="markupCostsRatioMap">
    </biz-ratio-grid>
  </nb-card-body>

  <nb-card-body
    *ngIf="(
      type ===
      'markup'
    )">
    Would you like to apply a pretedermined markup to a new Pricelist generated from Cost List &quot;{{(
      pricelist
        .labelHuman
    )}}&quot;?

    <biz-ratio-grid
      titleSuffix="Markup"
      [(currencyCode)]="pricelist.currencyCode"
      [(currencyCodeInitial)]="pricelist.currencyCodeInitial"
      [(currencyRate)]="pricelist.rateCurrency"
      [(map)]="markupRatioMap">
    </biz-ratio-grid>
  </nb-card-body>

  <nb-card-body
    *ngIf="(
      type ===
      'generate'
    )">
    Would you like to generate standard discount Pricelists from {{ pageDomainModelName }} &quot;{{(
      pricelist
        .labelHuman
    )}}&quot;?&nbsp;If not, press the "Abort" button.<br/><br/>Check (&#x2713;) the discount rates to be included.<br/>Pressing the "Confirm" button will create Draft Pricelists automatically!

    <nb-stepper
      #discountStepper
      linear="false"
      orientation="horizontal">
      <nb-step
        [completed]="false"
        [label]="discountLabel"
        *ngFor="
          let discount
          of discountDefaults;
          let $index = index;
        ">
        <ng-template
          #discountLabel>
          <div>
            <span
              [innerHTML]="(
                discount
                  .labelSuffix
              )">
            </span>

            <nb-checkbox
              #discountCheckbox
              [attr.data-index]="$index"
              [ngModel]="(
                isDiscountOn(
                  $index
                )
              )"
              (ngModelChange)="(
                onToggleDiscountDefault(
                  $index
                )
              )">
            </nb-checkbox>
          </div>
        </ng-template>

        <biz-ratio-grid
          [disabled]="(
            !(
              isDiscountOn(
                $index
              )
            )
          )"
          [(map)]="discount.ratios">
        </biz-ratio-grid>
      </nb-step>
    </nb-stepper>
  </nb-card-body>

  <nb-card-footer>
    <button
      nbButton
      size="medium"
      [status]="(
        (
          [
            'archive',
            'clone',
            'clone-costs',
            'markup',
            'publish',
            'republish',
            'restore'
          ].includes(
            type
          )
        ) ?
          'warning' :
          (
            (
              type ===
              'remove'
            ) ?
              'danger' :
              undefined
          )
      )"
      (click)="onConfirm()"
      *ngIf="(
        type !==
        'generate'
      )">
      {{
        (
          [
            'clone',
            'clone-costs',
            'markup'
          ].includes(
            type
          )
        ) ?
          'Next' :
          'Yes'
      }}
    </button>

    <button
      nbButton
      size="medium"
      status="primary"
      (click)="onConfirm()"
      *ngIf="(
        type ===
        'generate'
      )">
      Confirm
    </button>

    <button
      nbButton
      size="medium"
      (click)="onClose()">
      {{
        (
          [
            'clone',
            'clone-costs',
            'generate',
            'markup'
          ].includes(
            type
          )
        ) ?
          'Abort' :
          'No'
      }}
    </button>
  </nb-card-footer>
</nb-card>