import {
  Rate
} from './rate.model';

import {
  RateCod
} from './rate-cod.model';

import {
  RateExtra
} from './rate-extra.model';

import {
  RateUnit
} from './rate-unit.model';

import {
  RatesZone
} from './rates-zone.model';

import {
  Service
} from './service.model';

import {
  ServiceExtra
} from './service-extra.model';

import {
  Surcharge
} from './surcharge.model';

import {
  Translation
} from './translation.model';

import {
  User
} from './user.jwt.model';

export class Pricelist {
  _id?: string;
  context?: string;
  countryCode: string;
  currencySymbol: string;
  currencyCode: string;
  partnerCode: string;
  isCod: boolean = true;
  isDraft: boolean = true;
  isPos: boolean = false;
  orderMin: number;
  orderMax: number;
  ownerEmail: string;
  label: string = 'Draft';
  labelHuman?: string;
  priceMax: number;
  priceMaxCurrency: string;
  priceMaxCurrencySymbol: string;
  rateCurrency?: number;
  rates: Rate[] = [];
  ratesZones: RatesZone[] = [];
  ratesInfo: string = '';
  rateExtra: RateExtra = new RateExtra();
  rateUnit: RateUnit = new RateUnit();
  rateUnitHasFranking: boolean = false;
  rateUnitFrankingType: string;
  ratesCod: RateCod[] = [];
  ratesCodInfo: string = '';
  ratesReturn: Rate[] = [];
  ratesReturnZones: RatesZone[] = [];
  ratesReturnInfo: string = '';
  rateReturnFee: number = 0;
  rateReturnHasCod: boolean = true;
  rateReturnHasPos: boolean = true;
  rateReturnType: string = 'free';
  regionCodes: string[] = [];
  services: Service[]|string[];
  servicesExtra: ServiceExtra[] = [];
  servicesExtraInfo: string;
  surcharges: Surcharge[] = [];
  surchargesInfo: string;
  translations: Translation[] = [];
  users: User[]|string[];
  validTimestampStart?: Date;
  validTimestampEnd?: Date;
  vatRate: number = 0.24;
  volumeFirst: boolean = true;
  volumeWeightFactor: number;
  weightMax: number;

  constructor(
    countryCode: string,
    currencyCode: string,
    currencySymbol: string,
    priceMax: number,
    priceMaxCurrency: string,
    ownerEmail: string,
    services: Service[]
  ) {
    this.countryCode = countryCode;
    this.currencyCode = currencyCode;
    this.currencySymbol = currencySymbol;
    this.priceMax = priceMax;
    this.priceMaxCurrency = priceMaxCurrency;
    this.ownerEmail = ownerEmail;
    this.services = services;
  }
};