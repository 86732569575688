<div
  cdkDropList
  class="ordered-list"
  (cdkDropListDropped)="drop($event)"
  *ngIf="isValid">
  <div
    class="ordered-list-header"
    *ngIf="(
      items &&
      items.length > 0 &&
      fields &&
      fields.length > 0
    )">
    <ng-container
      *ngFor="let field of fields">
      <div
        class="ordered-list-header-label"
        [ngClass]="{
          'modifier-label': (
            field.isModifier
          ),  
          'number-label': (
            field.type ===
            'number'
          )
        }"
        *ngIf="!field.isHidden">
          {{ field.label }}
      </div>
    </ng-container>
  </div>

  <div
    cdkDrag
    cdkDragLockAxis="y"
    class="ordered-list-item"
    *ngFor="
      let item of items;
      let $index = index;
    ">
    <div
      *cdkDragPlaceholder>
    </div>

    <div
      cdkDragHandle
      [ngClass]="{
        'disabled': disabled
      }">
      <nb-icon
        icon="swap">
      </nb-icon>
    </div>

    <div
      class="ordered-list-item-remove"
      (click)="remove($index)"
      *ngIf="(
        !disabled &&
        (
          itemEmptyIndex !==
          $index
        )
      )">
      <nb-icon
        icon="trash-2-outline">
      </nb-icon>
    </div>

    <div
      class="ordered-list-item-label"
      *ngIf="labelKey">
      {{(
        labelKey ?
          item[
            labelKey
          ] :
          item
      )}}
    </div>

    <ng-container
      *ngFor="
        let field of fields;
        let $fieldIndex = index;
        trackBy: trackFieldBy
      ">
      <nb-form-field
        [ngClass]="{
          'disabled-field': (
            disabled ||
            (
              isItemFieldDisabled(
                field,
                item
              )
            )
          ),
          'transparent-field': (
            isItemFieldDisabled(
              field,
              item
            )
          )
        }"
        *ngIf="!(
          field.isHidden ||
          field.isModifier
        )">
        <nb-icon
          nbPrefix
          [icon]="field.icon"
          *ngIf="field.icon">
        </nb-icon>

        <input
          nbInput
          autocomplete="off"
          [type]="field.type"
          [min]="(
            field.valueMin ?
              field.valueMin :
              null
          )"
          [max]="(
            field.valueMax ?
              field.valueMax :
              null
          )"
          [step]="(
            field.valueStep ?
              field.valueStep :
              1
          )"
          [placeholder]="(
            (
              isItemFieldDisabled(
                field,
                item
              )
            ) ?
              '' :
              field.label
          )"
          [ngModel]="item[
            field.valueKey
          ]"
          (ngModelChange)="set(
            $index,
            field.valueKey,
            field.valueType,
            $event
          )"
          (paste)="onPaste(
            $event,
            $index,
            field.valueType,
            $fieldIndex
          )"/>
      </nb-form-field>

      <nb-form-field
        class="modifier-field"
        [ngClass]="{
          'disabled-field': (
            disabled ||
            (
              isItemFieldDisabled(
                field,
                item
              )
            )
          ),
          'transparent-field': (
            isItemFieldDisabled(
              field,
              item
            )
          )
        }"
        *ngIf="field.isModifier">
        <nb-icon
          nbPrefix
          [icon]="field.icon"
          *ngIf="field.icon">
        </nb-icon>
        
        <nb-select
          [placeholder]="(
            (
              isItemFieldDisabled(
                field,
                item
              )
            ) ?
              '' :
              field.label
          )"
          [selected]="item[
            field.valueKey
          ]"
          (selectedChange)="set(
            $index,
            field.valueKey,
            field.valueType,
            $event
          )">
          <nb-option
            [value]="value"
            *ngFor="
              let value of field.values;
              let $valueIndex = index;
            ">
            {{
              (
                field.valueLabels &&
                (
                  field.valueLabels[
                    $valueIndex
                  ]
                )
              ) ?
                (
                  field.valueLabels[
                    $valueIndex
                  ]
                ) :
                ''
            }}
          </nb-option>
        </nb-select>
      </nb-form-field>
    </ng-container>
  </div>
</div>