import {
  mediaUrl,
  payUrl,
  serverUrl,
  stompUrl,
  wmsUrl
} from '../environments/environment';

import {
  Discount
} from '../models';

export const _MEDIA_URL = mediaUrl;
export const _PAY_URL = payUrl;
export const _SERVER_URL = serverUrl;
export const _STOMP_URL = stompUrl;
export const _WMS_URL = wmsUrl;

export const COST_LIST_MARKUP_DEFAULT_MAP = {
  delivery: 6,
  cod: 1,
  surcharges: 0,
  returns: 6
}; // % (percent)

export const COUNTRY_PARTNER_CODE_OVERRIDE_MAP = {
  'at': 'IT-BZ',
  'de': 'DE-LZ',
  'fr': 'IT-BZ',
  'it': 'IT-BZ',
  'eu': 'DE-LZ',
  'non-eu': 'DE-LZ',
  'intl': 'DE-LZ'
};

export const COUNTRY_PARTNER_CODE_OVERRIDE_MAP_WMS = {
  'de': 'DE-LZ',
  'it': 'IT-BZ'
};

export const COUNTRY_REGION_LABEL_MAP = {
  'es': [
    {
      'label': 'Balearic Islands',
      'regionCodes': [
        '10000041',
        '10000042'
      ]
    },
    {
      'label': 'Canary Islands',
      'regionCodes': [
        '10000043',
        '10000044'
      ]
    },
    {
      'label': 'Ceuta and Melilla',
      'regionCodes': [
        '10000040'
      ]
    }
  ],
  'gr': [
    {
      'label': 'Outside Attiki',
      'regionCodes': [
        '10000047'
      ]
    },
    {
      'label': 'Islands',
      'regionCodes': [
        '10000048'
      ]
    },
    {
      'label': 'Inaccessible Areas',
      'regionCodes': [
        '10000049'
      ]
    },
  ],
  'pt': [
    {
      'label': 'Azores',
      'regionCodes': [
        '10000045'
      ]
    },
    {
      'label': 'Madeira',
      'regionCodes': [
        '10000046'
      ]
    }
  ],
  'it': [
    {
      'label': 'Sicily',
      'regionCodes': [
        '10000051'
      ]
    },
    {
      'label': 'Corsica',
      'regionCodes': [
        '10000052'
      ]
    },
    {
      'label': 'Sardinia',
      'regionCodes': [
        '10000053'
      ]
    }
  ]
};

export const COUNTRY_ZONE_MAP = {
  'eu': 'European Union',
  'non-eu': 'Outside European Union',
  'intl': 'International'
};

export const DEFAULT_CURRENCY_CODE = 'EUR';
export const DEFAULT_LANGUAGE_CODE = 'en';

export const DEFAULT_LOCALES = [
  {
    name: 'gr',
    delimiters: {
      thousands: '.',
      decimal: ','
    }
  },
  {
    name: 'intl',
    delimiters: {
      thousands: ',',
      decimal: '.'
    }
  }
];

export const PAGE_SCROLL_MARGIN_TOP = 120;
export const PAGE_SCROLL_MARGIN_TOP_FIRST = 0;

export const PREFERRED_COUNTRY_CODES = [
  'at',
  'be',
  'bg',
  'cy',
  'cz',
  'de',
  'en',
  'es',
  'fr',
  'gr',
  'hr',
  'hu',
  'lu',
  'nl',
  'it',
  'pl',
  'pt',
  'ro',
  'sk',
  'si'
];

export const PRICELIST_DISCOUNT_DEFAULTS: Discount[] = [
  {
    labelSuffix: 'Discount A',
    orderMin: 100,
    orderMax: 499,
    ratios: {
      delivery: 10,
      cod: 5,
      surcharges: 0,
      returns: 10
    } // % (percent)
  },
  {
    labelSuffix: 'Discount B',
    orderMin: 500,
    orderMax: 999,
    ratios: {
      delivery: 15,
      cod: 5,
      surcharges: 0,
      returns: 15
    } // % (percent)
  },
  {
    labelSuffix: 'Discount C',
    orderMin: 1000,
    orderMax: 2499,
    ratios: {
      delivery: 20,
      cod: 7.5,
      surcharges: 0,
      returns: 20
    } // % (percent)
  },
  {
    labelSuffix: 'Discount D',
    orderMin: 2500,
    orderMax: 5000,
    ratios: {
      delivery: 25,
      cod: 7.5,
      surcharges: 0,
      returns: 25
    } // % (percent)
  },
  {
    labelSuffix: 'Partner',
    orderMin: 1,
    orderMax: 5000,
    ratios: {
      delivery: 35,
      cod: 12.5,
      surcharges: 0,
      returns: 35
    } // % (percent)
  },
  {
    labelSuffix: 'Reseller',
    orderMin: 1,
    orderMax: 5000,
    ratios: {
      delivery: 30,
      cod: 10,
      surcharges: 0,
      returns: 30
    } // % (percent)
  }
];

export const PRICELIST_DISCOUNT_DEFAULT_MAP = {
  delivery: null,
  cod: null,
  surcharges: null,
  returns: null
}; // % (percent)

export const PRICELIST_OFFICIAL_DEFAULT = {
  labelSuffix: 'Official',
  orderMin: 1,
  orderMax: 99
};

export const PRICELIST_MARKUP_DEFAULT_MAP = {
  delivery: 70,
  cod: 30,
  surcharges: 0,
  returns: 70
}; // % (percent)

export const PRICELIST_SERVICE_CODES_LEGACY_DEFAULT = [
  '001',
  '22'
];

export const RATE_RETURN_TYPES = [
  'free',
  'fixed',
  'stepped'
];

export const RATE_UNIT_FRANKING_TYPES = [
  'full',
  'unit'
];

export const SEARCH_MIN_CHARACTERS = 3;

export const VAT_RATES = [
  0.24,
  0.23,
  0.21,
  0.19,
  0.16,
  0.13,
  0.135,
  0.09,
  0.07,
  0.06,
  0.05,
  0.048,
  0
];