import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter
} from '@angular/core';

import {
  Currency,
  Ratios
} from '../../models';

import {
  getCurrencyOptions,
  getModelOption
} from '../../app/app.utils';

import {
  DEFAULT_CURRENCY_CODE
} from '../../app/app.const';

@Component({
  selector: 'biz-ratio-grid',
  templateUrl: 'ratio-grid.component.html',
  styleUrls: [
    'ratio-grid.component.scss'
  ]
})
export class RatioGridComponent implements OnInit {
  @Input()
  public currencyCode: string;

  @Input()
  public currencyCodeInitial: string;

  @Input()
  public currencyRate: number;

  @Input()
  public disabled: boolean = false;

  @Input()
  public map: Ratios;
  
  @Input()
  public titleSuffix: string = 'Discount';

  @Output()
  public currencyCodeChange: EventEmitter<string> = (
    new EventEmitter()
  );

  @Output()
  public currencyRateChange: EventEmitter<number> = (
    new EventEmitter()
  );

  @Output()
  public mapChange: EventEmitter<Ratios> = (
    new EventEmitter()
  );

  public currencyOptions: {
    zones: Currency[],
    preferred: Currency[],
    other: Currency[]
  };
  public currencyOptionActive: Currency;
  public currencyOptionInitial: Currency;

  constructor() {}

  ngOnInit() {
    this.currencyOptions = (
      getCurrencyOptions()
    );

    this.setCurrencyOptionInitial(
      this.currencyCodeInitial
    );

    this.setCurrencyOptionActive(
      this.currencyCode
    );
  }

  public get currencyOptionDefault() {
    return (
      this.getCurrencyOption(
        DEFAULT_CURRENCY_CODE
      )
    );
  }

  public get showCurrencyRow() {
    return (
      (
        Array
          .isArray(
            this.currencyOptions
                .zones
          )
      ) &&
      (
        Array
          .isArray(
            this.currencyOptions
                .preferred
          )
      ) &&
      (
        Array
          .isArray(
            this.currencyOptions
                .other
          )
      ) &&
      this.currencyOptionActive &&
      this.currencyOptionDefault &&
      this.currencyOptionInitial &&
      (
        (
          this.currencyOptionInitial
              .code
        ) !==
        (
          this.currencyOptionDefault
              .code
        )
      )
    );
  }

  public setCurrencyOptionActive(code) {
    this.currencyOptionActive = (
      this.getCurrencyOption(
        code
      )
    );

    this.currencyCodeChange
        .emit(
          code
        );
  }

  public setCurrencyOptionInitial(code) {
    this.currencyOptionInitial = (
      this.getCurrencyOption(
        code
      )
    );
  }

  public setCurrencyRate(rate) {
    this.currencyRateChange
        .emit(
          rate
        );
  }

  private getCurrencyOption(code): Currency {
    return (
      getModelOption(
        this.currencyOptions,
        code
      )
    );
  }
};