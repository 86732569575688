<quill-editor
  [format]="format"
  [modules]="modules"
  [preserveWhitespace]="preserveWhitespace"
  [theme]="theme"
  [trimOnValidation]="trimOnValidation"
  [ngModel]="valueText"
  (ngModelChange)="setValue($event)"
  *ngIf="valueTextIsValid">
</quill-editor>

<biz-language-select
  [(languageCodeActive)]="languageCodeActive">
</biz-language-select>