import {
  Component,
  Input,
  Output,
  EventEmitter
} from '@angular/core';

import {
  getLanguageOptions,
  isArrayNotEmpty
} from '../../app/app.utils';

import {
  DEFAULT_LANGUAGE_CODE
} from '../../app/app.const';

@Component({
  selector: 'biz-language-select',
  templateUrl: './language-select.component.html',
  styleUrls: [
    './language-select.component.scss'
  ],
  host: {
    '(document:click)': 'onHideSelect($event)'
  }
})
export class LanguageSelectComponent {
  @Input(
    'languageCodes'
  ) languageCodes: string[];

  @Input(
    'languageCodeActive'
  ) languageCodeActive: string;

  @Output(
    'languageCodeActiveChange'
  ) onLanguageCodeActive: EventEmitter<string> = (
    new EventEmitter()
  );

  public languageOptions = (
    getLanguageOptions()
  );

  public showSelect: boolean = false;

  public get languageOptionActive() {
    return (
      this.languageOptionsPreferredActive ||
      this.languageOptionsOtherActive
    );
  }

  public get languageOptionsPreferred() {
    return (
      this.languageOptionsSupported
          .preferred
    );
  }

  public get languageOptionsPreferredActive() {
    return (
      (
        isArrayNotEmpty(
          this.languageOptionsPreferred
        )
      ) ?
        (
          this.languageOptionsPreferred
            .find(
              languageOption => (
                (
                  languageOption
                    .code
                ) ===
                this.languageCodeActive
              )
            )
        ) :
        undefined
    );
  }

  public get languageOptionsOther() {
    return (
      this.languageOptionsSupported
          .other
    );
  }

  public get languageOptionsOtherActive() {
    return (
      (
        isArrayNotEmpty(
          this.languageOptionsOther
        )
      ) ?
        (
          this.languageOptionsOther
            .find(
              languageOption => (
                (
                  languageOption
                    .code
                ) ===
                this.languageCodeActive
              )
            )
        ) :
        undefined
    );
  }

  private get languageOptionsSupported() {
    if (
      !(
        Array
          .isArray(
            this.languageCodes
          )
      ) // An empty input array is valid
    ) {
      return (
        this.languageOptions
      );
    }

    return ({
      preferred: (
        this.languageOptions
            .preferred
            .filter(
              languageOption => (
                (
                  (
                    languageOption
                      .code
                  ) ===
                  DEFAULT_LANGUAGE_CODE
                ) ||
                (
                  this.languageCodes
                      .includes(
                        languageOption
                          .code
                      )
                )
              )
            )
      ),
      other: (
        this.languageOptions
            .other
            .filter(
              languageOption => (
                (
                  (
                    languageOption
                      .code
                  ) ===
                  DEFAULT_LANGUAGE_CODE
                ) ||
                (
                  this.languageCodes
                      .includes(
                        languageOption
                          .code
                      )
                )
              )
            )
      )
    });
  }

  public onLanguageOptionActive(
    languageOptionPreferred,
    event?
  ) {
    if (event) {
      event
        .stopPropagation();
    }

    this.onLanguageCodeActive
        .emit(
          languageOptionPreferred
            .code
        );

    this.onToggleSelect();
  }
  
  public onHideSelect() {
    if (this.showSelect) {
      this.onToggleSelect();
    }
  }

  public onToggleSelect(event?) {
    if (event) {
      event
        .stopPropagation();
    }

    this.showSelect = (
      !this.showSelect
    );
  }
}