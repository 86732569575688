<nb-layout>
  <nb-layout-header
    fixed>
    <img
      class="logo"
      src="/assets/vectors/logo.svg"/>

    <div
      class="user-menu">

      <img
        class="avatar"
        [src]="authUserPhoto"
        *ngIf="authUserPhoto"/>

      <nb-spinner
        class="avatar"
        size="medium"
        status="primary"
        [message]="null"
        *ngIf="(
          authUser &&
          !authUserPhoto
        )">
      </nb-spinner>

      <div
        class="full-name"
        *ngIf="authUser">
        {{ authUser.name }}
      </div>

      <a
        class="logout-link"
        (click)="logout()"
        *ngIf="authUser">
        Sign Out
      </a>
    </div>
  </nb-layout-header>

  <nb-sidebar
    *ngIf="authUser">
    <div
      class="list-header"
      (click)="(
        toggleExpanded(
          'pricelists'
        )
      )">
      <nb-icon
        [icon]="(
          getExpandedIcon(
            'pricelists'
          )
        )">
      </nb-icon>
    
      <span>
        Delivery Pricelists
      </span>
    </div>

    <nb-list
      [ngClass]="{
        'expanded': (
          isExpanded(
            'pricelists'
          )
        )
      }">
      <nb-list-item>
        <a
          routerLink="/"
          [ngClass]="{
            'active': (
              contextIsLive &&
              !domainIsCostLists &&
              !pageIsWms
            )
          }">
          <nb-icon
            icon="pricetags-outline">
          </nb-icon>
          <span>
            Active
          </span>
        </a>
      </nb-list-item>
      <nb-list-item>
        <a
          routerLink="/archived"
          [ngClass]="{
            'active': (
              contextIsArchived &&
              !domainIsCostLists &&
              !pageIsWms
            )
          }">
          <nb-icon
            icon="archive-outline">
          </nb-icon>
          <span>
            Archived
          </span>
        </a>
      </nb-list-item>
      <nb-list-item>
        <a
          routerLink="/removed"
          [ngClass]="{
            'active': (
              contextIsRemoved &&
              !domainIsCostLists &&
              !pageIsWms
            )
          }">
          <nb-icon
            icon="trash-2-outline">
          </nb-icon>
          <span>
            Removed
          </span>
        </a>
      </nb-list-item>
    </nb-list>

    <div
      class="list-header"
      (click)="(
        toggleExpanded(
          'cost-lists'
        )
      )">
      <nb-icon
        [icon]="(
          getExpandedIcon(
            'cost-lists'
          )
        )">
      </nb-icon>
      
      <span>
        Delivery Cost Lists
      </span>
    </div>

    <nb-list
      class="cost-lists"
      [ngClass]="{
        'expanded': (
          isExpanded(
            'cost-lists'
          )
        )
      }">
      <nb-list-item>
        <a
          routerLink="/cost-lists/context/active"
          [ngClass]="{
            'active': (
              contextIsLive &&
              domainIsCostLists &&
              !pageIsWms
            )
          }">
          <nb-icon
            icon="pricetags-outline">
          </nb-icon>
          <span>
            Active
          </span>
        </a>
      </nb-list-item>
      <nb-list-item>
        <a
          routerLink="/cost-lists/context/archived"
          [ngClass]="{
            'active': (
              contextIsArchived &&
              domainIsCostLists &&
              !pageIsWms
            )
          }">
          <nb-icon
            icon="archive-outline">
          </nb-icon>
          <span>
            Archived
          </span>
        </a>
      </nb-list-item>
      <nb-list-item>
        <a
          routerLink="/cost-lists/context/removed"
          [ngClass]="{
            'active': (
              contextIsRemoved &&
              domainIsCostLists &&
              !pageIsWms
            )
          }">
          <nb-icon
            icon="trash-2-outline">
          </nb-icon>
          <span>
            Removed
          </span>
        </a>
      </nb-list-item>
    </nb-list>

    <div
      class="list-header"
      (click)="(
        toggleExpanded(
          'wms-pricelists'
        )
      )">
      <nb-icon
        [icon]="(
          getExpandedIcon(
            'wms-pricelists'
          )
        )">
      </nb-icon>
    
      <span>
        WMS Pricelists
      </span>
    </div>

    <nb-list
      [ngClass]="{
        'expanded': (
          isExpanded(
            'wms-pricelists'
          )
        )
      }">
      <nb-list-item>
        <a
          routerLink="/wms/context/active"
          [ngClass]="{
            'active': (
              contextIsActive &&
              !domainIsCostLists &&
              pageIsWms
            )
          }">
          <nb-icon
            icon="pricetags-outline">
          </nb-icon>
          <span>
            Active
          </span>
        </a>
      </nb-list-item>
      <nb-list-item>
        <a
          routerLink="/wms/context/archived"
          [ngClass]="{
            'active': (
              contextIsArchived &&
              !domainIsCostLists &&
              pageIsWms
            )
          }">
          <nb-icon
            icon="archive-outline">
          </nb-icon>
          <span>
            Archived
          </span>
        </a>
      </nb-list-item>
      <nb-list-item>
        <a
          routerLink="/wms/context/removed"
          [ngClass]="{
            'active': (
              contextIsRemoved &&
              !domainIsCostLists &&
              pageIsWms
            )
          }">
          <nb-icon
            icon="trash-2-outline">
          </nb-icon>
          <span>
            Removed
          </span>
        </a>
      </nb-list-item>
    </nb-list>

    <div
      class="list-header"
      (click)="(
        toggleExpanded(
          'wms-cost-lists'
        )
      )">
      <nb-icon
        [icon]="(
          getExpandedIcon(
            'wms-cost-lists'
          )
        )">
      </nb-icon>
      
      <span>
        WMS Cost Lists
      </span>
    </div>

    <nb-list
      class="cost-lists"
      [ngClass]="{
        'expanded': (
          isExpanded(
            'wms-cost-lists'
          )
        )
      }">
      <nb-list-item>
        <a
          routerLink="/wms/cost-lists/context/active"
          [ngClass]="{
            'active': (
              contextIsActive &&
              domainIsCostLists &&
              pageIsWms
            )
          }">
          <nb-icon
            icon="pricetags-outline">
          </nb-icon>
          <span>
            Active
          </span>
        </a>
      </nb-list-item>
      <nb-list-item>
        <a
          routerLink="/wms/cost-lists/context/archived"
          [ngClass]="{
            'active': (
              contextIsArchived &&
              domainIsCostLists &&
              pageIsWms
            )
          }">
          <nb-icon
            icon="archive-outline">
          </nb-icon>
          <span>
            Archived
          </span>
        </a>
      </nb-list-item>
      <nb-list-item>
        <a
          routerLink="/wms/cost-lists/context/removed"
          [ngClass]="{
            'active': (
              contextIsRemoved &&
              domainIsCostLists &&
              pageIsWms
            )
          }">
          <nb-icon
            icon="trash-2-outline">
          </nb-icon>
          <span>
            Removed
          </span>
        </a>
      </nb-list-item>
    </nb-list>
  </nb-sidebar>

  <nb-layout-column>
    <router-outlet
      *ngIf="(
        authReady &&
        authUser
      )">
    </router-outlet>

    <nb-spinner
      size="giant"
      status="primary"
      [message]="null"
      *ngIf="(
        !authReady &&
        authUser
      )">
    </nb-spinner>

    <div
      class="button-container"
      *ngIf="!authUser">
      <button
        nbButton
        status="primary"
        (click)="login()">
        Sign in with Microsoft
      </button>
    </div>
  </nb-layout-column>
</nb-layout>