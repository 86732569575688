import {
  Injectable
} from '@angular/core';

import {
  HttpClient
} from '@angular/common/http';

import {
  encode
} from 'base64-arraybuffer';

@Injectable()
export class GraphService {
  constructor(
    private http: HttpClient
  ) {}
  
  public async getPhoto() {
    return (
      `data:image/png;base64,${(
          encode(
            await (
              this.http
                  .get(
                    'https://graph.microsoft.com/v1.0/me/photo/$value',
                    {
                      responseType: 'arraybuffer'
                    }
                  )
                  .toPromise()
            )
          )
      )}`
    );
  }

  public async getUsers() {
    return (
      await (
        this.http
            .get(
              'https://graph.microsoft.com/v1.0/users'
            )
            .toPromise()
      )
    )
  }
}