import {
  Injectable
} from '@angular/core';

import {
  HttpClient
} from '@angular/common/http';

import {
  User
} from '../models';

import {
  getAuthHeaders
} from '../app/app.utils';

import {
  _SERVER_URL
} from '../app/app.const';

@Injectable()
export class AuthService {
  public authToken: string;
  
  constructor(
    private http: HttpClient
  ) {}
  
  public getAll(): (
    Promise<User[]>
  ) {
    return (
      <any>(
        this.http
            .get(
              `${_SERVER_URL}api/users`,
              getAuthHeaders(
                this.authToken
              )
            )
            .toPromise()
      )
    );
  }
}