<nb-spinner
  size="giant"
  status="primary"
  [message]="null"
  *ngIf="!pricelist">
</nb-spinner>

<ng-container
  *ngIf="pricelist">
  <div
    class="row button-row"
    [ngClass]="{
      'read-only': (
        pricelistIsReadOnly
      )
    }">
    <button
      nbButton
      size="medium"
      status="success"
      (click)="onSave()"
      *ngIf="(
        pricelistIsEdit &&
        pricelist.isDraft
      )">
      <nb-icon
        icon="save">
      </nb-icon>
      Save Changes
    </button>

    <button
      nbButton
      size="medium"
      status="success"
      (click)="onSave()"
      *ngIf="pricelistIsClone">
      <nb-icon
        icon="save">
      </nb-icon>
      Save Clone
    </button>

    <button
      nbButton
      size="medium"
      status="success"
      (click)="onSave()"
      *ngIf="pricelistIsMarkup">
      <nb-icon
        icon="save">
      </nb-icon>
      Save New Pricelist
    </button>

    <button
      nbButton
      size="medium"
      status="success"
      (click)="onSave()"
      *ngIf="pricelistIsNew">
      <nb-icon
        icon="save">
      </nb-icon>
      Save New
    </button>

    <div
      class="warning text-danger"
      *ngIf="showRefreshWarning">
      Do not refresh browser! Any discount, markup or currency conversion options will be lost and the process will be aborted!
    </div>

    <button
      nbButton
      size="medium"
      class="abort-button"
      (click)="onBack()">
      <nb-icon
        icon="close">
      </nb-icon>
      {{
        pricelistIsReadOnly ?
          'Close' :
          'Abort'
      }}
    </button>
  </div>

  <biz-header
    label="Core Info">
  </biz-header>

  <div
    class="row">
    <nb-form-field
      class="primary-field label-field"
      [ngClass]="{
        'disabled-field': (
          pricelistIsReadOnly
        )
      }">
      <nb-icon
        nbPrefix
        icon="bookmark-outline">
      </nb-icon>

      <input
        nbInput
        type="text"
        autocomplete="off"
        placeholder="Name"
        [ngModel]="pricelistLabel"
        (ngModelChange)="onPricelistLabel($event)"/>

      <biz-language-select
        [languageCodeActive]="languageCodeActiveLabel"
        (languageCodeActiveChange)="onLanguageCodeActiveLabel($event)">
      </biz-language-select>
    </nb-form-field>
    
    <div
      class="subrow">
      <nb-form-field
        class="currency-field"
        [ngClass]="{
          'currency-two-digits': (
            (
              currencyOptionActive
                .symbol
                .length
            ) <=
            2
          ),
          'currency-three-digits': (
            (
              currencyOptionActive
                .symbol
                .length
            ) >
            2
          ),
          'disabled-field': pricelistIsReadOnly,
          'has-sibling': currencyRateFieldShow
        }"
        *ngIf="(
          currencyOptions &&
          currencyOptionActive
        )">
        <span
          nbPrefix
          class="char-prefix">
          {{(
            currencyOptionActive
              .symbol
              .substr(
                0,
                3
              )
          )}}
        </span>

        <nb-select
          placeholder="Currency"
          [selected]="currencyOptionActive.code"
          (selectedChange)="setCurrencyOptionActive($event)">
          <nb-select-label>
            Currency: {{ currencyOptionActive.code }}
          </nb-select-label>

          <nb-option-group
            title="EU Network">
            <nb-option
              [value]="currency.code"
              *ngFor="
                let currency of (
                  currencyOptions
                    .preferred
                )
              ">
              {{ currency.code }} <span
                *ngIf="(
                  currency.code !==
                  currency.symbol
                )">({{
                currency.symbol
              }})</span>
            </nb-option>
          </nb-option-group>
          
          <nb-option-group
            title="Other">
            <nb-option
              [value]="currency.code"
              *ngFor="
                let currency of (
                  currencyOptions
                    .other
                )
              ">
              {{ currency.code }} <span
                *ngIf="(
                  currency.code !==
                  currency.symbol
                )">({{
                currency.symbol
              }})</span>
            </nb-option>
          </nb-option-group>
        </nb-select>
      </nb-form-field>

      <nb-form-field
        [ngClass]="{
          'disabled-field': pricelistIsReadOnly,
          'has-sibling': currencyRateFieldShow
        }"
        *ngIf="currencyRateFieldShow">
        <nb-icon
          nbPrefix
          icon="trending-up-outline">
        </nb-icon>

        <input
          nbInput
          class="has-suffix"
          type="number"
          min="0.000001"
          step="0.000001"
          autocomplete="off"
          placeholder="Exchange Rate"
          [(ngModel)]="pricelist.rateCurrency"/>

        <span
          nbPrefix
          class="char-suffix">
          {{(
            currencyOptions
              .preferred[0]
              .symbol
          )}}
        </span>
      </nb-form-field>
    </div>
  </div>

  <div
    class="row">
    <nb-form-field
      [ngClass]="{
        'disabled-field': (
          pricelistIsReadOnly
        )
      }"
      *ngIf="(
        countryOptions &&
        countryOptionActive
      )">
      <nb-icon
        nbPrefix
        icon="flag-outline">
      </nb-icon>

      <nb-select
        placeholder="Country"
        [ngClass]="{
          'align-ignore-arrow': (
            (
              countryOptionActive
                .name
                .length
            ) <
            20
          )
        }"
        [selected]="countryOptionActive.code"
        (selectedChange)="setCountryOptionActive(
          $event,
          true
        )">
        <nb-select-label>
          {{ countryOptionLabel }}: {{ countryOptionActive.name }}
        </nb-select-label>

        <nb-option-group
          title="Global Zones">
          <nb-option
            [value]="country.code"
            *ngFor="
              let country of (
                countryOptions
                  .zones
              )
            ">
            {{ country.name }}
          </nb-option>
        </nb-option-group>

        <nb-option-group
          title="EU Network">
          <nb-option
            [value]="country.code"
            *ngFor="
              let country of (
                countryOptions
                  .preferred
              )
            ">
            {{ country.name }}
          </nb-option>
        </nb-option-group>
        
        <nb-option-group
          title="Other">
          <nb-option
            [value]="country.code"
            *ngFor="
              let country of (
                countryOptions
                  .other
              )
            ">
            {{ country.name }}
          </nb-option>
        </nb-option-group>
      </nb-select>
    </nb-form-field>

    <nb-form-field
      [ngClass]="{
        'disabled-field': (
          pricelistIsReadOnly ||
          hasSinglePartnerCode
        )
      }"
      *ngIf="(
        hasCountryPartnerCodeMapValue &&
        (
          countryOptionActive &&
          (
            (
              countryOptionActive
                .code
            ) !==
            'gr'
          )
        )
      )">
      <nb-icon
        nbPrefix
        icon="shuffle-2-outline">
      </nb-icon>

      <nb-select
        placeholder="Partner Code"
        [selected]="pricelist.partnerCode"
        *ngIf="hasSinglePartnerCode">
        <nb-select-label>
          Partner Code: {{ pricelist.partnerCode }}
        </nb-select-label>

        <nb-option
          [value]="partnerCode"
          *ngFor="
            let partnerCode of (
              partnerCodes
            )
          ">
          {{ partnerCode }}
        </nb-option>
      </nb-select>

      <input
        nbInput
        type="text"
        autocomplete="off"
        placeholder="Partner Code"
        [(ngModel)]="pricelist.partnerCode"
        *ngIf="!hasSinglePartnerCode"/>
    </nb-form-field>
  </div>

  <ng-container
    *ngIf="hasVat">
    <biz-header
      label="Regional Info">
    </biz-header>
    
    <div
      class="row">
      <nb-form-field
        [ngClass]="{
          'disabled-field': (
            pricelistIsReadOnly
          )
        }"
        *ngIf="vatRates">
        <nb-icon
          nbPrefix
          icon="pie-chart-outline">
        </nb-icon>

        <nb-select
          placeholder="VAT Rate"
          [(selected)]="pricelist.vatRate">
          <nb-select-label>
            VAT Rate: {{ pricelist.vatRate | percent : '1.0-1' }}
          </nb-select-label>
          <nb-option
            [value]="vatRate"
            *ngFor="let vatRate of vatRates">
            {{ vatRate | percent : '1.0-1' }}
          </nb-option>
        </nb-select>
      </nb-form-field>
    </div>
  </ng-container>

  <biz-header
    label="Date Range of Validity">
  </biz-header>

  <div
    class="row">
    <nb-form-field
      [ngClass]="{
        'disabled-field': (
          pricelistIsReadOnly
        )
      }">
      <nb-icon
        nbPrefix
        icon="calendar-outline">
      </nb-icon>

      <input
        nbInput
        placeholder="Start Date"
        [value]="pricelist.validTimestampStart | date : 'dd MMMM yyyy' : '+0000'"
        [nbDatepicker]="dateTimePickerDateStart">

      <nb-datepicker
        #dateTimePickerDateStart
        (dateChange)="setValidTimestampStart($event)">
      </nb-datepicker>
    </nb-form-field>

    <nb-form-field
      [ngClass]="{
        'disabled-field': (
          pricelistIsReadOnly
        )
      }">
      <nb-icon
        nbPrefix
        icon="calendar">
      </nb-icon>

      <input
        nbInput
        placeholder="End Date"
        [value]="pricelist.validTimestampEnd | date : 'dd MMMM yyyy' : '+0000'"
        [nbDatepicker]="dateTimePickerDateEnd">

      <nb-datepicker
        #dateTimePickerDateEnd
        (dateChange)="setValidTimestampEnd($event)">
      </nb-datepicker>
    </nb-form-field>
  </div>

  <biz-header
    label="Associated Services">
  </biz-header>

  <div
    class="row">
    <nb-form-field
      [ngClass]="{
        'disabled-field': (
          pricelistIsReadOnly
        )
      }"
      *ngIf="services">
      <nb-icon
        nbPrefix
        icon="briefcase-outline">
      </nb-icon>

      <nb-select
        multiple
        placeholder="Service Codes"
        [selected]="pricelistServicesActive"
        (selectedChange)="onPricelistServicesActive($event)">
        <nb-option
          [value]="service"
          *ngFor="let service of services">
          {{service.codeLegacy}} - {{ service.label }}
        </nb-option>
      </nb-select>
    </nb-form-field>

    <!-- <nb-form-field
      [ngClass]="{
        'disabled-field': (
          pricelistIsReadOnly
        )
      }"
      *ngIf="services">
      <nb-icon
        nbPrefix
        icon="link-outline">
      </nb-icon>

      <input
        placeholder="Service Protocol URL"
        [(ngModel)]="pricelist.serviceProtocolUrl"/>
    </nb-form-field> -->
  </div>

  <ng-container
    *ngFor="
      let rateGroup of (
        pricelist
          .rateGroups
      )">
    <biz-header
      [label]="(
        rateGroup
          .label
      )">
    </biz-header>

    <table>
      <thead>
        <tr>
          <th>
            Type
          </th>
        </tr>
      </thead>

      <tbody>
        <tr
          *ngFor="
            let rate of (
              rateGroup
                .rates
            )
          ">
          <td
            [innerHTML]="(
              rate
                .type
                .name
            )">
          </td>
        </tr>
      </tbody>
    </table>
  </ng-container>

  <biz-header
    label="Terms & Conditions">
  </biz-header>

  <biz-wysiwyg
    key="terms"
    [translations]="pricelist.translations"
    [value]="pricelist.terms"
    (edit)="onEditInfo(
      'terms',
      $event
    )">
  </biz-wysiwyg>
</ng-container>