
import {
  NgModule
} from '@angular/core';

import {
  FormsModule
} from '@angular/forms';

import {
  BrowserModule
} from '@angular/platform-browser';

import {
  HttpClientModule
} from '@angular/common/http';

import {
  NbActionsModule,
  NbBadgeModule,
  NbButtonModule,
  NbCardModule,
  NbDatepickerModule,
  NbIconModule,
  NbLayoutModule,
  NbListModule,
  NbSelectModule,
  NbSpinnerModule,
  NbToggleModule
} from '@nebular/theme';

import {
  HeaderModule,
  LanguageSelectModule,
  OrderedListModule,
  WysiwygModule
} from '../../components';

import {
  AppRoutingModule
} from '../../app/app-routing.module';

import {
  AuthService,
  PricelistService,
  ServiceService
} from '../../providers';

import {
  PricelistComponent
} from './pricelist.component';

@NgModule({
  declarations: [
    PricelistComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    NbActionsModule,
    NbBadgeModule,
    NbButtonModule,
    NbCardModule,
    NbDatepickerModule,
    NbIconModule,
    NbLayoutModule,
    NbListModule,
    NbSelectModule,
    NbSpinnerModule,
    NbToggleModule,
    HeaderModule,
    LanguageSelectModule,
    OrderedListModule,
    WysiwygModule,
    AppRoutingModule
  ],
  providers: [
    AuthService,
    PricelistService,
    ServiceService
  ]
})
export class PricelistModule { }
