import {
  NgModule
} from '@angular/core';

import {
  FormsModule
} from '@angular/forms';

import {
  BrowserModule
} from '@angular/platform-browser';

import {
  QuillModule
} from 'ngx-quill';

import {
  LanguageSelectModule
} from '../language-select';

import {
  WysiwygComponent
} from './wysiwyg.component';

@NgModule({
  declarations: [
    WysiwygComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    LanguageSelectModule,
    QuillModule
  ],
  exports: [
    WysiwygComponent
  ]
})
export class WysiwygModule { }