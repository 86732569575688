
import {
  NgModule
} from '@angular/core';

import {
  FormsModule
} from '@angular/forms';

import {
  BrowserModule
} from '@angular/platform-browser';

import {
  HttpClientModule
} from '@angular/common/http';

import {
  MatButtonModule
} from '@angular/material/button';

import {
  MatFormFieldModule
} from '@angular/material/form-field';

import {
  MatIconModule
} from '@angular/material/icon';

import {
  MatInputModule
} from '@angular/material/input'

import {
  MatSelectModule
} from '@angular/material/select';

import {
  NbActionsModule,
  NbBadgeModule,
  NbButtonModule,
  NbCardModule,
  NbIconModule,
  NbLayoutModule,
  NbListModule,
  NbSelectModule,
  NbSpinnerModule
} from '@nebular/theme';

import {
  AppRoutingModule
} from '../../app/app-routing.module';

import {
  LanguageSelectModule
} from '../../components';

import {
  PricelistService,
  PricelistWmsService
} from '../../providers';

import {
  HomeComponent
} from './home.component';

@NgModule({
  declarations: [
    HomeComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    HttpClientModule,
    LanguageSelectModule,
    MatButtonModule,
    MatFormFieldModule,
    MatIconModule,
    MatSelectModule,
    NbActionsModule,
    NbBadgeModule,
    NbButtonModule,
    NbCardModule,
    NbIconModule,
    MatInputModule,
    NbLayoutModule,
    NbListModule,
    NbSelectModule,
    NbSpinnerModule,
    AppRoutingModule
  ],
  providers: [
    PricelistService,
    PricelistWmsService
  ]
})
export class HomeModule { }
