import {
  Component,
  Inject,
  OnInit
} from '@angular/core';

import {
  ActivatedRoute,
  Router
} from '@angular/router';

import {
  first
} from 'rxjs/operators';

import {
  MsalService
} from '@azure/msal-angular';

import {
  Country,
  Currency,
  PricelistWms,
  Ratios,
  Service
} from '../../models';

import {
  PricelistWmsService,
  ServiceService
} from '../../providers';

import {
  getContextPath,
  getCountryCodesZone,
  getCountryPartnerCodeOverride,
  getCountryOptions,
  getCurrencyOptions,
  getCurrencySymbol,
  getModelOption,
  getUtcDateTimestamp,
  getVatRates,
  isArrayNotEmpty,
  isEU,
  isStringSafeNotEmpty,
  naturalSort,
  objectHasValue,
  setPricelistAdjustmentWms
} from '../../app/app.utils';

import {
  DEFAULT_CURRENCY_CODE,
  DEFAULT_LANGUAGE_CODE,
  PRICELIST_OFFICIAL_DEFAULT
} from '../../app/app.const';

@Component({
  selector: 'biz-page-pricelist-wms',
  templateUrl: './pricelist-wms.component.html',
  styleUrls: [
    './pricelist-wms.component.scss'
  ],
  host: {
    class: 'biz-page'
  }
})
export class PricelistWmsComponent implements OnInit {
  public pricelist: PricelistWms;
  public pricelistDomain: string = null;
  public pricelistIsClone: boolean;
  public pricelistIsEdit: boolean;
  public pricelistIsMarkup: boolean;
  public pricelistIsNew: boolean;
  public pricelistIsReadOnly: boolean;

  public currencyOptions: {
    zones: Currency[],
    preferred: Currency[],
    other: Currency[]
  };
  public currencyOptionActive: Currency;

  public countryOptions: {
    zones: Country[],
    preferred: Country[],
    other: Country[]
  };
  public countryOptionActive: Country;

  public countryPartnerCodeMap: any;
  public currencyRateFieldShow: boolean = false;

  public languageCodeActiveLabel: string = (
    DEFAULT_LANGUAGE_CODE
  );

  public partnerCodes: string[] = [];
  
  public showRefreshWarning: boolean;

  public services: Service[] = [];
  public vatRates: number[] = [];

  private authEmail: string;

  public constructor(
    @Inject(
      'Window'
    ) private window: Window,
    private router: Router,
    private route: ActivatedRoute,
    private msalService: MsalService,
    private pricelistWmsService: PricelistWmsService,
    private serviceService: ServiceService
  ) {}

  public get countryOptionLabel(): string {
    return (
      (
        (
          this.countryOptions !==
          null
        ) &&
        (
          typeof this.countryOptions ===
          'object'
        ) &&
        (
          Array
            .isArray(
              this.countryOptions
                  .zones
            )
        ) &&
        this.countryOptionActive &&
        (
          this.countryOptions
              .zones
              .map(
                zone => (
                  zone
                    .code
                )
              )
              .includes(
                this.countryOptionActive
                    .code
              )
        )
      ) ?
        'Zone' :
        'Country'
    );
  }

  public get hasCountryPartnerCodeMapValue(): boolean {
    return (
      objectHasValue(
        this.countryPartnerCodeMap
      )
    );
  }

  public get hasSinglePartnerCode(): boolean {
    return (
      (
        !!this.countryPartnerCodeMap[
          this.pricelist
              .countryCode
        ]
      ) &&
      (
        ![
          'de',
          'it'
        ].includes(
          this.pricelist
              .countryCode
        )
      )
    );
  }

  public get pricelistDomainIsCosts(): boolean {
    return (
      this.pricelistDomain ===
      'costs'
    );
  }

  public get isEU(): boolean {
    return (
      this.pricelist &&
      isEU(
        this.pricelist
            .countryCode
      )
    );
  }

  public get hasVat(): boolean {
    return (
      this.isEU ||
      (
        this.pricelist &&
        (
          getCountryCodesZone()
            .includes(
              this.pricelist
                  .countryCode
            )
        )
      )
    );
  }
  
  public get pricelistLabel(): string {
    if (
      !this.languageCodeActiveLabel ||
      (
        this.languageCodeActiveLabel ===
        DEFAULT_LANGUAGE_CODE
      )
    ) {
      return (
        this.pricelist
            .label
      );
    }

    const translationActive = (
      (
        Array
          .isArray(
            this.pricelist
                .translations
          )
      ) ?
        (
          this.pricelist
              .translations
              .find(
                translation => (
                  (
                    'label' ===
                    (
                      translation
                        .key
                    )
                  ) &&
                  (
                    this.languageCodeActiveLabel ===
                    (
                      translation
                        .languageCode
                    )
                  )
                )
              )
        ) :
        undefined
    );

    return (
      translationActive ?
        (
          translationActive
            .value
        ) :
        ''
    );
  }

  public get pricelistServicesActive(): Service[] {
    if (
      !(
        (
          Array
            .isArray(
              this.services
            )
        ) &&
        (
          isArrayNotEmpty(
            this.pricelist
                .services
          )
        )
      )
    ) {
      return [];
    }

    return (
      this.services
          .filter(
            service => (
              (
                (
                  <Service[]>(
                    this.pricelist
                        .services
                  )
                )
                .map(
                  pricelistService => (
                    pricelistService
                      ._id
                  )
                )
                .includes(
                  service
                    ._id
                )
              )
            )
          )
    );
  }

  public async ngOnInit() {
    this.setAuthEmail();
    this.setVatRates();

    this.countryOptions = (
      getCountryOptions()
    );

    await this.setServices();

    await this.setPartnerCodes();
    this.setCountryPartnerCodeMap();

    this.setPricelistDomain();
    await this.setPricelist();

    this.setCountryOptionActive(
      this.pricelist
          .countryCode
    );

    this.currencyOptions = (
      getCurrencyOptions()
    );

    await this.setPricelistIsClone();
    await this.setPricelistIsMarkup();

    this.setPricelistIsEdit();
    this.setPricelistIsReadOnly();

    this.setCurrencyOptionActive(
      this.pricelist
          .currencyCode
    );
  }

  public onBack() {
    this.goBack();
  }
  
  public onEditInfo(
    key: string,
    data: {
      value: string;
      languageCode: string;
    }
  ) {
    let {
      value,
      languageCode
    } = data;

    value = (
      (
        isStringSafeNotEmpty(
          value
        )
      ) ?
        value :
        ''
    );

    if (
      [
        'terms'
      ].includes(
        key
      )
    ) {
      if (
        !languageCode ||
        (
          languageCode ===
          DEFAULT_LANGUAGE_CODE
        )
      ) {
        this.pricelist[
          key
        ] = value;
      }
      else {
        if (
          Array
            .isArray(
              this.pricelist
                  .translations
            )
        ) {
          const index = (
            this.pricelist
                .translations
                .findIndex(
                  translation => (
                    (
                      (
                        translation
                          .key
                      ) ===
                      key
                    ) &&
                    (
                      (
                        translation
                          .languageCode
                      ) ===
                      languageCode
                    )
                  )
                )
          );

          if (
            index >
            -1
          ) {
            this.pricelist
                .translations[
                  index
                ]
                .value = (
                  value
                );
          }
          else {
            this.pricelist
                .translations
                .push({
                  key,
                  languageCode,
                  value
                });
          }
        }
        else {
          this.pricelist
              .translations = [{
                key,
                languageCode,
                value
              }];
        }
      }
    }
  }

  public onLanguageCodeActiveLabel(
    languageCode: string
  ) {
    this.languageCodeActiveLabel = (
      languageCode
    );

    if (
      this.languageCodeActiveLabel &&
      (
        this.languageCodeActiveLabel !==
        DEFAULT_LANGUAGE_CODE
      )
    ) {
      const translation = (
        (
          Array
            .isArray(
              this.pricelist
                  .translations
            )
        ) ?
          (
            this.pricelist
                .translations
                .find(
                  translation => (
                    (
                      (
                        translation
                          .key
                      ) ===
                      'label'
                    ) &&
                    (
                      (
                        translation
                          .languageCode
                      ) ===
                      this.languageCodeActiveLabel
                    )
                  )
                )
          ) :
          null
      );

      if (
        !(
          translation &&
          (
            isStringSafeNotEmpty(
              translation
                .value
            )
          )
        )
      ) {
        this.onPricelistLabel(
          this.pricelist
              .label
        );
      }
    }
  }

  public onPricelistLabel(
    value: string
  ) {
    value = (
      (
        isStringSafeNotEmpty(
          value
        )
      ) ?
        value :
        ''
    );

    if (
      !this.languageCodeActiveLabel ||
      (
        this.languageCodeActiveLabel ===
        DEFAULT_LANGUAGE_CODE
      )
    ) {
      this.pricelist
          .label = (
            value
          );
    }
    else {
      if (
        Array
          .isArray(
            this.pricelist
                .translations
          )
      ) {
        const index = (
          this.pricelist
              .translations
              .findIndex(
                translation => (
                  (
                    (
                      translation
                        .key
                    ) ===
                    'label'
                  ) &&
                  (
                    (
                      translation
                        .languageCode
                    ) ===
                    this.languageCodeActiveLabel
                  )
                )
              )
        );

        if (
          index >
          -1
        ) {
          this.pricelist
              .translations[
                index
              ]
              .value = (
                value
              );
        }
        else {
          this.pricelist
              .translations
              .push({
                key: 'label',
                languageCode: (
                  this.languageCodeActiveLabel
                ),
                value
              });
        }
      }
      else {
        this.pricelist
            .translations = [{
              key: 'label',
              languageCode: (
                this.languageCodeActiveLabel
              ),
              value
            }];
      }
    }
  }

  public onPricelistServicesActive(
    services: Service[]
  ) {
    if (
      !this.pricelist ||
      this.pricelistIsReadOnly
    ) {
      return false;
    }

    this.pricelist
        .services = (
          naturalSort(
            services,
            'codeLegacy'
          )
        );
  }

  private goBack(
    context?: string
  ) {

    if (
      !context &&
      this.pricelist
    ) {
      context = (
        this.pricelist
            .context
      );
    }

    if (!context) {
      return (
        this.router
            .navigate(
              [
                (
                  this.pricelistDomainIsCosts ?
                    `/wms/cost-lists/context/active` :
                    `/wms/context/active`
                )
              ],
              {
                queryParamsHandling: (
                  'preserve'
                )
              }
            )
      );
    }

    this.router
        .navigate(
          [
            (
              this.pricelistDomainIsCosts ?
                `/wms/cost-lists/context/${(
                  (
                    getContextPath(
                      context
                    )
                  )
                )}` :
                `/wms/context/${(
                  (
                    getContextPath(
                      context
                    )
                  )
                )}`
            )
          ],
          {
            queryParamsHandling: (
              'preserve'
            )
          }
        );
  }

  private setAuthEmail() {
    this.authEmail = (
      this.msalService
          .getAccount()
          .userName
    );
  }


  private getCountryOption(code): Country {
    return (
      getModelOption(
        this.countryOptions,
        code
      )
    );
  }

  private getCurrencyOption(code): Currency {
    return (
      getModelOption(
        this.currencyOptions,
        code
      )
    );
  }

  private setCountryPartnerCodeMap() {
    this.countryPartnerCodeMap = {};

    [
      ...(
        this.countryOptions
            .zones
      ),
      ...(
        this.countryOptions
            .preferred
      ),
      ...(
        this.countryOptions
            .other
      )
    ].forEach(
      country => {
        const partnerCodeOverride = (
          getCountryPartnerCodeOverride(
            (
              country
                .code
            ),
            true
          )
        );

        if (partnerCodeOverride) {
          return (
            this.countryPartnerCodeMap[
              country
                .code
            ] = partnerCodeOverride
          );
        }

        const partnerCodeLocal = (
          this.partnerCodes
              .filter(
                partnerCode => (
                  partnerCode
                    .toLowerCase()
                    .startsWith(
                      `${(
                        country
                          .code
                      )}-`
                    )
                )
              )[0]
        );

        if (partnerCodeLocal) {
          this.countryPartnerCodeMap[
            country
              .code
          ] = partnerCodeLocal;
        }
      }
    );
  }

  public setCountryOptionActive(
    code: string,
    forceSetPartnerCode: boolean = false
  ) {
    this.countryOptionActive = (
      this.getCountryOption(
        code
      )
    );

    this.pricelist
        .countryCode = (
          code
        );

    if (
      this.hasSinglePartnerCode ||
      forceSetPartnerCode
    ) {
      this.pricelist
          .partnerCode = (
            this.countryPartnerCodeMap[
              code
            ]
          );
    }
  }

  public setCurrencyOptionActive(code) {
    this.currencyOptionActive = (
      this.getCurrencyOption(
        code
      )
    );

    this.pricelist
        .currencyCode = (
          code
        );

    this.pricelist
        .currencySymbol = (
          this.currencyOptionActive
              .symbol
        );

    this.setFeeLabelCurrency();
    this.setRateCurrency();
  }

  public setValidTimestampStart(
    value: string
  ) {
    this.setValidTimestampRaw(
      'validTimestampStart',
      value
    );
  }

  public setValidTimestampEnd(
    value: string
  ) {
    this.setValidTimestampRaw(
      'validTimestampEnd',
      value,
      true
    );
  }

  private async setPartnerCodes() {
    this.partnerCodes = (
      await 
        this.pricelistWmsService
            .getPartnerCodes()
    );
  }

  private async setServices() {
    this.services = (
      await (
        this.serviceService
            .getAll()
      )
    );
  }

  private setFeeLabelCurrency() {
    /* this.setFeeLabelCurrencyRatesClone(
      'fieldsRates'
    ); */
  }

  private setFeeLabelCurrencyRatesClone(member) {
    const fieldsRates = [
      ...this[
        member
      ]
    ];

    this.setFeeLabelCurrencyRatesMutate(
      fieldsRates
    );

    this[
      member
    ] = (
      fieldsRates
    );
  }

  private setFeeLabelCurrencyRatesMutate(fieldsRates) {
    fieldsRates
      .forEach(
        (
          rate,
          index
        ) => {
          if (
            rate
              .label
              .includes(
                'Fee'
              )
          ) {
            fieldsRates[
              index
            ].label = `${(
                rate
                  .label
                  .split(
                    ' ('
                  )[0]
              )} (${
                this.pricelist
                    .currencySymbol
              })`;
          }
        }
      );
  }

  private async setPricelist() {
    const params = (
      await 
        this.route
            .params
            .pipe(
              first()
            )
            .toPromise()
    );
    
    this.pricelistIsNew = (
      !params
        ._id
    );
    
    if (this.pricelistIsNew) {
      this.pricelist = new PricelistWms(
        (
          this.countryOptions
              .zones[0]
              .code
        ),
        DEFAULT_CURRENCY_CODE,
        getCurrencySymbol(
          DEFAULT_CURRENCY_CODE
        ),
        this.authEmail
      );

      return;
    }

    this.pricelist = (
      await
        this.pricelistWmsService
            .get(
              params._id,
              this.pricelistDomain
            )
    );
  }

  private setPricelistDomain() {
    this.pricelistDomain = (
      (
        this.router
            .url
            .startsWith(
              '/wms/cost-lists'
            )
      ) ?
        'costs' :
        null
    );
  }

  private async setPricelistIsClone() {
    const urlSegments =(
      await 
        this.route
            .url
            .pipe(
              first()
            )
            .toPromise()
    );

    this.pricelistIsClone = (
      (
        (
          urlSegments
            .length
        ) >
        2
      ) &&
      (
        (
          urlSegments[2]
            .path
        ).startsWith(
          'clone'
        )
      )
    );

    if (
      this.pricelist &&
      this.pricelistIsClone
    ) {
      this.setPricelistDiscount();
      
      this.pricelist
          .isDraft = (
            true
          );

      this.pricelist
          .label = `${(
            this.pricelist
                .label
          )} (Clone)`;

      this.pricelist
          .ownerEmail = (
            this.authEmail
          );

      delete (
        this.pricelist
            ._id
      );

      delete (
        this.pricelist
            .currencySymbol
      );

      delete (
        this.pricelist
            .labelHuman
      );
    }
  }

  private async setPricelistIsMarkup() {
    const urlSegments =(
      await 
        this.route
            .url
            .pipe(
              first()
            )
            .toPromise()
    );

    this.pricelistIsMarkup = (
      (
        (
          urlSegments
            .length
        ) >
        2
      ) &&
      (
        (
          urlSegments[2]
            .path
        ) ===
        'markup'
      )
    );

    if (
      this.pricelist &&
      this.pricelistIsMarkup
    ) {
      this.showRefreshWarning = true;
      
      this.setPricelistIncrease();
      
      this.pricelist
          .isDraft = (
            true
          );

      this.pricelist
          .label = `${(
            this.pricelist
                .label
          )} - ${(
            PRICELIST_OFFICIAL_DEFAULT
              .labelSuffix
          )}`

      this.pricelist
          .ownerEmail = (
            this.authEmail
          );

      delete (
        this.pricelist
            ._id
      );

      delete (
        this.pricelist
            .currencySymbol
      );

      delete (
        this.pricelist
            .labelHuman
      );
    }
  }

  private setPricelistIsEdit() {
    this.pricelistIsEdit = (
      this.pricelist &&
      !this.pricelistIsClone &&
      !this.pricelistIsMarkup &&
      !this.pricelistIsNew  &&
      (
        this.pricelist
            .isDraft
      ) &&
      (
        this.authEmail ===
        (
          this.pricelist
              .ownerEmail
        )
      )
    );

    if (this.pricelistIsEdit) {
      delete (
        this.pricelist
            .currencySymbol
      );

      delete (
        this.pricelist
            .labelHuman
      );
    }
  }

  private setPricelistIsReadOnly() {
    this.pricelistIsReadOnly = (
      !this.pricelistIsEdit &&
      (
        !(
          this.pricelistIsClone ||
          this.pricelistIsMarkup ||
          this.pricelistIsNew
        )
      )
    );
  }

  private setPricelistDiscount() {
    const discountRatioMap = (
      this.pricelistWmsService
          .cloneDiscountRatioMap[
            this.pricelist
                ._id
          ]
    );

    this.pricelistWmsService
        .resetCloneRatioMap(
          this.pricelistDomainIsCosts
        );

    if (!discountRatioMap) {
      return false;
    }

    const applyDiscount = (
      fee: number,
      ratioMap: Ratios,
      key: string,
      isRatio: boolean = false
    ) => (
      parseFloat(
        (
          fee *
          (
            100 -
            (
              ratioMap[
                key
              ]
            )
          ) /
          100
        ).toFixed(
          isRatio ?
            3 :
            2
        )
      )
    );

    this.setPricelistAdjustment(
      discountRatioMap,
      applyDiscount
    );
  }

  private setPricelistIncrease() {
    const markupRatioMap: Ratios = (
      this.pricelistWmsService
          .markupRatioMap[
            this.pricelist
                ._id
          ]
    );

    this.pricelistWmsService
        .resetMarkupRatioMap();

    if (!markupRatioMap) {
      this.showRefreshWarning = false;

      return (
        this.goBack()
      );
    }

    this.setPricelistCurrency(
      markupRatioMap
    );

    const applyIncrease = (
      fee: number,
      ratioMap: Ratios,
      key: string,
      isRatio: boolean = false
    ) => (
      parseFloat(
        (
          fee *
          (
            100 +
            (
              ratioMap[
                key
              ]
            )
          ) /
          100
        ).toFixed(
          isRatio ?
            3 :
            2
        )
      )
    );

    this.setPricelistAdjustment(
      markupRatioMap,
      applyIncrease
    );
  }

  private setPricelistAdjustment(
    ratioMap: Ratios,
    applyAdjustment: Function
  ) {
    setPricelistAdjustmentWms(
      this.pricelist,
      ratioMap,
      applyAdjustment
    );
  }

  private setPricelistCurrency(
    markupRatioMap: Ratios
  ) {
    const {
      currencyCode,
      currencyRate
    } = markupRatioMap;

    this.setCurrencyOptionActive(
      currencyCode
    );

    if (
      !(
        currencyRate &&
        (
          currencyCode ===
          DEFAULT_CURRENCY_CODE
        )
      )
    ) {
      return false;
    }

    const applyConversion = (
      fee: number,
      ratioMap: Ratios,
      key: string,
      isRatio: boolean = false
    ) => (
      isRatio ?
        fee :
        (
          parseFloat(
            (
              fee *
              currencyRate
            )
            .toFixed(
              2
            )
          )
        )
    );

    this.setPricelistAdjustment(
      markupRatioMap,
      applyConversion
    );
  }

  private setRateCurrency() {
    if (
      this.currencyOptionActive &&
      (
        (
          this.currencyOptionActive
              .code
        ) !==
        DEFAULT_CURRENCY_CODE
      )
    ) {
      this.currencyRateFieldShow = (
        this.pricelistDomainIsCosts &&
        !this.pricelistIsMarkup
      );
    }
    else {
      this.currencyRateFieldShow = false;

      this.pricelist
          .rateCurrency = null;
    }
  }

  private setValidTimestampRaw(
    key: string,
    value: string,
    isEndOfDay: boolean = false
  ) {
    if (!this.pricelist) {
      return false;
    }

    this.pricelist[
      key
    ] = (
      getUtcDateTimestamp(
        value,
        isEndOfDay
      )
    );
  }

  private setVatRates() {
    this.vatRates = (
      getVatRates()
    );
  }
}