import {
  Inject,
  Injectable
} from '@angular/core';

import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpRequest
} from '@angular/common/http';

import {
  BroadcastService,
  MsalInterceptor,
  MsalService
} from '@azure/msal-angular';

import {
  AuthResponse,
  ServerHashParamKeys
} from 'msal';

import {
  from,
  Observable
} from 'rxjs';

import {
  map,
  mergeMap,
  tap
} from 'rxjs/operators';

import {
  isFrame
} from '../app/app.utils';

import {
  _PAY_URL,
  _SERVER_URL
} from '../app/app.const';

@Injectable()
export class AzureInterceptor extends MsalInterceptor {
  private broadService: BroadcastService;
  private msalService: MsalService;

  constructor(
    @Inject(
      'Window'
    ) private window: Window,

    msalService: MsalService,
    broadcastService: BroadcastService
  ) {
    super(
      msalService,
      broadcastService
    );

    this.msalService = (
      msalService
    );
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let scopes = (
      this
        .msalService
        .getScopesForEndpoint(
          req.url
        )
    );
    

    if (
      isFrame(
        this.window
      ) ||
      (
        !(
          scopes ||
          (
            req
              .url
              .startsWith(
                _SERVER_URL
              )
          )
        )
      )
    ) {
      return (
        next
          .handle(
            req
          )
      );
    }

    if (
      !scopes
    ) {
      scopes = [
        'user.read'
      ];
    }

    let token: string;

    return (
      from(
        this.msalService
            .acquireTokenSilent({
              scopes,
              redirectUri: `${_PAY_URL}msal/blank.html`
            })
            .then(
              (response: AuthResponse) => (
                req.clone({
                  setHeaders: {
                    Authorization: (
                      `Bearer ${(
                        (
                          response.tokenType ===
                          ServerHashParamKeys.ID_TOKEN
                        ) ?
                          response.idToken.rawIdToken :
                          response.accessToken
                      )}`
                    )
                  }
                })
              )
            )
        )
        .pipe(
            mergeMap(nextReq => {
              return next
                .handle(
                  nextReq
                )
            }),
            map(
              data => {
                if (
                  (
                    data
                      .type
                  ) ===
                  0
                ) {
                  return null;
                }

                return data;
              }
            ),
            tap(
                () => {},
                err => {
                  if (
                    (
                      err instanceof
                      HttpErrorResponse
                  ) &&
                  (
                    err.status ===
                      401
                    )
                  ) {
                    this.msalService
                        .clearCacheForScope(
                          token
                        );

                    return (
                      this.broadService
                          .broadcast(
                            'msal:notAuthorized',
                            err.message
                          )
                    );
                  }
                }
            )
        )
    );
  }
}